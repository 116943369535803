import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, IconButton, InputAdornment, Stack, Typography, TextField, Popper, ClickAwayListener, Paper } from '@mui/material';
import { Scoped, k } from 'kremling';
import { CallMadeIcon, CloseIcon, EmojiObjectIcon } from 'components/mui';
import { withStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
      borderRadius: `16px`,
      '& fieldset': {
        border: 'none'
      }
    }
  }
})(TextField);
export const NodeHelpResource = props => {
  const [search, setSearch] = useState(props.searchValue);
  const [imageAnchorEl, setImageAnchorEl] = useState(null);
  const [imageData, setImageData] = useState(null);
  const imageAnchorElOpen = Boolean(imageData);
  const handleClose = e => {
    props?.onClose();
  };
  useEffect(() => {
    if (search === undefined || search === null) {
      // On our first load this will be a non string so don't load data again.
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      if (search != props.searchValue) {
        props?.onSearchChange(search);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  const handleImageClose = () => {
    setImageAnchorEl(null);
    setImageData(null);
  };
  return <>
      <Box sx={{
      background: '#f3f7fa',
      width: '315px',
      flexGrow: '0',
      flexShrink: '0',
      userSelect: 'none',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      height: '100%',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }}>
        <Box sx={{
        backgroundColor: '#F3F7FA',
        width: ' 309px',
        padding: '1.6rem 0.8rem',
        flexGrow: '0',
        flexShrink: '0',
        userSelect: 'none',
        position: 'relative',
        height: '100%'
      }}>
          <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}>
            <Stack spacing={'20px'} sx={{
            pr: '24px',
            pb: '24px',
            pt: '8px'
          }}>
              <Box sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
                <Box sx={{
                pl: '16px'
              }}>
                  <CustomTextField sx={{
                  width: '195px'
                }} placeholder="Search..." value={search} onChange={e => setSearch(e.target.value)} InputProps={{
                  startAdornment: <InputAdornment position="start">
                          <SearchIcon sx={{
                      transform: 'scale(1.5)'
                    }} />
                        </InputAdornment>,
                  endAdornment: <InputAdornment position="end">
                          {search != '' && <IconButton onClick={e => {
                      props?.onSearchChange('');
                    }}>
                              <CloseIcon size="sm" />
                            </IconButton>}
                        </InputAdornment>
                }} />
                </Box>

                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <IconButton sx={{
                  height: '40px',
                  width: '40px'
                }} onClick={e => {
                  handleClose();
                }}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <Stack direction={'row'}>
                <Scoped css={css}>
                  <div className="node-selection">
                    <div className="node-selection__img" style={{
                    backgroundColor: `#${props?.node?.color}`
                  }}>
                      {props?.node?.img ? <img src={props.node.img} alt="" draggable={false} /> : null}
                    </div>
                  </div>
                </Scoped>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontSize: '20px',
                  fontWeight: '700',
                  lineHeight: '28px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    {props?.node?.name}
                  </Typography>
                </Box>
              </Stack>

              <Stack sx={{
              pl: '16px'
            }} direction={'row'} spacing={'12px'}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center',
                px: '12px',
                py: '6px',
                borderRadius: '16px',
                backgroundColor: '#C2E7FF'
              }}>
                  <Typography sx={{
                  textTransform: 'capitalize'
                }}> {props?.node?.type}</Typography>
                </Box>
                <Box flexGrow={2} />
              </Stack>
              <Box sx={{
              pl: '16px'
            }}>
                <Divider />
              </Box>
              {!!props?.node?.nodeResource?.description && <Box sx={{
              pl: '16px'
            }}>
                  <Typography sx={{
                fontSize: '18px',
                fontWeight: '700'
              }}>Description</Typography>
                </Box>}

              {!!props?.node?.nodeResource?.description && <Box sx={{
              pl: '16px'
            }}>
                  <Typography sx={{
                fontSize: '16px',
                fontWeight: '300',
                lineHeight: '24px'
              }}>
                    {props?.node?.nodeResource?.description}
                  </Typography>
                </Box>}

              {!!props?.node?.nodeResource?.idea && <Stack sx={{
              pl: '16px'
            }} direction={'row'} spacing="10px">
                  <EmojiObjectIcon />
                  <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                    <Typography sx={{
                  fontSize: '18px',
                  fontWeight: '700',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      Journey Idea
                    </Typography>
                  </Box>
                </Stack>}
              {!!props?.node?.nodeResource?.idea && <Box sx={{
              pl: '16px'
            }}>
                  <Typography sx={{
                fontSize: '16px',
                fontWeight: '300',
                lineHeight: '24px'
              }}>
                    {props?.node?.nodeResource?.idea}
                  </Typography>
                </Box>}

              {!!props?.node?.nodeResource?.img && <Box sx={{
              pl: '16px'
            }}>
                  <Typography sx={{
                fontSize: '18px',
                fontWeight: '700'
              }}>Example Image</Typography>
                </Box>}

              {!!props.node?.nodeResource?.img && <Box sx={{
              pl: '16px',
              width: '100px'
            }} onMouseEnter={e => {
              setImageData(props.node.nodeResource.img);
              setImageAnchorEl(e.currentTarget);
            }} onMouseLeave={e => {
              setImageAnchorEl(null);
              setImageData(null);
            }}>
                  <img src={props.node?.nodeResource?.img} width="100px" height="100px" />
                </Box>}
            </Stack>
            {!!props?.node?.nodeResource?.url && <Stack spacing={'26px'} sx={{
            paddingX: '16px'
          }}>
                <Divider />
                <Button onClick={e => {
              //todo:whats the url format
              window.open(props?.node?.nodeResource?.url, '_blank');
            }}>
                  <Stack direction="row" spacing="8px">
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                      <Typography sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#3898D9',
                    textTransform: 'none'
                  }}>
                        Learn more
                      </Typography>
                    </Box>
                    <CallMadeIcon fill="#3898D9" />
                  </Stack>
                </Button>
              </Stack>}
          </Box>
        </Box>
      </Box>

      <Popper sx={{
      zIndex: 100
    }} anchorEl={imageAnchorEl} open={imageAnchorElOpen} onClose={handleImageClose} placement="auto">
        <ClickAwayListener onClickAway={handleImageClose}>
          <Paper elevation={0} sx={{
          borderRadius: '16px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.25))'
        }}>
            <img src={imageData} style={{
            maxHeight: '65vh',
            maxWidth: '65vw'
          }} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>;
};
const css = {
  styles: `[kremling="i41"] body,body[kremling="i41"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i41"] .node-selection,[kremling="i41"].node-selection {
  text-align: center;
  width: 80px;
  vertical-align: top;
  flex-basis: 33.333333%;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

[kremling="i41"] .node-selection__img,[kremling="i41"].node-selection__img {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 8px auto;
}

[kremling="i41"] .node-selection__img img,[kremling="i41"].node-selection__img img {
  width: 30px;
  height: 30px;
  display: inline-block;
  opacity: 0.7;
}

[kremling="i41"] .node-selection__name,[kremling="i41"].node-selection__name {
  color: #1D252D;
  font-size: 12px;
  font-family: Nexa;
}`,
  id: 'i41',
  namespace: 'kremling'
};