import { Container, Graphics, Sprite } from 'pixi.js';
import warningImg from '../assets/flag.png';
export class NodeSuggestion {
  constructor() {
    this.container = this.build();
    this.container.interactive = true;
    this.container.on('mouseover', this._onMouseOver).on('mouseout', this._onMouseOut);
    // .on('click', (event) => this._onMouseOver);
    this._hookId = 0;
    this._hooks = [];
  }
  build = () => {
    const container = new Container();
    container.sortableChildren = true;
    const size = 30;
    const radius = size / 2;
    const innerYellow = new Graphics();
    innerYellow.lineStyle(1, 0x3eb87b);
    innerYellow.beginFill(0xffffff);
    innerYellow.drawCircle(radius, radius, radius);
    innerYellow.endFill();
    this.icon = new Sprite.from(warningImg);
    this.icon.scale.x = 0.37;
    this.icon.scale.y = 0.37;
    this.icon.x = 4;
    this.icon.y = 2;
    //this.icon.alpha = 0.7;
    this.icon.tint = 0x3eb87b;
    container.addChild(innerYellow);
    container.addChild(this.icon);
    container.name = 'suggestion';
    this.tooltip = new Graphics();
    this.tooltip.alpha = 0;
    this.tooltip.name = 'tooltip';
    container.addChild(this.tooltip);
    container.alpha = 0;
    return container;
  };
  addHook = (name, callback) => {
    this._hooks = [...this._hooks, {
      id: this._hookId,
      name,
      callback
    }];
    const removeHook = () => this.removeHook(this._hookId);
    this._hookId += 1;
    return removeHook;
  };
  removeHook = id => {
    this._hooks = this._hooks.filter(hook => hook.id !== id);
  };
  callHook = (name, event) => {
    this._hooks.forEach(hook => {
      if (hook.name === name) hook.callback(event);
    });
  };
  _onMouseOver = e => {
    this.tooltip.alpha = 1;
    this.tooltip.visible = true;
    this.callHook('mouseover', e);
  };
  _onMouseOut = e => {
    this.tooltip.alpha = 0;
    this.tooltip.visible = false;
    this.callHook('mouseout', e);
  };
}