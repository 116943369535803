import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Button } from '../../components/button/button.component';
import { Dropdown } from '../../components/dropdown/dropdown.component';
import { Loader } from '../../components/loader/loader.component';
import { getCompanies, postCompany, getUsers, getCompanyStatuses } from '../../shared/common.api';
import { toasterService } from '../../components/toaster/toaster-service';
import { Modal } from '../../components/modal/modal.component';
import { Calendar } from '../../components/calendar/calendar.component';
import { DynamicTypeAhead } from '../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import utils from '../../shared/utils';
import moment from 'moment-timezone';
import { Icon, PageHeaderMui } from 'components';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { Box, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { NewButton } from 'components/mui/button/new.component';
import { SingleSelectFilter } from 'components/mui/filter/single-select.component';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
const minDate = new Date(2000, 1, 1, 0, 0, 0, 0);
export const CompaniesList = ({
  history
}) => {
  const {
    hasPermission,
    setAsCompany,
    user,
    asCompany,
    flags
  } = useContext(UserStateContext);
  const [loadingCreateCompany, setLoadingCreateCompany] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState('');
  const [newCompanyStatus, setNewCompanyStatus] = useState('');
  const [newCompanyAccountManager, setNewCompanyAccountManager] = useState(null);
  const [newCompanyContractSignedDate, setNewCompanyContractSignedDate] = useState(null);
  const [newCompanyContractSignedMRR, setNewCompanyContractSignedMRR] = useState('');
  const filterOptions = [{
    component: SingleSelectFilter,
    label: 'Status',
    key: 'is_active',
    defaultValue: 'true',
    isDefault: true,
    options: [{
      label: 'Show all',
      value: 'all'
    }, {
      label: 'Active',
      value: 'true'
      //isDefault: true,
    }, {
      label: 'Inactive',
      value: 'false'
    }]
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    fils['is_active'] = true;
    return fils;
  });
  const actions = <NewButton onClick={e => setCreateModal(true)} />;
  const headCells = [{
    width: '50%',
    key: 'name',
    label: 'Info',
    displayComponent: ({
      data
    }) => <Stack sx={{
      width: '100%'
    }}>
          <Box>
            <strong>{data.name}</strong>
          </Box>
          <Box>
            {data.address1}
            {data.address1 && ','} {data.city}
            {data.city && ','} {data.state}
            {data.state && ','} {data.zip_code}
          </Box>
        </Stack>
  }, {
    key: 'phone',
    label: 'Contact',
    displayComponent: ({
      data
    }) => utils.formatPhoneNumber(data.phone)
  }, {
    key: 'is_active',
    label: 'Status',
    displayComponent: ({
      data
    }) => data.is_active ? 'Active' : 'Inactive'
  }, {
    key: 'tools',
    label: '',
    noSort: true,
    displayComponent: ({
      data
    }) => data.id !== asCompany.id ? <Button actionType="flat" onClick={e => handleWorkClick(e, data)}>
            Switch Company
          </Button> : <></>
  }];
  const handleWorkClick = (e, asCompany) => {
    e.stopPropagation();
    setAsCompany(asCompany);
    toasterService.success(`Now working as "${asCompany.name}"`);
  };
  const goToCompany = id => {
    // if I have permission to view
    if (hasPermission('organization.view_company')) {
      history.push(`/companies/${id}`);
    }
  };
  useEffect(() => {
    if (history?.location?.state?.from == 'signin') {
      history.push('/dashboard');
    }
  }, []);
  const createCompany = () => {
    setLoadingCreateCompany(true);
    postCompany({
      name: newCompanyName,
      status: newCompanyStatus,
      account_manager: newCompanyAccountManager,
      contract_original_signed_date: newCompanyContractSignedDate ? newCompanyContractSignedDate.format('YYYY-MM-DD') : null,
      contract_signed_mrr: parseFloat(newCompanyContractSignedMRR) || null,
      use_mailhouse_return_address: true,
      created_user: user.id // TODO move this to the backend
    }).then(({
      data
    }) => {
      goToCompany(data.id);
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Companies" icon={<Icon name="custom-dashboard" size={34} />} />

        <div className="wrapper-scroll company-list">
          <CssBaseline />
          {filters && <EnhancedTable actions={user.is_superuser && actions} type="Companies" headCells={headCells} api={getCompanies} defaultSort="name" handleRowClick={company => goToCompany(company.id)} filterOptions={filterOptions} filtersState={[filters, setFilters]} />}
        </div>

        <Modal open={createModal} onClose={() => setCreateModal(false)} allowBackdropClick title="Create Company">
          <div className="modal__body">
            <div className="form-group">
              <label>
                Company Name <span className="form-error">*</span>
              </label>
              <input type="text" className="form-control" autoFocus value={newCompanyName} onChange={e => setNewCompanyName(e.target.value)} />
            </div>
            <div className="form-group">
              <label>
                Status <span className="form-error">*</span>
              </label>
              <DynamicTypeAhead getItems={getCompanyStatuses} placeholder="Choose Company Status" displayProperty="name" keyProperty="id" value={newCompanyStatus} onChange={manager => setNewCompanyStatus(manager ? manager.id : '')} />
            </div>
            <div className="form-group">
              <label>Account Manager</label>
              <DynamicTypeAhead getItems={getUsers} placeholder="Choose Account Manager" displayProperty="email" keyProperty="id" value={newCompanyAccountManager} onChange={manager => setNewCompanyAccountManager(manager ? manager.id : '')} />
            </div>
            <div className="form-group">
              <label>Contract Signed Date</label>
              <Dropdown size="md" allowContentClicks trigger={() => <Button dropdown block>
                    {newCompanyContractSignedDate ? newCompanyContractSignedDate.format('LL') : 'Select Date'}
                  </Button>} content={({
              close
            }) => <div className="p-sm">
                    <Calendar minDate={minDate} value={newCompanyContractSignedDate ? newCompanyContractSignedDate.toDate() : null} onChange={date => {
                setNewCompanyContractSignedDate(moment(date));
                close();
              }} />
                  </div>} />
            </div>
            <div className="form-group">
              <label>Contract Signed MRR</label>
              <input type="text" className="form-control" value={newCompanyContractSignedMRR} onChange={e => setNewCompanyContractSignedMRR(e.target.value)} />
            </div>
            <div className="form-error text-sm">* Required</div>
          </div>
          <div className="modal__actions">
            <Button onClick={() => setCreateModal(false)}>Cancel</Button>
            <Button actionType="primary" onClick={createCompany} disabled={!newCompanyName}>
              Create
            </Button>
          </div>
          {loadingCreateCompany && <Loader overlay />}
        </Modal>
      </div>
    </>;
};