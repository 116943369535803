import React from 'react';
function Icon(props) {
  if (props.size == 'xlg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18zm0-60h600v-430H180zm0-490h600v-130H180zm0 0v-130zm300 230q-17 0-28.5-11.5T440-440t11.5-28.5T480-480t28.5 11.5T520-440t-11.5 28.5T480-400m-160 0q-17 0-28.5-11.5T280-440t11.5-28.5T320-480t28.5 11.5T360-440t-11.5 28.5T320-400m320 0q-17 0-28.5-11.5T600-440t11.5-28.5T640-480t28.5 11.5T680-440t-11.5 28.5T640-400M480-240q-17 0-28.5-11.5T440-280t11.5-28.5T480-320t28.5 11.5T520-280t-11.5 28.5T480-240m-160 0q-17 0-28.5-11.5T280-280t11.5-28.5T320-320t28.5 11.5T360-280t-11.5 28.5T320-240m320 0q-17 0-28.5-11.5T600-280t11.5-28.5T640-320t28.5 11.5T680-280t-11.5 28.5T640-240"></path>
      </svg>;
  }
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M186.67-80q-27 0-46.84-19.83Q120-119.67 120-146.67v-600q0-27 19.83-46.83 19.84-19.83 46.84-19.83h56.66V-880h70v66.67h333.34V-880h70v66.67h56.66q27 0 46.84 19.83Q840-773.67 840-746.67v600q0 27-19.83 46.84Q800.33-80 773.33-80zm0-66.67h586.66v-420H186.67zm0-486.66h586.66v-113.34H186.67zm0 0v-113.34zM480-400q-17 0-28.5-11.5T440-440t11.5-28.5T480-480t28.5 11.5T520-440t-11.5 28.5T480-400m-160 0q-17 0-28.5-11.5T280-440t11.5-28.5T320-480t28.5 11.5T360-440t-11.5 28.5T320-400m320 0q-17 0-28.5-11.5T600-440t11.5-28.5T640-480t28.5 11.5T680-440t-11.5 28.5T640-400M480-240q-17 0-28.5-11.5T440-280t11.5-28.5T480-320t28.5 11.5T520-280t-11.5 28.5T480-240m-160 0q-17 0-28.5-11.5T280-280t11.5-28.5T320-320t28.5 11.5T360-280t-11.5 28.5T320-240m320 0q-17 0-28.5-11.5T600-280t11.5-28.5T640-320t28.5 11.5T680-280t-11.5 28.5T640-240"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <mask id="mask0_22_5173" style={{
      maskType: 'alpha'
    }} width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_22_5173)">
        <path fill={props.fill || '#1D252D'} d="M12 14.135a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.454.259-.626a.853.853 0 01.626-.258c.245 0 .454.086.626.258a.852.852 0 01.259.626.852.852 0 01-.26.626.853.853 0 01-.625.259zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.454.259-.626A.853.853 0 018 12.366c.245 0 .453.086.626.258a.853.853 0 01.259.626.853.853 0 01-.26.626.853.853 0 01-.625.259zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.454.259-.626a.853.853 0 01.626-.258c.245 0 .453.086.626.258a.852.852 0 01.259.626.852.852 0 01-.26.626.853.853 0 01-.625.259zm-4 3.923a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.453.259-.626a.853.853 0 01.626-.259c.245 0 .454.087.626.26a.852.852 0 01.259.625.853.853 0 01-.26.626.853.853 0 01-.625.259zm-4 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.453.259-.626A.852.852 0 018 16.288c.245 0 .453.087.626.26a.853.853 0 01.259.625.853.853 0 01-.26.626.853.853 0 01-.625.259zm8 0a.853.853 0 01-.626-.259.853.853 0 01-.259-.626c0-.245.087-.453.259-.626a.853.853 0 01.626-.259c.245 0 .453.087.626.26a.852.852 0 01.259.625.853.853 0 01-.26.626.853.853 0 01-.625.259zm-10.692 3.5c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 013.5 19.75V6.365c0-.505.175-.932.525-1.282.35-.35.778-.525 1.283-.525h1.384V3.212c0-.22.074-.403.22-.55a.745.745 0 01.55-.22c.219 0 .402.074.549.22.146.147.22.33.22.55v1.346h7.577V3.192c0-.212.071-.39.215-.534a.726.726 0 01.535-.216c.212 0 .39.072.534.216a.726.726 0 01.216.534v1.366h1.384c.505 0 .933.175 1.283.525.35.35.525.777.525 1.282V19.75c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308zm0-1.5h13.384a.3.3 0 00.221-.087.3.3 0 00.087-.221v-9.384H5v9.384a.3.3 0 00.087.221.3.3 0 00.22.087zM5 8.865h14v-2.5a.3.3 0 00-.087-.22.3.3 0 00-.22-.087H5.307a.3.3 0 00-.221.086.3.3 0 00-.087.221v2.5z"></path>
      </g>
    </svg>;
}
export default Icon;