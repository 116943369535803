import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../../root.component';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography, Divider, Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { withStyles } from '@mui/styles';
import { DefaultIntegrationIcon, OpenInNewWindowIcon, ThumbUpFilledIcon, ThumbUpIcon } from 'components/mui';
import { getEmailStatsWidget } from 'shared/common.api';
const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white'
  }
})(Tooltip);
export const EmailStatsChart = props => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  useEffect(() => {
    setLoading(true);
    getEmailStatsWidget(asCompany.id).then(response => {
      setEmails(response?.data?.emails);
      console.log(response?.data?.emails, 'response?.data?.emails');
      setLoading(false);
    });
  }, []);
  return <>
      {loading ? <>
          <Stack spacing="36px">
            <Stack direction="row" spacing={'16px'}>
              <Box>
                <DefaultIntegrationIcon />
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Skeleton variant="rounded" width={210} height={20} />
              </Box>
            </Stack>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          pb: '12px'
        }}>
              <Skeleton variant="rounded" width={270} height={20} />
            </Box>
          </Stack>
        </> : <Box sx={{
      width: '100%',
      display: 'flex'
    }}>
          <Stack spacing="32px" sx={{
        width: '100%'
      }}>
            {/*  <Typography
              sx={{
                fontWeight: '700',
                lineHeight: '20px',
                fontSize: '16px',
              }}
             >
              Unpublished Email Templates
             </Typography> */}

            {emails.length > 0 ? <Grid sx={{
          px: '8px'
        }} container spacing={{
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3
        }} columns={{
          xs: 4,
          sm: 12,
          md: 12,
          lg: 12
        }}>
                {emails.map((email, index) => <Grid xs="auto" sm="auto" md="auto" lg="auto" key={index}>
                    <Box sx={{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              border: '1px solid #E8EAED',
              cursor: 'pointer',
              borderRadius: '8px',
              '&:hover': {
                boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
              }
            }}>
                      <Stack sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center'
              }} onClick={e => {
                history.push(`/templates/edit/email/${email.id}?breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
              }}>
                        <Box sx={{
                  p: '20px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                          <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                            <Typography>{email.template_name}</Typography>
                          </Box>
                          <Box>
                            {/*    <IconButton>
                              <OpenInNewWindowIcon fill="#3898D9" />
                             </IconButton> */}
                          </Box>
                        </Box>
                        <Box sx={{
                  height: '250px'
                }}>
                          <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    border: '1px soild #000',
                    pb: 1,
                    px: 1,
                    maxHeight: '250px'
                  }}>
                            {email?.thumbnail_url && <CustomTooltip title={<img src={email.thumbnail_url} style={{
                      maxHeight: '50vh',
                      maxWidth: '50vw'
                    }} />}>
                                <img src={email.thumbnail_url} width="160px" />
                              </CustomTooltip>}
                          </Box>{' '}
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>)}
              </Grid> : <Stack direction="row" spacing={'8px'}>
                <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                  <Typography>You have no recent unpublished email templates. Good job! </Typography>
                </Box>
                <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                  <ThumbUpFilledIcon fill="#53A6D6" />
                </Box>
              </Stack>}
          </Stack>
        </Box>}
    </>;
};