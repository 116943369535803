import React, { Component } from 'react';
import { Scoped, k } from 'kremling';
import { shape, string } from 'prop-types';
export class NodeSelection extends Component {
  static propTypes = {
    node: shape({
      color: string,
      subType: string,
      templateId: string,
      name: string,
      img: string
    })
  };
  state = {
    onHover: null
  };
  onDragStart = e => {
    e.dataTransfer.setData('text/plain', `${this.props.node.templateId || ''}|${this.props.node.subType || ''}`);
  };
  render() {
    const {
      color,
      name,
      img,
      parameters
    } = this.props.node;
    const draggable = this.props.draggable;
    const {
      onHover
    } = this.state;
    return <Scoped css={css}>
        <div className="node-selection">
          <div className="node-selection__img" style={{
          backgroundColor: `#${color}`,
          cursor: onHover == this.props.node.subType && this.props.node?.nodeResource?.description ? 'pointer' : 'auto'
        }} draggable={draggable} onDragStart={e => {
          if (draggable) {
            this.onDragStart(e);
          }
        }} onClick={e => {
          if (this.props.node?.nodeResource?.description) {
            this.props.setClicked(this.props.node);
          }
        }} onMouseEnter={e => {
          this.setState({
            onHover: this.props.node.subType
          });
        }} onMouseLeave={e => {
          this.setState({
            onHover: null
          });
        }}>
            {img ? <img src={img} alt="" draggable={false} /> : null}
          </div>
          <div className="node-selection__name">{name || parameters.name}</div>
        </div>
      </Scoped>;
  }
}
const css = {
  styles: `[kremling="i45"] body,body[kremling="i45"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i45"] .node-selection,[kremling="i45"].node-selection {
  text-align: center;
  width: 80px;
  margin-bottom: 24px;
  vertical-align: top;
  flex-basis: 33.333333%;
  padding: 0.4rem;
}

[kremling="i45"] .node-selection__img,[kremling="i45"].node-selection__img {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
  margin: 0 auto 8px auto;
}

[kremling="i45"] .node-selection__img img,[kremling="i45"].node-selection__img img {
  width: 30px;
  height: 30px;
  display: inline-block;
  opacity: 0.7;
}

[kremling="i45"] .node-selection__name,[kremling="i45"].node-selection__name {
  color: #1D252D;
  font-size: 12px;
  font-family: Nexa;
}`,
  id: 'i45',
  namespace: 'kremling'
};