import React, { useState, useRef, useEffect, useContext } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { SegmentedMessage, GraphemeSplitter } from 'shared/segment-calculator';
import { ModalDialog, Icon } from 'components';
import { Emoji } from 'components/emoji/emoji.component';
import { SmsTemplatesMenu } from 'components/templates/sms-templates.component';
const SPLITTER = new GraphemeSplitter();
const style = {
  textareaFooter: {
    color: '#f28837',
    position: 'relative',
    bottom: '0px',
    left: '8px'
  }
};

/**
 * TODO There are some hard coded node specific things in here. We should probably fix that.
 */

export const SMSMessage = props => {
  const {
    companyFields
  } = useContext(UserStateContext);
  const {
    param
  } = props;
  const message = props.data.message || '';
  const [showSegmentModal, setShowSegmentModal] = useState(false);
  const [messageHeight, setMessageHeight] = useState(50);
  const [showAttemptedAttachementModal, setShowAttemptedAttachementModal] = useState(false);
  const onMessageChange = e => {
    setMessageHeight(e.target.scrollHeight);
    props.update({
      message: e.target.value
    });
  };
  const insertEmoji = e => {
    props.update({
      message: props.data.message ? props.data.message + e : e
    });
  };
  const getMessageCount = message => {
    const fullMessage = message + '\nReply STOP to optout';
    const segmentedMessage = new SegmentedMessage(fullMessage, SPLITTER);
    const charactersValue = `${SPLITTER.countGraphemes(fullMessage)}* characters = `;
    let segmentsValues = `${segmentedMessage.segments.length}`;
    if (message.indexOf('{') > -1) {
      segmentsValues += '**';
    }
    segmentsValues += ' segment';
    if (segmentedMessage.segments.length != 1) {
      segmentsValues += 's';
    }
    return <>
        {charactersValue}
        <span style={segmentedMessage.segments.length > 1 ? {
        color: '#f28837'
      } : {
        color: '#404040'
      }}>{segmentsValues}</span>
      </>;
  };
  const insertData = e => {
    const {
      message
    } = props.data;
    if (e.target[e.target.selectedIndex]?.dataset?.type == 'datetime') {
      props.update({
        message: `${message || ''}{{{date ${e.target.value} "%m/%d/%Y %-I:%M %p"}}}`
      });
    } else {
      props.update({
        message: `${message || ''}{{{${e.target.value}}}}`
      });
    }
  };
  const getId = (groupId, fieldId) => {
    return `${groupId}.${fieldId?.replace(/__/g, '.')}`;
  };
  const handleTemplateChoice = (template, revision) => {
    const newProps = {
      message: props.data.message ? props.data.message + revision.message : revision.message
    };
    if (param.message_type === 'sms' && revision.media.length > 0) {
      setShowAttemptedAttachementModal(true);
    } else if (revision.media.length > 0) {
      newProps.media = revision.media;
    }
    props.update(newProps);
  };
  return <>
      <div className="form-group">
        <div className="d-flex justify-content-between align-items-center">
          <label className="my-0">{param.label}</label>
          <div className="d-flex justify-content-between align-items-center">
            <SmsTemplatesMenu handleTemplateChoice={handleTemplateChoice} offset={{
            x: 60,
            y: 30
          }} />
            <Emoji openDirection="right" onEmojiClick={e => insertEmoji(e)} />
          </div>
        </div>
        <div className="form-control" style={{
        height: 'auto',
        padding: '0px'
      }}>
          <textarea className="form-control form-message" style={{
          border: 'none',
          boxShadow: 'none',
          overflowY: 'scroll',
          minHeight: '24px',
          maxHeight: '250px',
          height: `${messageHeight}px`,
          ':focus': {
            outline: 'none'
          }
        }} onChange={onMessageChange} value={message || ''} />
          <span style={style.textareaFooter}>Reply STOP to optout</span>
        </div>
      </div>
      <div className="d-flex mb-3 justify-content-between">
        <span className="text-sm">
          {getMessageCount(message || '')}
          <span onClick={() => setShowSegmentModal(true)}>
            <Icon className="select-list__selected ml-2" size={14} name="fa-solid-question-circle" fill={'#f28837'} />
          </span>
        </span>
        <select className="form-control" style={{
        height: '2rem',
        width: '136px'
      }} onChange={e => {
        insertData(e);
      }} value={''}>
          <option>Insert Tag</option>
          {props.node.subType === 'action_send_survey_sms' && <optgroup label="Survey">
              <option value="surveyLink">Survey Link</option>
            </optgroup>}
          {companyFields.map(field => <optgroup key={field.id} label={field.name}>
              {field.fields.map(subfield => <option key={subfield.id} value={getId(field.id, subfield.id)} data-type={subfield.type}>
                  {subfield.name}
                </option>)}
            </optgroup>)}
        </select>
      </div>
      {(props.data.message || '').indexOf('{') > -1 && <p className="text-sm">** Message contains variable data which may increase the size of the message.</p>}
      <ModalDialog open={showSegmentModal} title="SMS Segments" submitText="Okay" allowBackdropClick={true} onClose={() => setShowSegmentModal(false)} cancelBtn={false} onSubmit={() => setShowSegmentModal(false)}>
        <p>
          SMS (aka text) messages are billed per segment. A single segment is typically 160 characters, but if you're using special
          characters, like emoji it may be less. You can send multiple segments (really as many as you like) but for billing purposes, each
          segment counts as a message sent.
        </p>
      </ModalDialog>
      <ModalDialog open={showAttemptedAttachementModal} title="SMS Attachments" submitText="Okay" allowBackdropClick={true} onClose={() => setShowAttemptedAttachementModal(false)} cancelBtn={false} onSubmit={() => setShowAttemptedAttachementModal(false)}>
        <p>
          The template you used has an attachment, but the SMS node cannot send attachments. You can still use the template in text only
          mode. If you want to send a message with an attachment, you must use the "Send MMS" tile in the journey builder.
        </p>
      </ModalDialog>
    </>;
};