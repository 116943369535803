import React, { useState, useContext, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { snackbarService } from 'components';
import { UserStateContext } from 'context/user-state-context';
import { Stack, Paper, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Typography, Button, Box, TextField, TableSortLabel, CircularProgress, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { updateMotorOemConfigDetail, getMotorOemConfigDetails, finalizeMotorOemConfig, deleteMotorOemConfigDetail, getMotorOemConfig } from 'shared/common.api';
import { cloneDeep } from 'lodash';
import { history } from '../../../root.component';
import { configStatus } from './consts';
class InvalidRank extends Error {}
export const MotorOemRecommendationsConfig = ({
  configuration,
  setConfiguration,
  setPage
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [configurationDetails, setConfigurationDetails] = useState({});
  const [preSaveConfigurationDetails, setPreSaveConfigurationDetails] = useState({});
  const [sortBy, setSortBy] = useState('asc');
  const [openFinalizeModal, setOpenFinalizeModal] = useState(false);
  useEffect(() => {
    const initialize = async () => {
      setSortBy('asc');
      await loadConfigurationDetails();
      setLoading(false);
    };
    initialize();
  }, [asCompany.id]);
  const getAndSetLatestConfiguration = async () => {
    const configResponse = await getMotorOemConfig(configuration.id);
    const configData = configResponse.data;
    setConfiguration(configData);
  };
  const flipSortBy = () => {
    let newSort = null;
    if (sortBy === 'asc') {
      newSort = 'desc';
    } else if (sortBy === 'desc') {
      newSort = 'asc';
    } else {
      newSort = 'asc';
    }
    return newSort;
  };
  const translateSortBy = field => {
    let fieldOrder = `${field}`;
    if (sortBy === 'desc') {
      fieldOrder = '-'.concat(fieldOrder);
    }
    return fieldOrder;
  };
  const formatConfigurationDetail = (config, name) => {
    return {
      rank: config.rank,
      title: config.title,
      description: config.description,
      url: config.url ? config.url : '',
      config: config.config,
      name: name,
      invalidated_when: config.invalidated_when
    };
  };
  const loadConfigurationDetails = async () => {
    const configDetailsResponse = await getMotorOemConfigDetails({
      company: asCompany.id,
      config: configuration.id,
      limit: 999,
      ordering: translateSortBy('rank')
    });
    const formattedConfigurations = {};
    for (const config of configDetailsResponse.data.results) {
      formattedConfigurations[config.id] = formatConfigurationDetail(config, config.motor_oem_recommendation.full_name);
    }
    setConfigurationDetails(formattedConfigurations);
    setPreSaveConfigurationDetails(formattedConfigurations);
  };

  /**
   * Throws an exception to be caught if there is an invalid rank.
   * @param {string} configId
   * @returns {boolean}
   */
  const hasValidRank = async configId => {
    const totalRanks = Object.keys(configurationDetails).length;
    const configurationDetail = configurationDetails[configId];
    if (configurationDetail.rank < 1 || configurationDetail.rank > totalRanks) {
      throw new InvalidRank(`${configurationDetail.name} must have a rank inbetween 1 and ${totalRanks}`);
    }
  };

  // Update Functions
  const updateRank = async (configId, newValue) => {
    if (isNaN(newValue)) {
      throw new InvalidRank(`${newRank} is not a number`);
    }
    const castNewValue = Number(newValue);
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[configId].rank = castNewValue;
    setConfigurationDetails(copiedConfiguration);
  };
  const updateTitle = async (configId, newValue) => {
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[configId].title = newValue;
    setConfigurationDetails(copiedConfiguration);
  };
  const updateDescription = async (configId, newValue) => {
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[configId].description = newValue;
    setConfigurationDetails(copiedConfiguration);
  };
  const updateUrl = async (configId, newValue) => {
    const copiedConfiguration = {
      ...cloneDeep(configurationDetails)
    };
    copiedConfiguration[configId].url = newValue;
    setConfigurationDetails(copiedConfiguration);
  };
  const configDetailChanged = (configId, propertyName) => {
    if (configurationDetails[configId][propertyName] === preSaveConfigurationDetails[configId][propertyName]) {
      return false;
    }
    return true;
  };
  const saveConfigDetail = async configId => {
    try {
      const configDetail = configurationDetails[configId];
      await updateMotorOemConfigDetail(configId, configDetail);
      // Keep the pre-save states up to date with new changes
      const copiedConfiguration = {
        ...cloneDeep(configurationDetails)
      };
      setPreSaveConfigurationDetails(copiedConfiguration);
    } catch (error) {
      console.error(error);
      snackbarService.popup({
        type: 'error',
        message: 'Issue attempting to save configuration.'
      });
    }
  };
  const finalizeConfiguration = async () => {
    try {
      await validateAll();
      await finalizeMotorOemConfig(configuration.id);
      snackbarService.popup({
        type: 'success',
        message: 'Successfully saved configuration.'
      });
      history.push('/companies');
    } catch (error) {
      if (error instanceof InvalidRank) {
        snackbarService.popup({
          type: 'error',
          message: error.message
        });
      } else {
        console.error(error);
        snackbarService.popup({
          type: 'error',
          message: 'Issue Attempting to finalize configuration.'
        });
      }
    }
  };

  /**
   * Does one final pass through all the data regarding anything we care
   * about.  If an error is hit, we stop and allow the user to address.
   * @returns {null}
   */
  const validateAll = async () => {
    const ranks = [];
    for (const [configId, configDetail] of Object.entries(configurationDetails)) {
      await hasValidRank(configId);
      if (ranks.includes(configDetail.rank)) {
        throw new InvalidRank(`There are duplicate rank values of ${configDetail.rank}.  Please ensure each configuration is uniquely ranked.`);
      }
      ranks.push(configDetail.rank);
    }
  };

  /**
   * States in which we absolutely do not want to allow editing.
   *
   * TODO I hate how I wrote this, it went through a few refactors
   * TODO refactor and split DRAFT / COMPLETED and
   * rework the configIsDisabled logic where used
   */
  const configIsDisabled = () => {
    const disabledStates = [configStatus.NEW_IN_PROGRESS, configStatus.IN_PROGRESS, configStatus.DRAFT, configStatus.COMPLETED];
    return disabledStates.includes(configuration.status);
  };

  /** Defines what a disabled configuration detail looks like.  */
  const configDetailIsDisabled = configDetailId => {
    const isInvalidated = !!configurationDetails[configDetailId].invalidated_when;
    return !isInvalidated;
  };

  /** Controls ranks availability to be edited.
      In the instance that one rank is being edited, we must allow all to be edited 
      to fix the rank mismatches. */
  const rankIsDisabled = configDetailId => {
    for (const [index, configurationDetail] of Object.entries(configurationDetails)) {
      if (configurationDetail.invalidated_when) {
        return false;
      }
    }
    return configDetailIsDisabled(configDetailId) && configIsDisabled();
  };
  const titleIsDisabled = configDetailId => {
    return configDetailIsDisabled(configDetailId) && configIsDisabled();
  };
  const descriptionIsDisabled = configDetailId => {
    return configDetailIsDisabled(configDetailId) && configIsDisabled();
  };
  const urlIsDisabled = configDetailId => {
    return configDetailIsDisabled(configDetailId) && configIsDisabled();
  };

  /** Show ability to edit only when config is non processing and in certain "editable" states.  */
  const showEditOptions = () => {
    if (configuration.status === configStatus.DRAFT || configuration.status === configStatus.COMPLETED) {
      return true;
    }
    return false;
  };

  /** The ability to edit should only show up under certain conditions,
   * and, should dissapear once we're mid edit for an option.
   */
  const disableEditButton = configDetailId => {
    if (configuration.status === configStatus.COMPLETED) {
      return false;
    } else if (configuration.status === configStatus.DRAFT) {
      return !configDetailIsDisabled(configDetailId);
    }
    return true;
  };
  const displayFriendlyConfigurationStatus = () => {
    if (configuration.status === configStatus.COMPLETED) {
      return <Grid sx={{
        padding: '5px'
      }}>
          <Typography>Recommendations Generated</Typography>
        </Grid>;
    } else if (configuration.status === configStatus.IN_PROGRESS || configuration.status === configStatus.NEW_IN_PROGRESS) {
      return <Grid sx={{
        padding: '5px'
      }}>
          <Typography>Status</Typography>
          <Grid sx={{
          marginTop: '1rem'
        }} container alignItems="center" direction="row">
            <CircularProgress sx={{
            color: '#FFB82B'
          }} size={'30px'} />
            <Typography sx={{
            paddingLeft: '1rem',
            color: '#FFB82B',
            size: '30px'
          }}>Recommendations building</Typography>
          </Grid>
        </Grid>;
    } else if (configuration.status === configStatus.NEW) {
      return <Grid sx={{
        padding: '5px'
      }}>
          <Typography>Please configure your recommendations and select `Finish` to finalize and generate recommendations.</Typography>
        </Grid>;
    } else if (configuration.status === configStatus.DRAFT) {
      return <Grid sx={{
        padding: '5px'
      }}>
          <Typography>
            Recommendation generation is currently paused. Please finalize your configuration to resume generating recommendations.
          </Typography>
        </Grid>;
    }
  };

  /**
   * When we delete recommendations we need to go over / re-rank /
   * save the recommendations with the new organized ranks.
   * */
  const reRankRecommendations = copiedConfigurationDetails => {
    const currentRanking = {};
    for (const [id, configurationDetail] of Object.entries(copiedConfigurationDetails)) {
      currentRanking[configurationDetail.rank] = {
        id,
        configurationDetail
      };
    }
    let assignableRank = 1;
    const reRanking = {};
    while (Object.keys(currentRanking).length !== 0) {
      const minKey = Math.min(...Object.keys(currentRanking).map(Number));
      const detail = currentRanking[minKey];
      reRanking[detail.id] = {
        ...detail.configurationDetail,
        rank: assignableRank
      };
      assignableRank += 1;
      delete currentRanking[minKey];
    }
    return reRanking;
  };
  const handleEditClicked = async configDetailId => {
    try {
      const response = await updateMotorOemConfigDetail(configDetailId, {
        invalidated_when: new Date()
      });
      const configDetail = response.data;
      const copiedConfiguration = {
        ...cloneDeep(configurationDetails)
      };
      copiedConfiguration[configDetailId] = formatConfigurationDetail(configDetail, copiedConfiguration[configDetailId].name);
      setConfigurationDetails(copiedConfiguration);
      await getAndSetLatestConfiguration();
      snackbarService.popup({
        type: 'success',
        message: `${copiedConfiguration[configDetailId].name} set to now be editable.`
      });
    } catch (error) {
      console.error(error);
      snackbarService.popup({
        type: 'error',
        message: 'Failed to set configuration into edit mode.'
      });
    }
  };
  const updateRanks = async copiedConfigurationDetails => {
    const promises = [];
    for (const [id, configurationDetail] of Object.entries(copiedConfigurationDetails)) {
      promises.push(updateMotorOemConfigDetail(id, {
        rank: configurationDetail.rank
      }));
    }
    await Promise.all(promises);
  };
  const handleDeleteClicked = async configDetailId => {
    try {
      await deleteMotorOemConfigDetail(configDetailId);
      const copiedConfiguration = {
        ...cloneDeep(configurationDetails)
      };
      const filteredCopiedConfiguration = {};
      for (const [id, configurationDetail] of Object.entries(copiedConfiguration)) {
        if (id !== configDetailId) {
          filteredCopiedConfiguration[id] = configurationDetail;
        }
      }
      const reRankedConfigurationDetails = reRankRecommendations(filteredCopiedConfiguration);
      await updateRanks(reRankedConfigurationDetails);

      // get the latest and reload
      await loadConfigurationDetails();
      await getAndSetLatestConfiguration();
      snackbarService.popup({
        type: 'success',
        message: `Successfully removed ${copiedConfiguration[configDetailId].name}`
      });
    } catch (error) {
      console.error(error);
      snackbarService.popup({
        type: 'error',
        message: 'Failed to delete configuration detail.'
      });
    }
  };
  return <Grid>
      <CssBaseline />
      <div className="wrapper">
        <Stack sx={{
        paddingRight: '20px',
        paddingLeft: '20px',
        paddingTop: '20px'
      }} alignItems="center">
          <Paper sx={{
          width: '100%',
          padding: '5px'
        }}>
            <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
              <Stack sx={{
              margin: 1
            }} direction="row" spacing={'10px'} alignItems={'center'}>
                <Typography sx={{
                fontSize: 18
              }}>2. Customize Your Recommendations |</Typography>
                <Typography sx={{
                fontSize: 14
              }}>
                  You can customize these OEM recommendations to match your business' preferred names, descriptions, and priority.
                </Typography>
              </Stack>

              {(configuration.status === configStatus.NEW || configuration.status === configStatus.DRAFT) && <Stack direction={'row'} spacing={'5px'} sx={{
              pr: '20px'
            }} alignItems={'center'}>
                  <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="outlined" onClick={() => {
                setPage(1);
              }}>
                    Go Back
                  </Button>
                  <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="contained" onClick={async () => {
                setOpenFinalizeModal(true);
              }}>
                    Finish
                  </Button>
                </Stack>}
            </Box>
          </Paper>
        </Stack>

        {/* Shows status once a configuration has finalized one time */}
        {configuration.status !== configStatus.NEW && <Stack sx={{
        paddingRight: '20px',
        paddingLeft: '20px',
        paddingTop: '10px'
      }} alignItems={'center'}>
            <Paper sx={{
          width: '100%',
          padding: '5px'
        }}>
              <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>{displayFriendlyConfigurationStatus()}</Box>
            </Paper>
          </Stack>}

        <Stack sx={{
        paddingRight: '20px',
        paddingLeft: '20px',
        paddingTop: '10px'
      }}>
          <Paper className="secondary-color" elevation={0} sx={{
          width: '100%',
          borderRadius: '14px'
        }}>
            {loading ? <Grid sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '2rem'
          }}>
                <CircularProgress />
              </Grid> : <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>MOTOR Recommendation</TableCell>
                      <TableCell>
                        Rank
                        <TableSortLabel active={true} direction={sortBy} onClick={async event => {
                      const newSort = flipSortBy();
                      setSortBy(newSort);
                      await loadConfigurationDetails();
                    }} />
                      </TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Link</TableCell>
                      {showEditOptions() && <TableCell />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!configurationDetails || Object.entries(configurationDetails).length === 0 ? <TableRow>
                        <TableCell colSpan={5}>Nothing to display here, please choose a recommendation on the previous page.</TableCell>
                      </TableRow> : Object.entries(configurationDetails).map(([configDetailId, data]) => {
                  return <TableRow key={configDetailId}>
                            <TableCell sx={{
                      padding: '8px',
                      paddingLeft: '20px'
                    }}>{data.name}</TableCell>
                            <TableCell sx={{
                      padding: '8px',
                      width: '2%'
                    }}>
                              <TextField sx={{
                        input: {
                          textAlign: 'center'
                        },
                        width: '100%'
                      }} disabled={rankIsDisabled(configDetailId)} size="small" value={data.rank} onChange={async event => {
                        await updateRank(configDetailId, event.target.value);
                      }} onBlur={async () => {
                        if (configDetailChanged(configDetailId, 'rank')) {
                          try {
                            await hasValidRank(configDetailId);
                            await saveConfigDetail(configDetailId);
                          } catch (error) {
                            if (error instanceof InvalidRank) {
                              snackbarService.popup({
                                type: 'error',
                                message: error.message
                              });
                            } else {
                              console.error(error);
                            }
                          }
                        }
                      }} />
                            </TableCell>
                            <TableCell sx={{
                      padding: '8px'
                    }}>
                              <TextField sx={{
                        width: '100%'
                      }} disabled={titleIsDisabled(configDetailId)} size="small" value={data.title} onChange={async event => {
                        await updateTitle(configDetailId, event.target.value);
                      }} onBlur={async () => {
                        if (configDetailChanged(configDetailId, 'title')) {
                          await saveConfigDetail(configDetailId);
                        }
                      }} />
                            </TableCell>
                            <TableCell sx={{
                      width: '30%',
                      padding: '8px'
                    }}>
                              <TextField sx={{
                        width: '100%'
                      }} disabled={descriptionIsDisabled(configDetailId)} size="small" value={data.description} onChange={async event => {
                        await updateDescription(configDetailId, event.target.value);
                      }} onBlur={async () => {
                        if (configDetailChanged(configDetailId, 'description')) {
                          await saveConfigDetail(configDetailId);
                        }
                      }} />
                            </TableCell>
                            <TableCell sx={{
                      padding: '8px'
                    }}>
                              <TextField sx={{
                        width: '100%'
                      }} disabled={urlIsDisabled(configDetailId)} size="small" value={data.url} onChange={async event => {
                        await updateUrl(configDetailId, event.target.value);
                      }} onBlur={async () => {
                        if (configDetailChanged(configDetailId, 'url')) {
                          await saveConfigDetail(configDetailId);
                        }
                      }} />
                            </TableCell>
                            {showEditOptions() ? <TableCell sx={{
                      padding: '8px'
                    }}>
                                <Grid container>
                                  <Grid item xs={6}>
                                    <Button disabled={disableEditButton(configDetailId)} onClick={async () => {
                            await handleEditClicked(configDetailId);
                          }}>
                                      Edit
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button onClick={async () => {
                            await handleDeleteClicked(configDetailId);
                          }}>
                                      Delete
                                    </Button>
                                  </Grid>
                                </Grid>
                              </TableCell> : <TableCell />}
                          </TableRow>;
                })}
                  </TableBody>
                </Table>
              </TableContainer>}
          </Paper>
        </Stack>
      </div>
      <Dialog open={openFinalizeModal}>
        <DialogTitle>Build MOTOR OEM Recommendations</DialogTitle>
        {configuration.status === configStatus.COMPLETED ? <DialogContent>
            This will now build MOTOR OEM recommendations for all vehicles that have a transaction in the past year.
            <p />
            Once the recommendations are generated you will have the ability to edit the configuration again if required.
          </DialogContent> : <DialogContent>
            This will rebuild MOTOR OEM recommendations for all vehicles relative to the newly suggested recommendations.
            <p />
            Once the MOTOR OEM recommendations are generated you will have the ability to once again edit the configuration.
          </DialogContent>}

        <DialogActions>
          <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
            <Button disabled={false} onClick={() => {
            setOpenFinalizeModal(false);
          }}>
              Continue Editing
            </Button>
            <Button disabled={false} onClick={async () => {
            await finalizeConfiguration();
          }}>
              Build Recommendations
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Grid>;
};