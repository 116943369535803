import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import EnhancedTable from '../../../components/mui/enhanced-table/enhanced-table.component';
import CssBaseline from '@mui/material/CssBaseline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import validator from 'validator';
import { UserStateContext } from 'context/user-state-context';
import { Loader, PageHeaderMui } from 'components';
import { Box, Button, IconButton, Stack, Tooltip, Typography, Divider, Grid } from '@mui/material';
import { HandymanIcon, OpenInNewWindowIcon } from 'components/mui';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { getPrebuiltJourney, getPrebuiltJourneyInstalls, getPrebuiltJourneyVersionMetaData, getPrebuiltJourneyVersions } from 'shared/common.api';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { allNodes } from '../../../pages/customer-journeys/node/node-list';
export const PrebuiltAdminJourneysUsageStats = ({
  history,
  location,
  match,
  staticContext
}) => {
  const {
    user,
    asCompany,
    hasPermission
  } = useContext(UserStateContext);
  const [isLoading, setIsLoading] = useState(true);
  const [versions, setVersions] = useState([]);
  const [renderIteration, setRenderIteration] = useState(0);
  const [prebuiltJourney, setPrebuiltJourney] = useState(null);

  // Related prebuilt content
  const [showRelatedContent, setShowRelatedContent] = useState(false);
  const [contentSegments, setContentSegments] = useState([]);
  const [contentEmails, setContentEmails] = useState([]);
  const [contentPrints, setContentPrints] = useState([]);
  const [contentSms, setContentSms] = useState([]);
  const [contentSurveys, setContentSurveys] = useState([]);
  useEffect(() => {
    if (!user || !asCompany?.id) {
      return;
    }
    setIsLoading(true);
    if (!validator.isUUID(match.params.id)) {
      snackbarService.popup({
        type: 'error',
        message: 'No Id provided.'
      });
      history.push('/prebuilt/admin');
      return;
    }
    const promises = [getPrebuiltJourneyVersions({
      prebuilt: match.params.id
    }), getPrebuiltJourney(match.params.id)];
    Promise.all(promises).then(async ([versionsResults, prebuiltJourney]) => {
      setVersions(versionsResults.data.results);
      setPrebuiltJourney(prebuiltJourney.data);

      // get the related metadata (content)
      const prebuiltSegments = [];
      const prebuiltEmails = [];
      const prebuiltSms = [];
      const prebuiltPrints = [];
      const prebuiltSurveys = [];
      const response = await getPrebuiltJourneyVersionMetaData(prebuiltJourney.data.active_version.id);
      for (const metadata of response.data) {
        if (metadata.prebuilt_content_type === 'segment') {
          prebuiltSegments.push(metadata);
        } else if (metadata.prebuilt_content_type === 'email') {
          prebuiltEmails.push(metadata);
        } else if (metadata.prebuilt_content_type === 'sms') {
          prebuiltSms.push(metadata);
        } else if (metadata.prebuilt_content_type === 'print') {
          prebuiltPrints.push(metadata);
        } else if (metadata.prebuilt_content_type === 'survey') {
          prebuiltSurveys.push(metadata);
        }
      }
      setContentSegments(prebuiltSegments);
      setContentEmails(prebuiltEmails);
      setContentPrints(prebuiltPrints);
      setContentSms(prebuiltSms);
      setContentSurveys(prebuiltSurveys);
      setIsLoading(false);
    });
  }, [hasPermission, asCompany?.id]);
  const filterOptions = [{
    component: MultiSelectFilter,
    label: 'Version',
    pluralLabel: 'Versions',
    key: 'version',
    options: versions?.map(i => ({
      label: i.version,
      value: i.id
    }))
  }, {
    component: DateRangeFilter,
    label: 'Installed Date',
    key: 'created'
  }];
  const [filters, setFilters] = React.useState(() => getFiltersFromURL(filterOptions));
  const headCells = [{
    key: 'company__name',
    align: 'left',
    disablePadding: true,
    label: 'Name',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    width: '40%',
    displayComponent: ({
      data
    }) => <Stack direction="row" spacing={1}>
          <Typography sx={{
        display: 'flex',
        alignItems: 'center'
      }}> {data.company_name}</Typography>
        </Stack>
  }, {
    key: 'prebuilt_version__version',
    align: 'left',
    disablePadding: true,
    label: 'Version',
    type: 'string',
    width: '30%',
    displayComponent: ({
      data
    }) => <>{data.version}</>
  }, {
    key: 'created_when',
    align: 'left',
    disablePadding: true,
    label: 'Installed Date',
    width: '25%',
    displayComponent: ({
      data,
      index,
      onHover
    }) => <>{moment(data.created_when).format('MMM D, YYYY')}</>
  }];
  const PrebuiltJourneysInstallsApiWrapper = () => {
    return params => {
      delete params.company;
      return getPrebuiltJourneyInstalls({
        prebuilt_parent: match.params.id,
        ...params
      });
    };
  };
  const handleNamePrefixAction = () => {
    history.push({
      pathname: window?.location?.pathname + '/edit'
    });
  };
  const handleGoToModel = data => {
    history.push(`/customer-journeys/${data.journey}`);
  };

  /**
   * There are functions that do this that we can leverage,
   * however, after some expirimenting I found it was easier
   * to just loop through the allNodes variable.
   */
  const getNodeColor = node_name => {
    for (const node of allNodes) {
      if (node.subType === node_name) {
        return `#${node.color}`;
      }
    }
  };

  /**
   * There are functions that do this that we can leverage,
   * however, after some expirimenting I found it was easier
   * to just loop through the allNodes variable.
   */
  const getNodeIcon = node_name => {
    for (const node of allNodes) {
      if (node.subType === node_name) {
        return node.img;
      }
    }
  };
  const parseTitle = title => {
    if (!title) {
      return 'No Title';
    }
    if (title.length >= 10) {
      return `${title.substring(0, 10)}...`;
    }
    return title;
  };
  return <>
      <CssBaseline />
      {isLoading && <Loader overlay />}
      <>
        <div className="wrapper">
          <PageHeaderMui type={'Prebuilt Admin - Journeys'} typeHistoryOverride={'/prebuilt/admin/journeys'} namePrefix={prebuiltJourney?.active_version?.title || ''} namePrefixAction={e => {
          handleNamePrefixAction();
        }} name="Usage Stats" icon={<HandymanIcon />} />

          {/* Top Portion / controls */}
          <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
            <Stack direction="row" spacing="32px">
              <Box sx={{
              px: '20px',
              pt: '20px',
              width: 'fit-content'
            }}>
                <Stack sx={{
                borderRadius: '32px',
                backgroundColor: '#fff',
                p: '16px'
              }} spacing="8px" direction={'row'}>
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}> Status: Released</Box>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    Latest Released Version #: {prebuiltJourney?.active_version?.version}
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    Total Install Count: {prebuiltJourney?.install_count}
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box sx={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    {' '}
                    Tags Count: {prebuiltJourney?.tags?.length || 0}{' '}
                  </Box>
                </Stack>
              </Box>
            </Stack>
            <Stack direction="row" spacing="32px">
              <Box sx={{
              px: '20px',
              pt: '20px',
              width: 'fit-content'
            }}>
                <Stack sx={{
                p: '16px'
              }} spacing="8px" direction={'row'}>
                  <Button onClick={e => {
                  history.push(`/prebuilt/admin/journeys/${prebuiltJourney?.id}/edit`);
                }} variant="contained">
                    Edit Version Details
                  </Button>
                  <Button onClick={e => {
                  history.push(`/prebuilt/admin/journeys/${prebuiltJourney?.id}/add-new-version`);
                }} variant="contained">
                    Make New Version
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>

          {/* TODO what to dispaly when there's no relative content...? */}
          {/* Metadata AKA Related prebuilt contents*/}
          {(contentSegments.length > 0 || contentEmails.length > 0 || contentPrints.length > 0 || contentSms.length > 0 || contentSurveys.length > 0) && <Box>
              <Stack direction="row" spacing="32px">
                <Box sx={{
              px: '20px',
              pt: '16px',
              width: '100%'
            }}>
                  <Stack sx={{
                borderRadius: '14px',
                backgroundColor: '#fff',
                p: '16px'
              }}>
                    <Stack direction="row" alignItems="center" sx={{
                  marginBottom: '1rem'
                }}>
                      <Box>
                        <Typography variant="h2">Related Content</Typography>
                      </Box>
                      <Box>
                        {!showRelatedContent ? <ExpandLessIcon onClick={() => {
                      setShowRelatedContent(!showRelatedContent);
                    }} sx={{
                      fontSize: 40,
                      cursor: 'pointer'
                    }} color="primary" /> : <ExpandMoreIcon onClick={() => {
                      setShowRelatedContent(!showRelatedContent);
                    }} sx={{
                      fontSize: 40,
                      cursor: 'pointer'
                    }} color="primary" />}
                      </Box>
                    </Stack>
                    <Stack>
                      {showRelatedContent && <Grid container spacing={2}>
                          {contentSegments.length > 0 && <Grid item xs={12}>
                              <Typography sx={{
                        marginBottom: '1rem'
                      }}>Segments</Typography>
                              {contentSegments.map(content => {
                        const bgColor = getNodeColor(content.node_name);
                        const icon = getNodeIcon(content.node_name);
                        return <Tooltip title={content.version_title} placement="bottom">
                                    <Button startIcon={<img src={icon} alt="icon" style={{
                            width: 16,
                            height: 16
                          }} />} sx={{
                            borderRadius: '4px',
                            padding: '6px',
                            backgroundColor: bgColor,
                            '&:hover': {
                              backgroundColor: bgColor
                            },
                            marginRight: '1rem',
                            maxHeight: '32.5px',
                            width: '120px',
                            marginBottom: '4px'
                          }} variant="contained" key={content.prebuilt_id} onClick={() => {
                            history.push(`/prebuilt/admin/segments/${content.prebuilt_id}/edit`);
                          }}>
                                      {parseTitle(content.version_title)}
                                    </Button>
                                  </Tooltip>;
                      })}
                            </Grid>}
                          {(contentEmails.length > 0 || contentPrints.length > 0 || contentSms.length > 0) && <Grid item xs={12}>
                              <Typography sx={{
                        marginBottom: '1rem'
                      }}>Templates</Typography>
                              {contentEmails.map(content => {
                        const bgColor = getNodeColor(content.node_name);
                        const icon = getNodeIcon(content.node_name);
                        return <Tooltip title={content.version_title} placement="bottom">
                                    <Button startIcon={<img src={icon} alt="icon" style={{
                            width: 16,
                            height: 16
                          }} />} sx={{
                            borderRadius: '4px',
                            padding: '6px',
                            backgroundColor: bgColor,
                            '&:hover': {
                              backgroundColor: bgColor
                            },
                            marginRight: '1rem',
                            maxHeight: '32.5px',
                            width: '120px',
                            marginBottom: '4px'
                          }} variant="contained" key={content.prebuilt_id} onClick={() => {
                            history.push(`/prebuilt/admin/emails/${content.prebuilt_id}/edit`);
                          }}>
                                      {parseTitle(content.version_title)}
                                    </Button>
                                  </Tooltip>;
                      })}
                              {contentPrints.map(content => {
                        const bgColor = getNodeColor(content.node_name);
                        const icon = getNodeIcon(content.node_name);
                        return <Tooltip title={content.version_title} placement="bottom">
                                    <Button startIcon={<img src={icon} alt="icon" style={{
                            width: 16,
                            height: 16
                          }} />} sx={{
                            borderRadius: '4px',
                            padding: '6px',
                            backgroundColor: bgColor,
                            '&:hover': {
                              backgroundColor: bgColor
                            },
                            marginRight: '1rem',
                            maxHeight: '32.5px',
                            width: '120px',
                            marginBottom: '4px'
                          }} variant="contained" key={content.prebuilt_id} onClick={() => {
                            history.push(`/prebuilt/admin/mailers/${content.prebuilt_id}/edit`);
                          }}>
                                      {parseTitle(content.version_title)}
                                    </Button>
                                  </Tooltip>;
                      })}
                              {contentSms.map(content => {
                        const bgColor = getNodeColor(content.node_name);
                        const icon = getNodeIcon(content.node_name);
                        return <Tooltip title={content.version_title} placement="bottom">
                                    <Button startIcon={<img src={icon} alt="icon" style={{
                            width: 16,
                            height: 16
                          }} />} sx={{
                            borderRadius: '4px',
                            padding: '6px',
                            backgroundColor: bgColor,
                            '&:hover': {
                              backgroundColor: bgColor
                            },
                            marginRight: '1rem',
                            maxHeight: '32.5px',
                            width: '120px',
                            marginBottom: '4px'
                          }} variant="contained" key={content.prebuilt_id} onClick={() => {
                            history.push(`/prebuilt/admin/texts/${content.prebuilt_id}/edit`);
                          }}>
                                      {parseTitle(content.version_title)}
                                    </Button>
                                  </Tooltip>;
                      })}
                            </Grid>}
                          {contentSurveys.length > 0 && <Grid item xs={12}>
                              <Typography sx={{
                        marginBottom: '1rem'
                      }}>Surveys</Typography>
                              {contentSurveys.map(content => {
                        const bgColor = getNodeColor(content.node_name);
                        const icon = getNodeIcon(content.node_name);
                        return <Tooltip title={content.version_title} placement="bottom">
                                    <Button startIcon={<img src={icon} alt="icon" style={{
                            width: 16,
                            height: 16
                          }} />} sx={{
                            borderRadius: '4px',
                            padding: '6px',
                            backgroundColor: bgColor,
                            '&:hover': {
                              backgroundColor: bgColor
                            },
                            marginRight: '1rem',
                            maxHeight: '32.5px',
                            width: '120px',
                            marginBottom: '4px'
                          }} variant="contained" key={content.prebuilt_id} onClick={() => {
                            history.push(`/prebuilt/admin/surveys/${content.prebuilt_id}/edit`);
                          }}>
                                      {parseTitle(content.version_title)}
                                    </Button>
                                  </Tooltip>;
                      })}
                            </Grid>}
                        </Grid>}
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Box>}

          {/* Table */}
          <div className="mui-wrapper">
            <EnhancedTable type={'Prebuilt Journey Installs'} headCells={headCells} api={PrebuiltJourneysInstallsApiWrapper()} defaultSort={'-created_when'} handleRowClick={row => {
            handleGoToModel(row);
          }} filterOptions={filterOptions} filtersState={[filters, setFilters]} renderIteration={renderIteration} hoverActions={({
            data
          }) => <Stack direction="row" gap="10px">
                  {hasPermission('template.change_template') && <Tooltip title="Go to Journey">
                      <IconButton sx={{
                ':hover': {
                  backgroundColor: '#DAEFFF'
                }
              }} onClick={e => {
                e.stopPropagation();
                handleGoToModel(data);
              }}>
                        <OpenInNewWindowIcon />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>
        </div>
      </>
    </>;
};