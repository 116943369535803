/**
 * Subject to change, but essentially all the
 * states a top level configuration can be in.
 */
class ConfigStatus {
  NEW = 'new';
  NEW_IN_PROGRESS = 'new_in_progress';
  DRAFT = 'draft';
  IN_PROGRESS = 'in_progress';
  COMPLETED = 'completed';
}
export const configStatus = new ConfigStatus();