import React, { useState, useEffect, useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { SearchIcon, CloseIcon } from 'components/mui';
import { Stack, Paper, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Checkbox, Typography, Button, Box, Divider, Tooltip, IconButton, TextField, InputAdornment, CircularProgress } from '@mui/material';
import { createMotorOemConfigDetail, deleteMotorOemConfigDetail, getMotorOemRecommendations, getMotorOemConfigDetails } from 'shared/common.api';
import { history } from '../../../root.component';
import { withStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      backgroundColor: 'white',
      borderRadius: `14px`,
      '& fieldset': {
        border: 'none',
        padding: 0
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    }
  }
})(TextField);

// TODO nice to have - get some sorting in for all the config_details header columns
export const MotorOemRecommendations = ({
  configuration,
  setPage
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [configurationDetails, setConfigurationDetails] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (search) {
      const matchingRecommendations = [];
      const lowerCaseSearch = search.toLowerCase();
      for (const recommendation of recommendations) {
        if (recommendation.description.toLowerCase().includes(lowerCaseSearch) || recommendation.action.toLowerCase().includes(lowerCaseSearch) || recommendation.full_name.toLowerCase().includes(lowerCaseSearch) || recommendation.group.toLowerCase().includes(lowerCaseSearch) || recommendation.subgroup.toLowerCase().includes(lowerCaseSearch) || recommendation.system.toLowerCase().includes(lowerCaseSearch)) {
          matchingRecommendations.push(recommendation);
        }
      }
      setFilteredRecommendations(matchingRecommendations);
    } else {
      setFilteredRecommendations(cloneDeep(recommendations));
    }
  }, [search]);
  useEffect(() => {
    const loadConfigurationDetails = async () => {
      const configDetailsResponse = await getMotorOemConfigDetails({
        company: asCompany.id,
        config: configuration.id,
        limit: 999
      });
      const formattedConfigurations = {};
      for (const config of configDetailsResponse.data.results) {
        formattedConfigurations[config.motor_oem_recommendation.id] = formatConfigDetail(config);
      }
      setConfigurationDetails(formattedConfigurations);
    };
    const retrieveRecommendations = async () => {
      const response = await getMotorOemRecommendations({
        limit: 999,
        ordering: 'system,group,subgroup,description,action,full_name'
      });
      setRecommendations(response.data.results);
      setFilteredRecommendations(response.data.results);
    };
    const initialize = async () => {
      await retrieveRecommendations();
      await loadConfigurationDetails();
      setLoading(false);
    };
    initialize();
  }, [asCompany.id]);
  const handleOpeningSearch = () => {
    setOpenSearch(!openSearch);
  };
  const formatConfigDetail = config => {
    return {
      id: config.id,
      rank: config.rank,
      title: config.title,
      description: config.description,
      url: config.url ? config.url : '',
      config: config.config,
      name: config.motor_oem_recommendation.full_name,
      deleted_when: config.deleted_when
    };
  };
  const isChecked = recommendationId => {
    if (configurationDetails[recommendationId]) {
      return true;
    }
    return false;
  };
  const changeCheckedState = async recommendation => {
    const configurationDetailId = configurationDetails[recommendation.id]?.id;
    if (isChecked(recommendation.id)) {
      try {
        await deleteMotorOemConfigDetail(configurationDetailId);
        const copyOfConfigDetails = {
          ...configurationDetails
        };
        delete copyOfConfigDetails[recommendation.id];
        setConfigurationDetails(copyOfConfigDetails);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const payload = {
          company: asCompany.id,
          config: configuration.id,
          description: recommendation.description,
          rank: populateRanking(),
          title: recommendation.group,
          url: null,
          motor_oem_recommendation: recommendation.id
        };
        const response = await createMotorOemConfigDetail(payload);
        const formattedConfig = formatConfigDetail(response.data);
        const copyOfConfigDetails = {
          ...configurationDetails
        };
        copyOfConfigDetails[recommendation.id] = formattedConfig;
        setConfigurationDetails(copyOfConfigDetails);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const populateRanking = () => {
    const currentRanks = [];
    let configurationCount = 0;
    for (const [recommendationId, configDetails] of Object.entries(configurationDetails)) {
      if (configDetails.rank) {
        currentRanks.push(configDetails.rank);
      }
      configurationCount++;
    }
    // since this is called pre-save add an extra to know full available
    configurationCount++;
    let availableRank = null;
    for (let rank = 1; rank <= configurationCount; rank++) {
      if (!currentRanks.includes(rank)) {
        availableRank = rank;
        break;
      }
    }
    if (availableRank) {
      return availableRank;
    }
    return 0;
  };
  const continueToNextPage = async () => {
    setPage(2);
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'} padding={'3rem'} alignItems={'center'}>
          <Paper sx={{
          width: '100%',
          padding: '5px'
        }}>
            <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
              <Stack sx={{
              margin: 1
            }} direction="row" spacing={'10px'} alignItems={'center'}>
                <Typography sx={{
                fontSize: 18
              }}>1. Select Your Services |</Typography>
                <Typography sx={{
                fontSize: 14
              }}>
                  You may not offer every service the OEM recommends. Please select the services that are relevant to your business.
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={'10px'} sx={{
              pr: '20px'
            }} alignItems={'center'}>
                <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="outlined" onClick={() => {
                history.goBack();
              }}>
                  Go Back
                </Button>
                <Button sx={{
                width: '115px',
                height: '30px'
              }} variant="contained" onClick={async () => {
                await continueToNextPage();
              }}>
                  Next
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Stack>
        <Stack sx={{
        p: '20px'
      }} spacing={'20px'}>
          <Paper sx={{
          borderRadius: '14px',
          minHeight: '75vh'
        }}>
            <Stack sx={{
            pt: '8px'
          }} spacing={'20px'}>
              {loading ? <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pt: '100px'
            }}>
                  <CircularProgress />
                </Box> : <Grid sx={{
              px: '8px'
            }} container spacing={{
              xs: 2,
              sm: 2,
              md: 3,
              lg: 3
            }} columns={{
              xs: 4,
              sm: 12,
              md: 12,
              lg: 12
            }}>
                  <Grid sx={{
                padding: '2px'
              }} container justifyContent={'flex-end'}>
                    <Box>
                      <Stack spacing={'20px'} direction={'row'} sx={{
                    pr: '20px',
                    ml: 'auto'
                  }}>
                        <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />
                        {openSearch ? <Box sx={{
                      height: '42px'
                    }}>
                            <CustomTextField id="outlined-basic" autoFocus={true} placeholder={'Search'} sx={{
                        width: '290px',
                        height: '56px',
                        pr: '20px',
                        py: 0
                      }} onChange={e => {
                        setSearch(e.target.value || '');
                      }} value={search || ''} InputProps={{
                        startAdornment: <InputAdornment position="start">
                                    <Tooltip title="Collapse Search">
                                      <IconButton sx={{
                              width: '40px',
                              height: '40px',
                              textAlign: 'center',
                              padding: 0,
                              backgroundColor: '#fff',
                              color: '#1D252D',
                              ':hover': {
                                backgroundColor: '#DAEFFF',
                                color: '#53A6D6'
                              }
                            }} onClick={() => {
                              handleOpeningSearch();
                            }}>
                                        <SearchIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>,
                        endAdornment: !!search && <InputAdornment onClick={e => {
                          setSearch('');
                        }} position="start" sx={{
                          cursor: 'pointer'
                        }}>
                                    <CloseIcon />
                                  </InputAdornment>
                      }} />
                          </Box> : <Tooltip title={'Search'}>
                            <IconButton sx={{
                        width: '40px',
                        height: '40px',
                        textAlign: 'center',
                        padding: 0,
                        backgroundColor: '#fff',
                        color: '#1D252D',
                        ':hover': {
                          backgroundColor: '#DAEFFF',
                          color: '#53A6D6'
                        }
                      }} onClick={() => {
                        handleOpeningSearch();
                      }}>
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>}
                        <Divider orientation="vertical" sx={{
                      height: '32px'
                    }} />
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '5px'
                    }}>
                          {filteredRecommendations.length + ' items'}
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>

                  <Grid sx={{
                width: '100%'
              }}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>System</TableCell>
                            <TableCell>Group</TableCell>
                            <TableCell>Subgroup</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Full Name</TableCell>
                            <TableCell>Include</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!filteredRecommendations ? <TableRow>
                              <TableCell colSpan={7}>No Data</TableCell>
                            </TableRow> : filteredRecommendations.map(recommendation => {
                        return <TableRow key={recommendation.id} sx={{
                          height: '10px'
                        }}>
                                  <TableCell sx={{
                            padding: '4px'
                          }} sortDirection={'asc'}>
                                    {recommendation.system}
                                  </TableCell>
                                  <TableCell>{recommendation.group}</TableCell>
                                  <TableCell>{recommendation.subgroup}</TableCell>
                                  <TableCell>{recommendation.description}</TableCell>
                                  <TableCell>{recommendation.action}</TableCell>
                                  <TableCell>{recommendation.full_name}</TableCell>
                                  <TableCell>
                                    <Checkbox sx={{
                              color: '#1D252D',
                              '&.Mui-checked': {
                                color: '#53A6D6'
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: 22
                              }
                            }} color="primary" checked={isChecked(recommendation.id)} onClick={async () => {
                              await changeCheckedState(recommendation);
                            }} />
                                  </TableCell>
                                </TableRow>;
                      })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>}
            </Stack>
          </Paper>
        </Stack>
      </div>
    </>;
};