import React, { useEffect, useState, createContext, useCallback, useRef } from 'react';
import ChangedWorkingAsModal from './changed-working-as.component';
import NeedsPhoneModal from './needs-phone.component';
import NeedsPasswordResetModal from './needs-password-reset.component';
import { getCompany, getCompanyFields, getUserCompanies, getCompanyFlags, isUserInLaunchDarklySegment, getActionItems, getLocations, getReleaseNotes, getDomains } from 'shared/common.api';
import { getInstalls } from '../pages/integrations/integrations.resource';
import * as Sentry from '@sentry/react';
import { toasterService } from 'components/toaster/toaster-service';
import { history } from '../root.component';
import { DateTime } from 'luxon';
import { api } from '../shared/api.js';
import { getMe, logOut as logOutApi } from '../shared/auth.api';
import Cookies from 'js-cookie';
import { Confirm } from 'components/mui';
import { Box } from '@mui/material';
import * as FullStory from '@fullstory/browser';
import CONSTANTS from 'shared/constants';

// Keys for localstorage items.
const TOKEN = 'cinch.userToken';
const TOKEN_EXPIRY = 'cinch.userTokenExpires';
const AS_COMPANY = 'cinch.asCompany';
export const UserStateContext = createContext();
export const UserStateContextProvider = ({
  children
}) => {
  // Global state items

  /**
   * Remembers the last page we were on and redirects there after a successful log in.
   *
   * @param {string} redirect
   */
  const [redirect, setRedirect] = useState('/companies');

  /**
   * Remembers the last page we were on and redirects there after a successful log in.
   *
   * @param {string} redirect
   */
  const [needsMFA, setNeedsMFA] = useState(false);

  /**
   * Company object that holds the current company we're "Working As". Sometimes it may only
   * contain the `id` key before it gets loaded with company information from an API call.
   *
   * By default we first check the URL's `?company=` query param for the company to start with.
   * If there isn't a company in the query param then check if we saved one in the local storage.
   *
   * @param {Object} asCompany
   */
  const [asCompany, setAsCompany] = useState(() => {
    // First check if we're setting the company ID from the URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    let companyId = urlParams.get('company');
    if (!companyId) {
      // If we don't have a company ID in the query params check browser storage
      companyId = Cookies.get(AS_COMPANY);
    }
    if (companyId) {
      // If we found a company ID set it to the state and our `useEffect` will load it..
      return {
        id: companyId
      };
    }
  });

  /**
   * Token to use in the API.
   *
   * By default this is first loaded from the query parm `?authentication=` but if there
   * isn't one in the query params we then load it from local starge if the token expiration
   * is in the future.
   *
   * @param {string} token
   */
  const [token, setTokenState] = useState(() => {
    // First check for token in the query parameters
    const searchParams = new URLSearchParams(window.location.search);
    const authenticationParam = searchParams.get('authentication');
    if (authenticationParam) {
      setRedirect((window.location.pathname || '/company') + '?' + searchParams);
      return authenticationParam;
    }

    // Next check for token in storage
    const token = Cookies.get(TOKEN);
    const tokenExpiry = Cookies.get(TOKEN_EXPIRY);
    const notExpired = DateTime.fromISO(tokenExpiry).toMillis() - DateTime.local().toUTC().toMillis() > 0;
    if (notExpired) {
      // If the token is not expired we assume it's still valid though we may find out otherwise shortly.
      return token;
    }
  });
  const setToken = token => {
    setTokenState(token);
    tokenRef.current = token;
  };

  /**
   * Holds the token in a ref so we can use it in the interceptor before it is used in the useEffect(s).
    * @param {string} tokenRef
   **/

  const tokenRef = useRef(token);

  /**
   * Holds the token's expiration date so we can save ourselves the trouble of using an expired token.
   *
   * @param {string} tokenExpiry
   */
  const [tokenExpiry, setTokenExpiry] = useState(() => {
    // Check for token in storage.
    const tokenExpiry = Cookies.get(TOKEN_EXPIRY);
    const notExpired = DateTime.fromISO(tokenExpiry).toMillis() - DateTime.local().toUTC().toMillis() > 0;
    if (notExpired) {
      // If the token is not expired we assume it's still valid though we may find out otherwise shortly.
      return tokenExpiry;
    }
  });

  /**
   * When `undefined` that means we haven't loaded a user yet and we're still in a loading state.
   * When `null` that means we're loaded and we know that we aren't logged in nor have a valid user.
   * When `Object` that means we're logged in and have a user.
   *
   * @param {undefined|null|Object} user User object that contains the currently logged in user's data.
   */
  const [user, setUser] = useState(undefined);

  /**
   * @param {list} installedIntegrations List of installed integrations for the company.  This is used on certain pages to show what can and can't be used.
   *
   */
  const [installedIntegrations, setInstalledIntegrations] = useState([]);
  const [companyFields, setCompanyFields] = useState([]);
  const [isLoadingUserStateApis, setIsLoadingUserStateApis] = useState(true);

  /**
   * This state variables are used when checking if the currently looked at page is the same company we're "working as"
   * and ask them if they want to switch.
   *
   * @param {Object} newCompany
   * @param {Object} oldCompany
   * @param {Promise} checkPromise
   */
  const [newCompany, setNewCompany] = useState();
  const [oldCompany, setOldCompany] = useState();
  const [checkPromise, setCheckPromise] = useState();

  /**
   * Feature Flags
   *
   * This is loaded with flags from Launch Darkly.  It will merge both the company and user flags.
   *
   * @param {object} flags
   */
  const [flags, setFlags] = useState({});
  const [isLoadingFlags, setIsLoadingFlags] = useState(true);

  /**
   * Whenever a user changes companies we need to reload their flags.
   */
  useEffect(() => {
    if (!token || !asCompany?.id || !user?.id) {
      return;
    }
    const fetchData = async () => {
      for (let i = 0; i < 3; i++) {
        try {
          const {
            data: flags
          } = await getCompanyFlags(asCompany.id);
          const newFlags = {};
          Object.keys(flags).forEach(key => {
            newFlags[key] = flags[key] || user?.flags?.[key];
          });
          setFlags(newFlags);
          return;
        } catch (e) {
          // Unable to fetch flags, try again in 1 - 3 second
          await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
        }
      }
      throw new Error('Unable to fetch flags');
    };

    // call the function
    setIsLoadingFlags(true);
    fetchData()
    // make sure to catch any error
    .catch(() => {
      setFlags(user?.flags || {});
    }).then(() => {
      // After we're all done either on success or failure, set the app as ready
      setIsLoadingFlags(false);
    });
  }, [token, asCompany?.id, user?.flags, user?.id]);
  useEffect(() => {
    const passToken = config => {
      if (config.url !== '/users/login') {
        // const token = Cookies.get(TOKEN);
        const token = tokenRef.current;
        if (token) {
          config.headers['Authorization'] = `Token ${token}`;
        }
      }
      return config;
    };

    // Pass tokens for all requests
    api.interceptors.request.use(passToken);
    const checkResponseSuccess = res => res;
    const checkResponseFailure = err => {
      // If we need MFA redirect there.
      if (err?.response?.status === 401 && err?.response?.data?.detail === 'Token needs to be authenticated with MFA') {
        setNeedsMFA(true);
        if (window.location.pathname !== '/mfa') {
          history.push('/mfa');
        }
        return 'Token needs to be authenticated with MFA';
      }
      if (err.response && err.response.status === 401) {
        if (err.response.config.url === '/users/logout') {
          history.push('/sign-in');
          return;
        } else if (err.response.config.url !== '/users/me') {
          // Check me
          return getMe().then(({
            status
          }) => {
            if (status !== 200) {
              toasterService.warning('Session timed out - please sign in.');
              logOut();
              return err.response;
            }

            // We had a different problem
            return Promise.reject(err.response);
          });
        } else {
          logOut();
          return;
        }
      }
      return Promise.reject(err);
    };

    // Check if our token expired.
    api.interceptors.response.use(checkResponseSuccess, checkResponseFailure);
    return () => {
      api.interceptors.request.eject(passToken);
      api.interceptors.response.eject(checkResponseSuccess, checkResponseFailure);
    };
  }, []);

  /**
   * When the token changes we need to do the following it:
   * - Add or remove token from local storage depending on if we have a token or not.
   * - Update our API calls to use the new token
   * - Get user info from API and sets it on state
   *
   * @listens token
   * @fires user
   */
  useEffect(() => {
    if (!token) {
      localStorage.removeItem(TOKEN);
      if (CONSTANTS.ENV === 'LOCAL') {
        Cookies.remove(TOKEN);
      } else if (CONSTANTS.ENV === 'DEV') {
        Cookies.remove(TOKEN, {
          domain: '.marketing-dev.cinch.io'
        });
      } else {
        Cookies.remove(TOKEN, {
          domain: '.marketing.cinch.io'
        });
      }
      setUser(false);
      return;
    }
    if (CONSTANTS.ENV === 'LOCAL') {
      Cookies.set(TOKEN, token, {
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else if (CONSTANTS.ENV === 'DEV') {
      Cookies.set(TOKEN, token, {
        domain: '.marketing-dev.cinch.io',
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else {
      Cookies.set(TOKEN, token, {
        domain: '.marketing.cinch.io',
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    }
    setUser(undefined);
    if (!needsMFA) {
      getMe().then(({
        data: user
      }) => {
        setUser(user);
        if (user?.color_preference || user?.color_preference_secondary) {
          const root = document.documentElement;
          root?.style.setProperty('--custom-background-color', user?.color_preference || '#e4e9f1');
          root?.style.setProperty('--custom-background-color-secondary', user?.color_preference_secondary || '#ffffff');
        }
      });
    }
  }, [token, needsMFA]);

  /**
   * Keep local storage in sync with tokenExpiry
   *
   * @listens tokenExpiry
   */
  useEffect(() => {
    if (!tokenExpiry) {
      localStorage.removeItem(TOKEN_EXPIRY);
      if (CONSTANTS.ENV === 'LOCAL') {
        Cookies.remove(TOKEN_EXPIRY);
      } else if (CONSTANTS.ENV === 'DEV') {
        Cookies.remove(TOKEN_EXPIRY, {
          domain: '.marketing-dev.cinch.io'
        });
      } else {
        Cookies.remove(TOKEN_EXPIRY, {
          domain: '.marketing.cinch.io'
        });
      }
      return;
    }
    if (CONSTANTS.ENV === 'LOCAL') {
      Cookies.set(TOKEN_EXPIRY, tokenExpiry, {
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else if (CONSTANTS.ENV === 'DEV') {
      Cookies.set(TOKEN_EXPIRY, tokenExpiry, {
        domain: '.marketing-dev.cinch.io',
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else {
      Cookies.set(TOKEN_EXPIRY, tokenExpiry, {
        domain: '.marketing.cinch.io',
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    }
  }, [tokenExpiry]);

  /**
   * Keep local storage in sync with current "working as" company
   *
   * @listens asCompany.id
   */
  useEffect(() => {
    if (!asCompany?.id) {
      if (CONSTANTS.ENV === 'LOCAL') {
        Cookies.remove(AS_COMPANY);
      } else if (CONSTANTS.ENV === 'DEV') {
        Cookies.remove(AS_COMPANY, {
          domain: '.marketing-dev.cinch.io'
        });
      } else {
        Cookies.remove(AS_COMPANY, {
          domain: '.marketing.cinch.io'
        });
      }
      return;
    }
    if (CONSTANTS.ENV === 'LOCAL') {
      Cookies.set(AS_COMPANY, asCompany.id, {
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else if (CONSTANTS.ENV === 'DEV') {
      Cookies.set(AS_COMPANY, asCompany.id, {
        domain: '.marketing-dev.cinch.io',
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    } else {
      Cookies.set(AS_COMPANY, asCompany.id, {
        domain: '.marketing.cinch.io',
        expires: 365,
        sameSite: 'strict',
        secure: true
      });
    }
  }, [asCompany?.id]);

  /**
   * When the token or "working as" company changes, reload installed integrations and data models
   *
   * @listens token
   * @listens asCompany.id
   * @fires installedIntegrations
   */
  useEffect(() => {
    if (!token || !asCompany?.id || !user?.id) {
      return;
    }
    const fetchData = async () => {
      for (let i = 0; i < 3; i++) {
        try {
          const [{
            results: installedIntegrations
          }, {
            data: fields
          }] = await Promise.all([getInstalls({
            company: asCompany.id,
            limit: 100
          }), getCompanyFields(asCompany.id)]);
          setInstalledIntegrations(installedIntegrations);
          if (fields) {
            setCompanyFields(fields);
          }
          return;
        } catch (e) {
          // Unable to fetch flags, try again in 1 - 3 second
          await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
        }
      }
      throw new Error('Unable to fetch integrations and fields');
    };
    setIsLoadingUserStateApis(true);
    // call the function
    fetchData()
    // make sure to catch any error
    .catch(() => {
      setInstalledIntegrations([]);
      setCompanyFields([]);
    }).then(() => {
      // After we're all done either on success or failure, set the app as ready
      setIsLoadingUserStateApis(false);
    });
  }, [token, asCompany?.id, user?.id]);

  //enrolledInLaunchDarklyBeta
  /**
   * Enroll in Launch Darkly Beta
   *
   * This is used to check if a user is in the Launch Darkly prod beta segment.
   * This does NOT check if they have any specific flags, only use this to determine if they are in the beta segment
   * When a feature is ready to be released to the beta users segment, add the segment to the flag on launchdarkly.com
   *
   * @param {boolean} isUserEnrolledInLaunchDarklyBeta
   */
  const [isUserEnrolledInLaunchDarklyBeta, setIsUserEnrolledInLaunchDarklyBeta] = useState(false);
  useEffect(() => {
    if (!token || !user?.id) {
      return;
    }
    const fetchData = async () => {
      for (let i = 0; i < 3; i++) {
        try {
          const {
            data
          } = await isUserInLaunchDarklySegment({
            segment_key: 'beta'
          });
          setIsUserEnrolledInLaunchDarklyBeta(data);
          return;
        } catch (e) {
          // Unable to fetch flags, try again in 1 - 3 second
          await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
        }
      }
      throw new Error('Unable to fetch launch darkly beta segment info');
    };

    // call the function
    fetchData()
    // make sure to catch any error
    .catch(() => {
      setIsUserEnrolledInLaunchDarklyBeta(false);
    });
  }, [token, user?.id]);

  /**
   * If we don't have asCompany data loaded get it from the API
   *
   * @listens user
   * @listens asCompany.id
   * @fires asCompany      !Warning may cause circular triggers
   */
  const [userRole, setUserRole] = useState(null);
  const [isCatalystUser, setIsCatalystUser] = useState(null);
  const [userLocations, setUserLocations] = useState([]);
  useEffect(() => {
    if (!user?.id || !!asCompany?.is_active) return;
    let asCompanyId = asCompany?.id;
    if (!asCompanyId && user.companies.length > 0) {
      // Grab the users first company and load it.
      asCompanyId = user.companies[0].id;
    }
    if (asCompanyId) {
      const promises = [getCompany(asCompanyId), getUserCompanies(user.id, {
        company: asCompany?.id
      })];
      Promise.all(promises).then(([company, roles]) => {
        setUserRole(roles?.data?.results[0]);
        if (roles?.data?.results[0]?.role_name == 'Catalyst') {
          getLocations({
            company: asCompanyId,
            limit: 1000,
            user_locations: user.id,
            ordering: 'name'
          }).then(({
            data
          }) => {
            setUserLocations(data.results.map(location => location.id));
            setIsCatalystUser(user.id);
            setAsCompany(company.data);
          });
        } else {
          setIsCatalystUser(null);
          setAsCompany(company.data);
        }
      });
    }
  }, [user, asCompany?.id]);
  const [confirmModal, setConfirmModal] = useState(false);
  useEffect(() => {
    // When we switch companies check if the company has SMS enabled.
    // If they don't prompt them to turn it back on.

    if (asCompany?.sms_enabled === false) {
      setConfirmModal(true);
    }
  }, [asCompany?.sms_enabled]);

  /**
   * Keep Pendo and Sentry in sync with current state.
   *
   * @listens user
   * @listens asCompany
   */
  useEffect(() => {
    if (user && asCompany && window.pendo.host && window.pendo?.isReady()) {
      //pendo is already initalized, it just needs an update
      window.pendo.updateOptions({
        visitor: {
          company: asCompany.id,
          companyName: asCompany.name,
          permissions: user.permissions,
          role: user.is_superuser ? 'superuser' : userRole ? userRole?.role_name : 'None'
        },
        account: {
          id: asCompany.id,
          name: asCompany.name
        }
      });
    } else if (user && asCompany) {
      window.pendo.initialize({
        visitor: {
          id: user.id,
          // Required if user is logged in, default creates anonymous ID
          email: user.email,
          // Recommended if using Pendo Feedback, or NPS Email
          full_name: user.first_name + ' ' + user.last_name,
          // Recommended if using Pendo Feedback
          company: asCompany.id,
          companyName: asCompany.name,
          permissions: user.permissions,
          role: user.is_superuser ? 'superuser' : userRole ? userRole?.role_name : 'None',
          industry: asCompany.naics_title
        },
        account: {
          id: asCompany.id,
          // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          name: asCompany.name
        }
      });
      initFullStoryWithUserData();
      Sentry.setContext('working_as', {
        id: asCompany.id,
        name: asCompany.name
      });
    }
  }, [user, asCompany]);

  /**
   * Has Integration.
   *
   * Check if current company has installed a specific integration.
   *
   * @param {string} integration Name of integration we're checking.
   *
   * @return {boolean} Whether or not this company has this integration installed.
   */
  const hasIntegration = useCallback(integration => {
    return installedIntegrations.some(i => i.integration === integration);
  }, [installedIntegrations]);

  /**
   * Check Company.
   *
   * Check if passed in company ID is the one we're currently looking at. If not trigger
   * modal asking if they want to switch to that company.
   *
   * @param {string} companyId ID of company we're checking.
   *
   * @return {Promise} Promise resolves if they user wants to switch.  Promise rejects if they don't want to
   *                   switch or they don't have access to the company we checked.
   */
  const checkCompany = companyId => {
    return new Promise((resolve, reject) => {
      if (asCompany.id != companyId) {
        if (hasPermission('organization.view_company')) {
          getCompany(companyId).then(({
            data: comp
          }) => {
            if (comp) {
              setOldCompany(asCompany);
              setNewCompany(comp);
              setCheckPromise([resolve, reject]);
            } else {
              reject('Unable to find company');
            }
          });
        } else {
          reject('User does not have permission for this company');
        }
      } else {
        resolve();
      }
    });
  };

  /**
   * Has Permission.
   *
   * Check if the user has at leaset one of these permissions for the current company.
   *
   * hasPermission('permission1')
   * hasPermission('permission1', 'permission2')
   *
   * @param {string} permission... One or more permissions to check.
   *
   * @return {boolean} Whether or not this user has permission to at least one of these permissions for the "working as" company.
   */
  const hasPermission = useCallback((...permissions) => {
    if (!user || !user.permissions || !asCompany.id) {
      return false;
    }
    return permissions.some(permission => {
      return !!user.permissions['*'] || user.permissions[permission] && user.permissions[permission].indexOf(asCompany.id) > -1;
    });
  }, [user?.permissions, asCompany?.id]);

  /**
   * Has Multi Permission.
   *
   * Check if the user has permission to more than one company
   *
   * hasMultiPermission('permission1')
   *
   * @param {string} permission Permission to check.
   *
   * @return {boolean} Whether or not this user has permission to multiple companies
   */
  const hasMultiPermission = useCallback(permission => {
    if (!user || !user?.permissions || !asCompany?.id) {
      return false;
    }
    return !!user?.permissions['*'] || user?.permissions[permission]?.length > 1;
  }, [user?.permissions, asCompany?.id]);

  /**
   * Log Out
   *
   * Logs out the current user's session.  The `useEffect` functions will make sure this persists in local storage. Also redirect to
   * the `sign-in` page.
   */
  const logOut = () => {
    // Log out in API
    logOutApi().catch().then(() => {
      setUser(null);
      setAsCompany(null);
      setToken(null);
      setTokenExpiry(null);
      history.push('/sign-in');
    });
  };

  /**
   * Has Multi Access.
   *
   * Check if the user has access to more than one company. This can be used to show whether or not the user can see the "working as" dropdown.
   *
   * @return {boolean} Whether or not this user has access to more than one company.
   */
  const hasMultiAccess = () => {
    return user.companies.length > 1;
  };
  const isValidToken = () => {
    return DateTime.fromISO(tokenExpiry).toMillis() - DateTime.local().toUTC().toMillis() > 0;
  };
  const enabledIntegrationChange = () => {
    getInstalls({
      company: asCompany.id,
      limit: 100
    }).then(({
      results: installedIntegrations
    }) => {
      setInstalledIntegrations(installedIntegrations);
    }).catch(() => {
      // No results....
    });
  };
  const [openPhoneModal, setOpenPhoneModal] = useState(false);
  const [changedPhone, setChangedPhone] = useState(null);
  const triggerNeedsPhoneModal = value => {
    setOpenPhoneModal(value);
  };
  const companyFieldsChange = () => {
    getCompanyFields(asCompany.id).then(({
      data
    }) => {
      setCompanyFields(data);
    }).catch(() => {
      // No results....
    });
  };
  const [actionItems, setActionItems] = useState({});
  useEffect(() => {
    refreshActionItems();
  }, [user?.id, asCompany?.id]);
  const refreshActionItems = () => {
    if (!user?.id || !asCompany?.id) {
      return;
    }
    getActionItems({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setActionItems(data);
    });
  };
  const [releaseNotes, setReleaseNotes] = useState([]);
  useEffect(() => {
    refreshReleaseNotes();
  }, [user?.id]);
  const refreshReleaseNotes = () => {
    if (!user?.id) {
      return;
    }
    getReleaseNotes().then(({
      data
    }) => {
      setReleaseNotes(data.results);
    });
  };
  const initFullStoryWithUserData = () => {
    // FullStory API - push user data to FullStory
    if (user?.id && asCompany?.id && FullStory.isInitialized()) {
      getUserCompanies(user.id, {
        company: asCompany.id
      }).then(({
        data
      }) => {
        FullStory.setUserVars({
          id: user.id,
          email: user.email,
          full_name: user.first_name + ' ' + user.last_name,
          company: asCompany.id,
          companyName: asCompany.name,
          industry: asCompany.naics_title,
          role: user.is_superuser ? 'superuser' : data.results[0] ? data.results[0]?.role_name : 'None'
        });
      });
    }
  };
  const [domainVerifiedModal, setDomainVerifiedModal] = useState(false);
  useEffect(() => {
    if (!user?.id || !!asCompany?.is_active || !asCompany?.id) return;
    getDomains({
      company: asCompany?.id
    }).then(({
      data
    }) => {
      if (flags?.domain_checker && data.results?.length > 0) {
        const status = data.results?.some(domain => {
          if (!!domain.parent_status) {
            return domain.parent_status === 'pending';
          }
          return domain.status === 'pending';
        });
        setDomainVerifiedModal(status);
      }
    });
  }, [user, asCompany?.id]);
  return <UserStateContext.Provider value={{
    asCompany,
    user,
    redirect,
    token,
    checkCompany,
    hasIntegration,
    hasPermission,
    hasMultiPermission,
    logOut,
    setAsCompany,
    setToken,
    setTokenExpiry,
    hasMultiAccess,
    setUser,
    setRedirect,
    isValidToken,
    enabledIntegrationChange,
    triggerNeedsPhoneModal,
    changedPhone,
    companyFields,
    isLoadingUserStateApis,
    isLoadingFlags,
    flags,
    needsMFA,
    setNeedsMFA,
    companyFieldsChange,
    isUserEnrolledInLaunchDarklyBeta,
    actionItems,
    refreshActionItems,
    initFullStoryWithUserData,
    userRole,
    isCatalystUser,
    userLocations,
    releaseNotes,
    refreshReleaseNotes
  }}>
      {children}
      <ChangedWorkingAsModal newCompany={newCompany} oldCompany={oldCompany} onAccept={comp => {
      const [resolve, _] = checkPromise;
      setAsCompany(comp);
      resolve();
    }} onBack={() => {
      const [_, reject] = checkPromise;
      reject('User chose not to switch companies');
      window.history.go(-1);
    }} onDone={() => {
      setNewCompany(null);
      setOldCompany(null);
    }} />
      <NeedsPhoneModal open={!!user?.requires_phone || openPhoneModal} me={user} setUser={setUser} logOut={logOut} optionalOptIn={openPhoneModal} onClose={e => {
      setChangedPhone(e);
    }} />
      <NeedsPasswordResetModal open={!!user?.requires_password_reset} me={user} setUser={setUser} logOut={logOut} />
      <Confirm open={confirmModal} title="SMS Is Disabled" message={<>
            <Box sx={{
        marginBottom: '1rem'
      }}>Text messaging has been disabled for {asCompany?.name}. This could be caused by:</Box>

            <ul>
              <li>Failure to register your text messaging traffic</li>
              <li>An above average failure rate on your text messages</li>
            </ul>

            <Box sx={{
        margin: '1rem 0'
      }}>
              If you have questions or concerns, please contact <a href="mailto:support@cinch.io">support@cinch.io</a>
            </Box>

            <Box sx={{
        marginTop: '1rem'
      }}>If you'd like to re-enable text messaging, you can go to the company settings page.</Box>
          </>} submitText="Go to Company Settings" onSubmit={() => {
      history.push(`/companies/${asCompany.id}`);
      setConfirmModal(false);
    }} onClose={() => setConfirmModal(false)} />

      <Confirm open={domainVerifiedModal} title="Domain is not verified" message={<>
            <Box sx={{
        marginBottom: '1rem'
      }}>
              The domain for {asCompany?.name} is not verified. This may impact your ability to use certain features that rely on a verified
              domain.
            </Box>

            <Box sx={{
        marginBottom: '1rem'
      }}>
              To avoid disruptions to your communication, please re-verify your domain. For more information on how to verify your domain,
              <a href="https://support.cinch.io/knowledge/why-you-should-verify-your-domain" target="_blank">
                visit our knowledge base
              </a>
            </Box>

            <Box sx={{
        margin: '1rem 0'
      }}>
              If you assistance, please contact <a href="mailto:support@cinch.io">support@cinch.io</a>
            </Box>
          </>} submitText="Go to Domains" onSubmit={() => {
      history.push('/domains');
      setDomainVerifiedModal(false);
    }} onClose={() => setDomainVerifiedModal(false)} />
    </UserStateContext.Provider>;
};