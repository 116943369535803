import React, { useEffect, useState, useContext, useRef } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Calendar30DaysIcon, CloseIcon } from 'components/mui';
import { Dialog, DialogContent, DialogTitle, Stack, Tooltip, Box, Typography, IconButton, DialogActions, Divider, Button, Drawer, Stepper, Step, StepLabel } from '@mui/material';
import { RecommendationsCheck } from './recommendations-check.component';
import { allNodes } from '../node/node-list';
import { getColor, getImg } from '../node/node-utils';
import { DateTime } from 'luxon';
import { getJourneyNodes, getNodeTemplates, getSegmentNodes } from 'shared/common.api';
import { RepublishCheck } from './republish-check.component';
import { ReleaseNotesCheck } from './release-notes-check.component';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
export const AutomatedJourneyChecksDrawer = props => {
  const {
    asCompany,
    flags
  } = useContext(UserStateContext);
  const drawerRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [modules, setModules] = useState([]);
  const [currentRecommendations, setCurrentRecommendations] = useState([]);
  const [dataToSubmit, setDataToSubmit] = useState({});
  const generateEndJourneyNodes = nodesResponse => {
    return nodesResponse.filter(node => node.from_links.length === 0 && node.name != 'action_eject');
  };
  const generateTimeTriggerNodes = nodesResponse => {
    return nodesResponse.filter(node => {
      if (node.name != 'trigger_time') {
        return false;
      }
      const {
        datetime
      } = node.parameters;
      if (DateTime.fromISO(datetime).toUTC().toISO() < DateTime.local().toUTC().toISO()) {
        return true;
      }
      return false;
    });
  };
  const generateDuplicateSegmentNodes = async nodesResponse => {
    const segmentNodes = nodesResponse.filter(node => {
      if (node.node_type === 'trigger' && node.parameters?.segment_id) {
        return true;
      }
      return false;
    });
    if (segmentNodes.length > 0) {
      return getSegmentNodes({
        company: asCompany.id,
        segments: segmentNodes.map(n => n.parameters?.segment_id)
      }).then(res => {
        if (res.data.results.length > 0) {
          // only show the user the unique duplicate Journeys
          const uniqueJourneys = [];
          res.data.results.forEach(segment => {
            if (uniqueJourneys.findIndex(s => s.journey_id === segment.journey_id) === -1) {
              uniqueJourneys.push(segment);
            }
          });
          return segmentNodes.map(node => {
            const journeys = res.data.results.filter(s => s.segment === node.parameters.segment_id);
            return {
              ...node,
              journeys
            };
          }).filter(node => node.journeys.length > 0);
        }
      });
    }
  };
  const generateRecommenations = async nodesResponse => {
    const endJourneyNodes = generateEndJourneyNodes(nodesResponse);
    const timeTriggerNodes = generateTimeTriggerNodes(nodesResponse);
    const duplicateSegmentNodes = await generateDuplicateSegmentNodes(nodesResponse);
    if (endJourneyNodes?.length > 0 || timeTriggerNodes?.length > 0 || duplicateSegmentNodes?.length > 0) {
      const rec = [];
      endJourneyNodes?.map(node => {
        rec.push({
          id: node.id,
          type: 'endJourneyNodes',
          name: node?.allNodesMeta?.name,
          message: 'This tile has no end status'
        });
      });
      timeTriggerNodes?.map(node => {
        rec.push({
          id: node.id,
          type: 'timeTriggerNodes',
          name: node?.allNodesMeta?.name,
          message: 'This tile has a time trigger that has already passed'
        });
      });
      duplicateSegmentNodes?.map(node => {
        rec.push({
          id: node.id,
          type: 'duplicateSegmentNodes',
          name: node?.allNodesMeta?.name,
          message: 'This tile uses a segment that is in use in another active journey'
        });
      });
      return {
        endJourneyNodes,
        timeTriggerNodes,
        duplicateSegmentNodes,
        rec
      };
    }
    return null;
  };
  useEffect(() => {
    //determine which modules to show
    const primaryPromises = [];
    primaryPromises.push(getJourneyNodes(props?.revision.id));
    primaryPromises.push(getNodeTemplates({
      company: asCompany.id,
      limit: 1000
    }));
    Promise.all(primaryPromises).then(([journeyNodes, nodeTemplates]) => {
      const secondaryPromises = [];
      const mods = [];
      const responseWithMetaData = journeyNodes.data.nodes.map(node => {
        const allNodesMeta = allNodes.find(allNode => allNode.subType == node.name);
        if (!allNodesMeta) {
          const templateNode = nodeTemplates.data.results.find(templateNode => templateNode.sub_type == node.name);
          if (templateNode) {
            return {
              ...node,
              showRecommendation: true,
              allNodesMeta: {
                name: templateNode.name,
                color: getColor(templateNode.type),
                img: getImg(templateNode.icon)
              }
            };
          } else {
            return {
              ...node,
              allNodesMeta: {
                name: node.name,
                color: '3EB87B'
              },
              showRecommendation: true
            };
          }
        }
        return {
          ...node,
          allNodesMeta,
          showRecommendation: true
        };
      });
      secondaryPromises.push(generateRecommenations(responseWithMetaData));
      Promise.all(secondaryPromises).then(([recommendations]) => {
        const recs = [];
        if (recommendations) {
          mods.push({
            key: 'recommendations',
            title: 'Recommendations',
            data: recommendations
          });
          recs.push(...recommendations.rec);
        }

        //determine if need to show republish module
        const publishedRevisions = props.allRevisions.filter(revision => {
          return revision.published_when && !revision.closed_when && !revision.deleted_when;
        });
        if (publishedRevisions.length > 0) {
          mods.push({
            key: 'republish',
            title: 'Republish Check',
            data: publishedRevisions
          });
        }
        if (flags?.journey_release_notes) {
          mods.push({
            key: 'release_notes',
            title: 'Release Notes'
          });
        }
        if (mods.length == 0) {
          props?.handleClose(true);
        }
        setCurrentRecommendations(recs);
        props.markNodesWithSuggestions(recs);
        setModules(mods);
      });
    });
  }, []);
  const ignoreRecommendation = (nodeId, type, parent) => {
    if (nodeId === 'all') {
      setModules(modules.map(module => {
        if (module.key == parent) {
          return {
            ...module,
            data: {
              ...module.data,
              [type]: module.data[type].map(node => {
                return {
                  ...node,
                  showRecommendation: false
                };
              })
            }
          };
        }
        return module;
      }));
      const newrecs = currentRecommendations.filter(rec => rec.type !== type);
      //snackbar if recommendation is still shown because of another category
      if (newrecs.some(rec => currentRecommendations.filter(r => r.type == type).map(r => r.id).includes(rec.id))) {
        snackbarService.popup({
          type: 'warning',
          message: 'The recommendation from this category is hidden, but there are additional recommendations for this tile from another category'
        });
      }
      setCurrentRecommendations(newrecs);
      props.markNodesWithSuggestions(newrecs);
    } else {
      //hide recommendation in modules

      setModules(modules.map(module => {
        if (module.key == parent) {
          return {
            ...module,
            data: {
              ...module.data,
              [type]: module.data[type].map(node => {
                if (node.id === nodeId) {
                  return {
                    ...node,
                    showRecommendation: false
                  };
                }
                return node;
              })
            }
          };
        }
        return module;
      }));
      const newrecs = currentRecommendations.filter(rec => rec.id !== nodeId || rec.type !== type);
      //snackbar if recommendation is still shown because of another category
      if (newrecs.some(rec => rec.id === nodeId)) {
        snackbarService.popup({
          type: 'warning',
          message: 'The recommendation from this category is hidden, but there are additional recommendations for this tile from another category'
        });
      }
      setCurrentRecommendations(newrecs);
      props.markNodesWithSuggestions(newrecs);
    }
  };
  const restoreIgnoredSuggestions = () => {
    const rec = [];
    const recommendationModule = modules.find(m => m.key == 'recommendations');
    if (recommendationModule) {
      setModules(modules.map(module => {
        if (module.key == 'recommendations') {
          return {
            ...module,
            data: {
              endJourneyNodes: module.data.endJourneyNodes?.map(node => {
                return {
                  ...node,
                  showRecommendation: true
                };
              }),
              timeTriggerNodes: module.data.timeTriggerNodes?.map(node => {
                return {
                  ...node,
                  showRecommendation: true
                };
              }),
              duplicateSegmentNodes: module.data.duplicateSegmentNodes?.map(node => {
                return {
                  ...node,
                  showRecommendation: true
                };
              })
            }
          };
        }
        return module;
      }));
      recommendationModule.data.endJourneyNodes?.map(node => {
        rec.push({
          id: node.id,
          type: 'endJourneyNodes',
          name: node.allNodesMeta.name,
          message: 'This tile has no end status'
        });
      });
      recommendationModule.data.timeTriggerNodes?.map(node => {
        rec.push({
          id: node.id,
          type: 'timeTriggerNodes',
          name: node.allNodesMeta.name,
          message: 'This tile has a time trigger that has already passed'
        });
      });
      recommendationModule.data.duplicateSegmentNodes?.map(node => {
        rec.push({
          id: node.id,
          type: 'duplicateSegmentNodes',
          name: node.allNodesMeta.name,
          message: 'This tile uses a segment that is in use in another active journey'
        });
      });
    }
    setCurrentRecommendations(rec);
    props.markNodesWithSuggestions(rec);
  };
  return <>
      <Drawer BackdropProps={{
      invisible: true
    }} open={modules.length > 0} anchor="right" onClose={(e, r) => {
      if (r !== 'backdropClick') {
        props?.handleClose(false);
      }
    }} PaperProps={{
      ref: drawerRef,
      sx: {
        borderRadius: '28px',
        height: 'calc(100% - 64px)',
        top: 64
      }
    }}>
        <Box sx={{
        width: 520
      }} role="presentation">
          <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
            <Stack sx={{
            display: 'flex',
            position: 'sticky',
            top: 0,
            py: '10px',
            background: '#fff',
            zIndex: 100
          }}>
              <Box sx={{
              px: '15px',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontSize: '22px'
                }}> Publish Journey Revision </Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => props?.handleClose(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{
              pt: 1
            }}>
                {modules.length > 1 && <Stepper sx={{}} activeStep={currentStep} alternativeLabel>
                    {modules.map((label, index) => <Step key={index}>
                        <StepLabel sx={{
                    '& .MuiStepIcon-root': {
                      width: 24,
                      height: 24
                    }
                  }}>
                          {label.title}
                        </StepLabel>
                      </Step>)}
                  </Stepper>}
              </Box>
            </Stack>

            {modules[currentStep] && modules[currentStep].key == 'recommendations' && <Box sx={{
            minHeight: props?.canvasHeight + 27 + 'px'
          }}>
                <RecommendationsCheck endJourneyNodes={modules[currentStep].data.endJourneyNodes} timeTriggerNodes={modules[currentStep].data.timeTriggerNodes} duplicateSegmentNodes={modules[currentStep].data.duplicateSegmentNodes} scrollToNode={props.scrollToNode} ignoreRecommendation={ignoreRecommendation} restoreIgnoredSuggestions={restoreIgnoredSuggestions} />
              </Box>}

            {modules[currentStep] && modules[currentStep].key == 'republish' && <Box sx={{
            minHeight: props?.canvasHeight + 27 + 'px'
          }}>
                <RepublishCheck publishedRevisions={modules[currentStep].data} dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} />
              </Box>}

            {modules[currentStep] && modules[currentStep].key == 'release_notes' && <Box sx={{
            minHeight: props?.canvasHeight + 27 + 'px'
          }}>
                <ReleaseNotesCheck revision={props.revision} dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} />
              </Box>}

            <Box sx={{
            position: 'sticky',
            bottom: 0,
            py: '20px',
            px: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            background: '#fff'
          }}>
              <Stack direction="row" spacing={2}>
                <Button variant="contained" onClick={() => props?.handleClose(false)} sx={{
                height: '40px',
                backgroundColor: '#F3F3F4',
                '&:hover': {
                  backgroundColor: '#F3F3F4'
                }
              }}>
                  <Typography sx={{
                  color: '#3898D9'
                }}>Continue editing </Typography>
                </Button>
                {currentStep != 0 && <Button onClick={() => {
                drawerRef.current.scrollTo(0, 0);
                setCurrentStep(currentStep - 1);
              }} variant="contained" sx={{
                height: '40px',
                backgroundColor: '#3898D9',
                '&:hover': {
                  backgroundColor: '#3898D9'
                }
              }}>
                    <Typography sx={{
                  color: '#fff'
                }}> Back</Typography>
                  </Button>}
              </Stack>

              <Button disabled={modules[currentStep] && modules[currentStep].key == 'republish' && !dataToSubmit.republish} onClick={() => {
              if (currentStep < modules.length - 1) {
                drawerRef.current.scrollTo(0, 0);
                setCurrentStep(currentStep + 1);
              } else {
                props?.handleClose(true, dataToSubmit);
              }
            }} variant="contained" sx={{
              height: '40px',
              backgroundColor: '#3898D9',
              '&:hover': {
                backgroundColor: '#3898D9'
              }
            }}>
                <Typography sx={{
                color: '#fff'
              }}> {currentStep == modules.length - 1 ? 'Publish' : 'Continue'}</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>;
};