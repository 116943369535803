import React, { useContext, useState, useEffect } from 'react';
import { UserStateContext } from 'context/user-state-context';
import { Icon, Modal, PageHeaderMui } from 'components';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { history } from '../../../root.component';
import moment from 'moment';
import { Box, Button, IconButton, Paper, Stack, TextField, Tooltip as MuiTooltip, Typography, Divider, Skeleton, Dialog, DialogActions, DialogContent, DialogTitle, Popper, ClickAwayListener, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { AddIcon, CancelIcon, CheckCircleIcon, CloseIcon, DefaultIntegrationIcon, HandymanIcon, LandscapeDisabledIcon, MoreVertIcon, ShieldPersonIcon, SmileDissatisfiedIcon, SmileIcon, SmileNeutralIcon, WarningIcon, WarningRedIcon } from 'components/mui';
import { getReviewStatsWidget } from 'shared/common.api';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement);
export const ReviewStatsChart = props => {
  const {
    user,
    asCompany,
    hasPermission,
    hasMultiAccess
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [reviewsData, setReviewsData] = useState({});
  useEffect(() => {
    setLoading(true);
    getReviewStatsWidget(asCompany.id).then(response => {
      console.log(response.data, 'getReviewStatsWidget');
      setReviewsData(response.data);
      setLoading(false);
    });
  }, []);
  return <>
      {loading ? <>
          <Stack spacing="36px">
            <Stack direction="row" spacing={'16px'}>
              <Box>
                <DefaultIntegrationIcon />
              </Box>
              <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
                <Skeleton variant="rounded" width={210} height={20} />
              </Box>
            </Stack>
            <Box sx={{
          display: 'flex',
          alignItems: 'center',
          pb: '12px'
        }}>
              <Skeleton variant="rounded" width={270} height={20} />
            </Box>
          </Stack>
        </> : <Box sx={{
      width: '100%',
      display: 'flex'
    }}>
          <Stack spacing="32px" sx={{
        width: '33%'
      }}>
            {/*    <Typography
              sx={{
                fontWeight: '700',
                lineHeight: '20px',
                fontSize: '16px',
              }}
             >
              Status Counts
             </Typography> */}

            <Grid justifyContent="space-around" sx={{
          px: '8px'
        }} container spacing={{
          xs: 2,
          sm: 2,
          md: 3,
          lg: 3
        }} columns={{
          xs: 4,
          sm: 12,
          md: 12,
          lg: 12
        }}>
              <Grid xs="auto" sm="auto" md="auto" lg="auto">
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              border: '1px solid #E2E2E2',
              cursor: 'pointer',
              borderRadius: '8px',
              '&:hover': {
                boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
              }
            }} onClick={e => {
              history.push(`/reviews?date_after=${moment().subtract(7, 'days').startOf('day').toISOString()}&score%5B0%5D=1&score%5B1%5D=0.75&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
            }}>
                  <Stack sx={{
                width: '100%',
                py: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }} direction={'row'} spacing="14px">
                    <Box sx={{
                  p: '7px',
                  backgroundColor: '#C5EAD7',
                  borderRadius: '8px'
                }}>
                      <SmileIcon fill="#3EB87B" />
                    </Box>

                    <Stack spacing="4px">
                      <Stack spacing="8px" direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '800',
                      lineHeight: '20px',
                      fontSize: '20px',
                      color: '#3EB87B'
                    }}>
                          {reviewsData?.positive_reviews}
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{
                      borderRightWidth: 2
                    }} />
                        <Typography sx={{
                      fontWeight: '800',
                      lineHeight: '20px',
                      fontSize: '20px'
                    }}>
                          {reviewsData?.total_reviews != 0 ? (reviewsData?.positive_reviews / reviewsData?.total_reviews * 100).toFixed(0) + '%' : ''}
                        </Typography>
                      </Stack>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '300',
                      lineHeight: '20px',
                      fontSize: '14px'
                    }}>
                          Positive Reviews
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>

              <Grid xs="auto" sm="auto" md="auto" lg="auto">
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              border: '1px solid #E2E2E2',
              cursor: 'pointer',
              borderRadius: '8px',
              '&:hover': {
                boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
              }
            }} onClick={e => {
              history.push(`/reviews?date_after=${moment().subtract(7, 'days').startOf('day').toISOString()}&score%5B0%5D=1&score%5B1%5D=0.75&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
            }}>
                  <Stack sx={{
                width: '100%',
                py: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }} direction={'row'} spacing="14px">
                    <Box sx={{
                  p: '7px',
                  backgroundColor: '#FFEABF',
                  borderRadius: '8px'
                }}>
                      <SmileNeutralIcon fill="#FFB82B" />
                    </Box>

                    <Stack spacing="4px">
                      <Stack spacing="8px" direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '800',
                      lineHeight: '20px',
                      fontSize: '20px',
                      color: '#FFB82B'
                    }}>
                          {reviewsData?.neutral_reviews}
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{
                      borderRightWidth: 2
                    }} />
                        <Typography sx={{
                      fontWeight: '800',
                      lineHeight: '20px',
                      fontSize: '20px'
                    }}>
                          {reviewsData?.total_reviews != 0 ? (reviewsData?.neutral_reviews / reviewsData?.total_reviews * 100).toFixed(0) + '%' : ''}
                        </Typography>
                      </Stack>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '300',
                      lineHeight: '20px',
                      fontSize: '14px'
                    }}>
                          Neutral Reviews
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>

              <Grid xs="auto" sm="auto" md="auto" lg="auto">
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              border: '1px solid #E2E2E2',
              cursor: 'pointer',
              borderRadius: '8px',
              '&:hover': {
                boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
              }
            }} onClick={e => {
              history.push(`/reviews?date_after=${moment().subtract(7, 'days').startOf('day').toISOString()}&score%5B0%5D=0&score%5B1%5D=0.25&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
            }}>
                  <Stack sx={{
                width: '100%',
                py: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }} direction={'row'} spacing="14px">
                    <Box sx={{
                  p: '7px',
                  backgroundColor: '#F3C2C2',
                  borderRadius: '8px'
                }}>
                      <SmileDissatisfiedIcon fill="#EF3C34" />
                    </Box>

                    <Stack spacing="4px">
                      <Stack spacing="8px" direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '800',
                      lineHeight: '20px',
                      fontSize: '20px',
                      color: '#EF3C34'
                    }}>
                          {reviewsData?.negative_reviews}
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{
                      borderRightWidth: 2
                    }} />
                        <Typography sx={{
                      fontWeight: '800',
                      lineHeight: '20px',
                      fontSize: '20px'
                    }}>
                          {reviewsData?.total_reviews != 0 ? (reviewsData?.negative_reviews / reviewsData?.total_reviews * 100).toFixed(0) + '%' : ''}
                        </Typography>
                      </Stack>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '300',
                      lineHeight: '20px',
                      fontSize: '14px'
                    }}>
                          Negative Reviews
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>

              <Grid xs="auto" sm="auto" md="auto" lg="auto">
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              width: '200px',
              border: '1px solid #E2E2E2',
              cursor: 'pointer',
              borderRadius: '8px',
              '&:hover': {
                boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
              }
            }} onClick={e => {
              history.push(`/reviews?date_after=${moment().subtract(7, 'days').startOf('day').toISOString()}&score%5B0%5D=0&score%5B1%5D=0.25&has_reply=false&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
            }}>
                  <Stack sx={{
                width: '100%',
                py: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }} direction={'row'} spacing="14px">
                    <Box sx={{
                  p: '7px',
                  backgroundColor: '#EF3C34',
                  borderRadius: '8px'
                }}>
                      <WarningRedIcon fill="#FFFFFF" />
                    </Box>

                    <Stack spacing="4px">
                      <Stack spacing="8px" direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '800',
                      lineHeight: '20px',
                      fontSize: '20px',
                      color: '#EF3C34'
                    }}>
                          {reviewsData?.unanswered_negative}
                        </Typography>
                      </Stack>
                      <Box sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Typography sx={{
                      fontWeight: '300',
                      lineHeight: '20px',
                      fontSize: '14px'
                    }}>
                          Negative - No Reply
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Stack>
          <Stack spacing="16px" sx={{
        width: '66%',
        cursor: 'pointer',
        pt: '12px'
      }}>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                   <Typography
                sx={{
                  fontWeight: '700',
                  lineHeight: '20px',
                  fontSize: '16px',
                }}
              >
                Daily Volume
              </Typography>
             </Box>*/}
            <Box sx={{
          border: '1px solid #E2E2E2',
          borderRadius: '8px'
        }}>
              <Box sx={{
            p: 1,
            height: '35vh'
          }}>
                <Bar plugins={[{
              afterDraw: chart => {
                if (reviewsData?.calendar_has_data) {
                  return;
                }
                let ctx = chart.ctx;
                let width = chart.width;
                let height = chart.height;

                //chart.clear();
                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = '16px Nexa';
                ctx.fillText('No responses yet', width / 2, (height - 20) / 2);
                ctx.restore();
              }
            }]} options={{
              responsive: true,
              maintainAspectRatio: false,
              elements: {
                bar: {
                  // borderSkipped: false, // Apply setting to all bar datasets
                }
              },
              scales: {
                y: {
                  ticks: {
                    color: '#1D252D',
                    precision: 0,
                    beginAtZero: true,
                    font: {
                      family: 'Nexa',
                      size: 14,
                      weight: '400'
                    }
                  },
                  grid: {
                    display: false
                  }
                },
                x: {
                  grid: {
                    display: false
                  },
                  ticks: {
                    color: '#1D252D',
                    font: {
                      family: 'Nexa',
                      size: 14,
                      weight: '400'
                    },
                    precision: 0,
                    beginAtZero: true
                  }
                }
              },
              plugins: {
                title: {
                  display: false
                },
                legend: {
                  position: 'bottom',
                  events: [],
                  labels: {
                    generateLabels: function (chart) {
                      return [{
                        text: 'Number of Reviews',
                        fillStyle: '#C2E7FF',
                        strokeStyle: '#3898D9',
                        lineWidth: 2,
                        hidden: false,
                        index: 1,
                        borderRadius: 4
                      }];
                    },
                    font: {
                      family: 'Nexa',
                      size: 14,
                      weight: '400'
                    }
                  }
                },
                tooltip: {
                  displayColors: false,
                  //events: [],
                  // intersect: false,
                  callbacks: {
                    label: function (context) {
                      return context?.parsed?.y ? context?.parsed?.y + ' Reviews' : '0 Reviews';
                    },
                    title: function (context) {
                      return '';
                    }
                  }
                }
              },
              onClick: function (e, item) {
                const review = reviewsData?.calendar[item[0]?.index];
                setLoading(true);
                if (review) {
                  history.push(`/reviews?date_after=${moment(review.date).startOf('day').toISOString()}&date_before=${moment(review.date).endOf('day').toISOString()}&breadcrumbOverrideURL=/dashboard&breadcrumbOverrideName=Dashboard`);
                } else {
                  setLoading(false);
                }
              }
            }} data={{
              labels: reviewsData?.calendar?.map(review => {
                return review?.day + ' ' + moment(review?.date).format('M/D');
              }),
              datasets: [{
                data: reviewsData?.calendar?.map(review => {
                  return review?.count ? review.count : 0;
                }),
                label: 'Number of Reviews',
                backgroundColor: ['#C2E7FF'],
                borderColor: ['#3898D9'],
                barThickness: 36,
                borderRadius: 8,
                borderSkipped: 'start',
                borderWidth: 2
              }]
            }} />
              </Box>
            </Box>
          </Stack>
        </Box>}
    </>;
};