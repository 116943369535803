import React, { useContext, useState, useEffect } from 'react';
import { PageHeaderMui } from 'components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getReviews, sendReviewReply, getReviewsExport, getLocations, exportReviewToCSV } from '../../shared/common.api';
import moment from 'moment';
import EnhancedTable from '../../components/mui/enhanced-table/enhanced-table.component';
import Rating from '@mui/material/Rating';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import ReplyDialog from './reply-dialog.component';
import { SingleSelectFilter } from 'components/mui/filter/single-select.component';
import { MultiSelectFilter } from 'components/mui/filter/multi-select.component';
import { DateRangeFilter } from 'components/mui/filter/date-range.component';
import { LocationTooltip } from '../../components/mui/tooltip/location-tooltip.component';
import { CustomWidthTooltip } from '../../components/mui/tooltip/custom-width-tooltip.component';
import { CustomerDrawerContext } from '../../context/customer-drawer-context';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Icon } from '../../components/icon/icon.component';
import { UserStateContext } from 'context/user-state-context';
import { getFiltersFromURL } from 'components/mui/enhanced-table/utils';
import { snackbarService } from 'components/mui/snackbar/snackbar-service';
import { CarfaxIcon, CloseIcon } from 'components/mui/icon';
import { DownloadIcon } from 'components/mui';
import { ToggleFilter } from 'components/mui/filter/toggle.component';
import { ExportToCSV } from 'components/export-csv/export-to-csv-component';
const renderReplied = (review, hover) => {
  if (review.entity_source === 'facebook_reviews') {
    return <></>;
  }
  if (review.is_latest && !review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex',
      height: '24px'
    }}>
        <div style={{
        color: '#53A6D6'
      }}>
          <Icon name="custom-reply" size={20} />
        </div>
        <Typography sx={{
        pl: 1,
        margin: 'auto'
      }} color={'#53A6D6'}>
          Reply
        </Typography>
      </div>;
  }
  if (review.is_latest && !!review.reply_when && review.id == hover) {
    return <div style={{
      width: '40px',
      display: 'flex',
      height: '24px'
    }}>
        <EditOutlinedIcon sx={{
        fontSize: '24px',
        color: '#53A6D6'
      }} />
        <Typography sx={{
        pl: 1,
        margin: 'auto'
      }} color={'#53A6D6'}>
          Edit Reply
        </Typography>
      </div>;
  }
  if (!review.reply_when) {
    return <CancelOutlinedIcon sx={{
      fontSize: '24px',
      color: '#FCCD6F'
    }} />;
  }
  return <CheckCircleOutlineIcon sx={{
    fontSize: '24px',
    color: '#66CC99'
  }} />;
};
export const Reviews = () => {
  const [replyDialog, setReplyDialog] = useState(null);
  const [locations, setLocations] = useState([]);
  const [renderIteration, setRenderIteration] = useState(0);
  const {
    openCustomerDrawer
  } = useContext(CustomerDrawerContext);
  const {
    asCompany,
    hasPermission,
    isCatalystUser,
    userLocations,
    flags
  } = useContext(UserStateContext);
  const [reviewsDownloading, setReviewsDownloading] = useState(false);
  const [reviewsPagination, setReviewsPagination] = useState(0);
  const [largeFileDetected, setLargeFileDetected] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [allFields, setAllFields] = useState([{
    id: 'customer',
    label: 'Customer'
  }, {
    id: 'score',
    label: 'Score'
  }, {
    id: 'review_when',
    label: 'Date'
  }, {
    id: 'location_format',
    label: 'Location'
  }, {
    id: 'comment',
    label: 'Review Comment'
  }, {
    id: 'reply_when',
    label: 'Replied'
  }]);
  const handleCloseExportToCSV = exportSelectedFields => {
    if (exportSelectedFields && exportSelectedFields.length > 0) {
      setReviewsDownloading(true);
      exportReviewToCSV({
        selected_fields: exportSelectedFields
      }, {
        company: asCompany.id,
        ...filters
      }).then(response => {
        if (response?.data?.results === 'failed') {
          snackbarService.popup({
            message: 'Error exporting reviews. Please try again.',
            type: 'error',
            action: <React.Fragment>
                <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
          });
          setReviewsDownloading(false);
          return;
        } else if (largeFileDetected) {
          snackbarService.popup({
            message: `Exporting ${asCompany.name + ' ' + 'reviews'} to CSV. ${'We will email you a link to the File Manager page where your CSV file will be when it is ready for download. This may take a few minutes.'}`,
            type: 'info',
            action: <React.Fragment>
                <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
          });
          setReviewsDownloading(false);
          return;
        } else {
          const blob = new Blob([response.data], {
            type: 'text/csv'
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.setAttribute('href', url);
          let fileName = `${asCompany.name + '_' + 'Reviews' + '_' + moment().format('MM-DD-YYYY') + '.csv'}`;
          a.setAttribute('download', fileName);
          a.click();
          setReviewsDownloading(false);
          snackbarService.popup({
            message: `Exported ${asCompany.name + '_' + 'Reviews' + '_' + moment().format('MM-DD-YYYY') + '.csv'} to your browser. ${'Please navigate to your browser downloads folder to see your file.'}`,
            type: 'info',
            action: <React.Fragment>
                <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
          });
        }
      });
    }
    setShowExportModal(false);
  };
  useEffect(() => {
    getLocations({
      company: asCompany.id,
      limit: 1000,
      user_locations: isCatalystUser
    }).then(({
      data
    }) => {
      if (data) {
        setLocations(data.results);
      }
    });
  }, [asCompany, isCatalystUser]);
  useEffect(() => {
    if (!isCatalystUser) {
      return;
    }
    setRenderIteration(r => r + 1);
  }, [userLocations, isCatalystUser]);
  const filterOptions = [{
    component: SingleSelectFilter,
    label: 'Replied',
    key: 'has_reply',
    options: [{
      label: 'Show all',
      value: 'all',
      isDefault: true
    }, {
      label: 'Yes',
      value: 'true'
    }, {
      label: 'No',
      value: 'false'
    }]
  }, {
    component: MultiSelectFilter,
    label: 'Review Source',
    key: 'entity_source',
    options: [{
      label: 'Google',
      value: 'google_business',
      icon: <GoogleIcon />
    }, {
      label: 'Facebook',
      value: 'facebook_reviews',
      icon: <FacebookIcon viewBox="0 0 22 22" />
    }, {
      label: 'Carfax',
      value: 'carfax_reviews',
      icon: <CarfaxIcon />
    }]
  }, {
    component: MultiSelectFilter,
    label: 'Score',
    key: 'score',
    options: [{
      label: <Rating name="read-only" value={5} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={5} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '1'
    }, {
      label: <Rating name="read-only" value={4} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={4} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0.75'
    }, {
      label: <Rating name="read-only" value={3} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={3} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0.5'
    }, {
      label: <Rating name="read-only" value={2} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={2} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0.25'
    }, {
      label: <Rating name="read-only" value={1} readOnly />,
      chipLabel: <Rating sx={{
        textAlign: 'center',
        margin: 'auto',
        top: '2px',
        color: '#53A6D6'
      }} name="read-only" value={1} readOnly emptyIcon={<StarOutlinedIcon sx={{
        color: 'rgba(83, 166, 214, 0.3)'
      }} />} />,
      value: '0'
    }]
  }, {
    component: DateRangeFilter,
    label: 'Replied Date',
    key: 'replied'
  }, {
    component: MultiSelectFilter,
    label: 'Location',
    key: 'location',
    options: locations.map(location => ({
      label: location.friendly_name || 'Unnamed Location',
      value: location.id
    }))
  }, {
    key: 'replied_after',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }, {
    key: 'replied_before',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }, {
    key: 'date_after',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }, {
    key: 'date_before',
    hidden: {
      hideInFilterList: true,
      hideChip: true
    }
  }, {
    component: ToggleFilter,
    label: 'Filter by Id',
    key: 'id',
    defaultVal: 'off',
    hidden: {
      hideFilterButton: true,
      hideChip: false,
      hideInFilterList: true
    }
  }];
  const [filters, setFilters] = React.useState(() => {
    const fils = getFiltersFromURL(filterOptions);
    if (!fils['date_after']) {
      fils['date_after'] = moment().subtract(7, 'days').startOf('day').toISOString();
    }
    return fils;
  });
  const convertNormalizedScore = normalizedScore => {
    if (normalizedScore == 0) return 1;
    if (normalizedScore > 0 && normalizedScore <= 0.25) return 2;
    if (normalizedScore > 0.25 && normalizedScore <= 0.5) return 3;
    if (normalizedScore > 0.5 && normalizedScore <= 0.75) return 4;
    if (normalizedScore > 0.75) return 5;
  };
  const headCells = [{
    key: 'customer',
    align: 'left',
    disablePadding: true,
    label: 'Customer',
    component: 'th',
    scope: 'row',
    padding: 'none',
    lableId: `enhanced-table-checkbox-0`,
    displayComponent: ({
      data
    }) => data.customer_first_name || data.customer_last_name ? `${data.customer_first_name || ''} ${data.customer_last_name || ''}` : data.customer_phone || '',
    props: {}
  }, {
    key: 'score',
    align: 'left',
    disablePadding: true,
    label: 'Score',
    displayComponent: ({
      data
    }) => <Stack direction="row" justifyContent="flex-start" alignItems="center">
          {data.entity_source === 'facebook_reviews' && <FacebookIcon viewBox="0 0 22 22" sx={{
        fontSize: 16
      }} />}
          {data.entity_source === 'google_business' && <GoogleIcon sx={{
        fontSize: 16
      }} />}
          {data.entity_source === 'carfax_reviews' && <CarfaxIcon sx={{
        fontSize: 16
      }} />}
          <Rating name="read-only" value={convertNormalizedScore(data.normalized_score)} readOnly />
        </Stack>
  }, {
    key: 'review_when',
    align: 'left',
    disablePadding: true,
    label: 'Date',
    displayComponent: ({
      data
    }) => <>{moment(data.review_when).format('MMM D, YYYY')}</>
  }, {
    key: 'location_format',
    align: 'left',
    disablePadding: true,
    label: 'Location',
    displayComponent: ({
      data
    }) => <LocationTooltip location={data.location} />
  }, {
    key: 'comment',
    align: 'left',
    disablePadding: true,
    label: 'Review Comment',
    type: 'string',
    displayComponent: ({
      data
    }) => <CustomWidthTooltip tip={data.comment} />
  }, {
    key: 'reply_when',
    align: 'left',
    disablePadding: true,
    label: 'Replied',
    displayComponent: ({
      data,
      index,
      onHover
    }) => {
      if (data.entity_source === 'facebook_reviews') {
        return <></>;
      }
      return <>{renderReplied(data, onHover.row)}</>;
    }
  }];
  function onDataChange(newData) {
    setReplyDialog(newData);
  }
  function onSubmitData(body) {
    sendReviewReply(replyDialog.id, body).then(({
      result
    }) => {
      setRenderIteration(i => i + 1);
    });
  }
  function onCustomerDrawer(customer) {
    setReplyDialog(null);
    if (hasPermission('customer.view_customer')) openCustomerDrawer(customer.id);
  }
  const downloadCsv = () => {
    const params = {
      ...filters
    };
    params.company = asCompany.id;
    getReviews({
      ...params
    }).then(response => {
      if (response.data?.count >= 1000) {
        setLargeFileDetected(true);
      } else {
        setLargeFileDetected(false);
      }
    });
    setShowExportModal(true);
  };
  const ReviewsApiWrapper = () => {
    return params => getReviews({
      location: !!isCatalystUser ? userLocations : null,
      ...params
    });
  };
  return <>
      <CssBaseline />
      <div className="wrapper">
        <PageHeaderMui type="Reviews" icon={<Icon name="custom-reviews" size={24} />} />
        <div className="mui-wrapper">
          {filters && <EnhancedTable type="Reviews" headCells={headCells} api={ReviewsApiWrapper()} enableAutoRefresh defaultSort={'-review_when'} locations={locations} renderIteration={renderIteration} searchCount={count => setReviewsPagination(count)} actions={<>
                  {reviewsPagination > 0 && reviewsDownloading && <Tooltip title="Downloading, please wait">
                      <IconButton sx={{
              backgroundColor: '#fff',
              color: '#53A6D6',
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }}>
                        <CircularProgress size={20} />
                      </IconButton>
                    </Tooltip>}

                  {reviewsPagination > 0 && !reviewsDownloading && flags['review-export-to-csv'] && <Tooltip title="Export as CSV">
                      <IconButton sx={{
              backgroundColor: '#fff',
              color: '#53A6D6',
              ':hover': {
                backgroundColor: '#DAEFFF'
              }
            }} onClick={downloadCsv}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>}
                </>} handleRowClick={row => {
          if (row.entity_source === 'facebook_reviews') {
            return;
          }
          if (!row.is_latest) {
            snackbarService.popup({
              type: 'error',
              message: "Can't reply to this review as it's not the latest."
            });
          } else {
            setReplyDialog(row);
          }
        }} filterOptions={filterOptions} filtersState={[filters, setFilters]} datePicker />}
        </div>
      </div>

      {replyDialog && <ReplyDialog data={replyDialog} onDataChange={onDataChange} onSubmit={onSubmitData} onCustomerDrawer={onCustomerDrawer} />}
      {showExportModal && <ExportToCSV handleClose={handleCloseExportToCSV} showExportModal={showExportModal} model="review" company={asCompany.id} selectedFields={headCells} availableFields={allFields} />}
    </>;
};