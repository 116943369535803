import { Container, Graphics, Sprite, TextStyle, Text } from 'pixi.js';
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { cloneDeep, flattenDeep, isEmpty, isArray, isObject } from 'lodash';
import { isValid, checkBetterFalsy } from './node-modal/utils';

/**
 * Build Square
 * @param color
 * @param size
 * @return {Container}
 */
export function buildSquare(color, size) {
  const radius = 6;
  const shapeContainer = new Container();
  const shape = new Graphics();
  shape.beginFill(color);
  shape.moveTo(0, radius);
  shape.lineTo(0, size - radius);
  shape.arcTo(0, size, radius, size, radius);
  shape.lineTo(size - radius, size);
  shape.arcTo(size, size, size, size - radius, radius);
  shape.lineTo(size, radius);
  shape.arcTo(size, 0, size - radius, 0, radius);
  shape.lineTo(radius, 0);
  shape.arcTo(0, 0, 0, radius, radius);
  shape.alpha = 0.3;
  shape.endFill();
  const dropshadow = new DropShadowFilter({
    distance: 0,
    rotation: 0,
    alpha: 0.3,
    pixelSize: 0.5,
    blur: 5
  });
  shape.filters = [dropshadow];
  shapeContainer.addChild(shape);
  return shapeContainer;
}

/**
 * Build Border
 * A rounded rect with a stroke and without a fill
 * @param size {number}
 * @return {Graphics}
 */
export function buildBorder(size) {
  const radius = 6;
  const shape = new Graphics();
  shape.lineStyle(3, 0x000000, 0.15, 1);
  shape.moveTo(0, radius);
  shape.lineTo(0, size - radius);
  shape.arcTo(0, size, radius, size, radius);
  shape.lineTo(size - radius, size);
  shape.arcTo(size, size, size, size - radius, radius);
  shape.lineTo(size, radius);
  shape.arcTo(size, 0, size - radius, 0, radius);
  shape.lineTo(radius, 0);
  shape.arcTo(0, 0, 0, radius, radius);
  shape.endFill();
  return shape;
}

/**
 * Build Circle
 * Not currently using - but saving
 * @param color
 * @param size
 * @return {Graphics}
 */
export function buildCircle(color, size) {
  const radius = size / 2;
  const shape = new Graphics();
  shape.beginFill(color);
  shape.drawCircle(radius, radius, radius);
  shape.endFill();
  return shape;
}

/**
 * Build Octogon
 * Not currently using - but saving
 * @param color
 * @return {Graphics}
 */
export function buildOctogon(color) {
  const side = 58;
  const ratio = 1.41421356237;
  const point = side / ratio;
  const shape = new Graphics();
  shape.beginFill(color);
  shape.drawPolygon([0, point, point, 0, point + side, 0, point + side + point, point, point + side + point, point + side, point + side, point + side + point, point, point + side + point, 0, point + side]);
  shape.endFill();
  return shape;
}
export function buildBackdrop(width, height) {
  const backdrop = new Graphics();
  backdrop.interactive = true;
  backdrop.beginFill(0x000000);
  backdrop.alpha = 0.5;
  backdrop.drawRect(0, 0, width, height);
  backdrop.endFill();
  return backdrop;
}

/**
 * Nodes Can Connect
 * Each node comes with connecting rules - make sure nodes are compatible
 * @param nodeFrom {Node}
 * @param nodeTo {Node}
 * @return {boolean}
 */
export function nodesCanConnect(nodeFrom, nodeTo) {
  return nodeTo.connectFrom.includes(nodeFrom.type) && nodeFrom.connectTo.includes(nodeTo.type);
}

/**
 * Link Exists
 * don't allow duplicate or reverse duplicate links
 * @param nodeFrom {Node}
 * @param nodeTo {Node}
 * @param links {array}
 * @return {boolean}
 */
export function linkExists(nodeFrom, nodeTo, links) {
  return links.some(link => {
    return link.nodeFrom === nodeFrom && link.nodeTo === nodeTo || link.nodeFrom === nodeTo && link.nodeTo === nodeFrom;
  });
}
export const MODES = {
  POINT_LINK: 'POINT_LINK',
  EDIT_PARAM: 'EDIT_PARAM',
  CANVAS: 'CANVAS',
  TEXT: 'TEXT',
  PAN: 'PAN',
  NODE_PARAM: 'NODE_PARAM'
};
export const PROCESS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED'
};
export const emptyState = {
  canvasSize: {
    width: 3840,
    height: 2160
  },
  revisionId: null,
  links: [],
  nodes: [],
  texts: [],
  mode: MODES.CANVAS,
  nodeEdit: null,
  linkEdit: false,
  nodeTo: null,
  nodeFrom: null,
  linksContainer: null,
  nodesContainer: null,
  stage: null,
  process: null,
  onParamEdit: 0
};
export function findRules(query) {
  let rules = [];
  const group = query.and || query.or;
  if (group) {
    rules = flattenDeep(group.map(findRules));
  } else {
    rules = query;
  }
  return rules;
}
export function filterNode(node, matchSubType) {
  return IS_PROD ? !node.subType.match(matchSubType) : true;
}
export const validateRules = obj => {
  if ('and' in obj) {
    return obj.and.length > 0 && !obj.and.some(obj => !validateRules(obj));
  } else if ('or' in obj) {
    return obj.or.length > 0 && !obj.or.some(obj => !validateRules(obj));
  } else if (!obj.field || !obj.model || !obj.operator) {
    return false;
  } else if (obj.operator.substring(0, 3) === 'is_') {
    return true;
  } else if (!obj.query_type) {
    return false;
  } else if (obj.query_type === 'value' && !obj.value) {
    return false;
  } else if (obj.query_type === 'related_field' && (!obj.related_model || !obj.related_field)) {
    return false;
  } else if (obj.query_type === 'relative_time' && (!obj.value || !obj.time_unit || !obj.time_type)) {
    return false;
  }
  return true;
};
export const getColor = type => {
  if (type === 'action') return 'E77A16';
  if (type === 'trigger') return '3EB87B';
  if (type === 'condition') return '107EBE';
};

// images
import timeImg from '../assets/time.png';
import transactionImg from '../assets/transaction.png';
import emailImg from '../assets/email.png';
import timeDelayImg from '../assets/time-delay.png';
import mailImg from '../assets/mail.png';
import triggerRecurringImg from '../assets/trigger-recurring.png';
import aBImg from '../assets/a-b.png';
import SmsImg from '../assets/sms.png';
import MmsImg from '../assets/mms.png';
import webhookImg from '../assets/webhook.png';
import addSegmentImg from '../assets/segment-add.png';
import removeSegmentImg from '../assets/segment-remove.png';
import checkeredImg from '../assets/checkered.png';
import segmentImg from '../assets/segment.png';
import customerRepeatImg from '../assets/customer-repeat.png';
import pointOnMapImg from '../assets/point-on-map.png';
import dataFieldsImg from '../assets/data-fields.png';
import facebookImg from '../assets/facebook.png';
import googleAdsImg from '../assets/google-ads.png';
import segmentValueImg from '../assets/segment-value.png';
import webFormImg from '../assets/web-form.png';
import fastSensorImg from '../assets/fast-sensors.png';
import banImg from '../assets/ban.png';
import calendarImg from '../assets/calendar.png';
import offerImg from '../assets/price-tag.png';
import expireImg from '../assets/alarm.png';
import pointsImg from '../assets/gift.png';
import surveyImg from '../assets/clipboard.png';
import aircallImg from '../assets/aircall.png';
import callToolsImg from '../assets/calltools.png';
import cartImg from '../assets/cart.png';
import shopifyImg from '../assets/shopify.png';
import trophyImg from '../assets/trophy.png';
import notificationsImg from '../assets/notifications.png';
import zapierImg from '../assets/zapier.png';
import userPlusImg from '../assets/user-plus.png';
import cinchImg from '../assets/cinch.png';
import audiohookImg from '../assets/audiohook.png';
import dripdropImg from '../assets/dripdrop.png';
import starImg from '../assets/star-sharp-solid.png';
import replyClockSolid from '../assets/reply-clock-solid.png';
import mailbox_powerImg from '../assets/mailbox_power.png';
export const getImg = icon => {
  if (icon === 'zapier') return zapierImg;
  if (icon === 'sms') return SmsImg;
  if (icon === 'user-plus') return userPlusImg;
  if (icon === 'star') return starImg;
  if (icon === 'survey') return surveyImg;
  if (icon === 'cinch') return cinchImg;
  if (icon === 'time') return timeImg;
  if (icon === 'transaction') return transactionImg;
  if (icon === 'email') return emailImg;
  if (icon === 'time-delay') return timeDelayImg;
  if (icon === 'mail') return mailImg;
  if (icon === 'trigger-recurring') return triggerRecurringImg;
  if (icon === 'ab') return aBImg;
  if (icon === 'mms') return MmsImg;
  if (icon === 'webhook') return webhookImg;
  if (icon === 'add-segment') return addSegmentImg;
  if (icon === 'remove-segment') return removeSegmentImg;
  if (icon === 'checkered') return checkeredImg;
  if (icon === 'segment') return segmentImg;
  if (icon === 'repeat-customer') return customerRepeatImg;
  if (icon === 'point-on-map') return pointOnMapImg;
  if (icon === 'data-fields') return dataFieldsImg;
  if (icon === 'facebook') return facebookImg;
  if (icon === 'google-ads') return googleAdsImg;
  if (icon === 'segment-value') return segmentValueImg;
  if (icon === 'web-form') return webFormImg;
  if (icon === 'ban') return banImg;
  if (icon === 'calendar') return calendarImg;
  if (icon === 'offer') return offerImg;
  if (icon === 'expire') return expireImg;
  if (icon === 'points') return pointsImg;
  if (icon === 'aircall') return aircallImg;
  if (icon === 'calltools') return callToolsImg;
  if (icon === 'cart') return cartImg;
  if (icon === 'shopify') return shopifyImg;
  if (icon === 'trophy') return trophyImg;
  if (icon === 'notification') return notificationsImg;
  if (icon === 'audiohook') return audiohookImg;
  if (icon === 'drip-drop') return dripdropImg;
  if (icon === 'reply-clock') return replyClockSolid;
  if (icon === 'mailbox-power') return mailbox_powerImg;
};
import { getSurveys, getLocations, getMarkets, getSmsTemplates } from 'shared/common.api';
const mapParams = params => {
  if (!params) return;
  const newParams = cloneDeep(params);
  newParams.forEach(param => {
    if (param.model) {
      if (param.model === 'survey') param.getItems = getSurveys;
      if (param.model === 'market') param.getItems = getMarkets;
      if (param.model === 'location') param.getItems = getLocations;
      if (param.model === 'smsTemplates') param.getItems = getSmsTemplates;
    }
  });
  return newParams;
};
import Handlebars from 'handlebars';
const configureLabel = 'Click to configure';
export const mapNode = node => {
  let template;
  if (node.label) {
    template = Handlebars.compile(node.label);
  }
  return {
    type: node.type,
    subType: node.sub_type,
    templateId: node.id,
    name: node.name,
    description: node.description,
    order: node.order,
    img: getImg(node.icon),
    connectTo: ['action', 'condition'],
    parameters: cloneDeep(node.defaults),
    componentParams: mapParams(node.params),
    connectFrom: node.type === 'trigger' ? [] : ['trigger', 'action', 'condition'],
    color: node.color || getColor(node.type),
    onEventList: node.events,
    nodeResource: node.help_resource,
    validate: params => {
      if (node.params) {
        try {
          // Loop over each node and check for a validation failure.
          return !node.params.some(param => {
            // Return true if there is a validation error
            return !isValid(param, params);
          });
        } catch (e) {
          console.error(e);
          return false;
        }
      }
      return true;
    },
    buildLabel: params => {
      let response = '';
      if (template) {
        let label;
        try {
          label = template(params);
        } catch (e) {
          console.error(e);
        }
        response = checkBetterFalsy(label) ? configureLabel : label;
      }
      return Promise.resolve(response);
    }
  };
};