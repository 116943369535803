import React, { useState, useEffect, useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { PageHeaderMui } from 'components';
import { RepeatTransactionCalculatorIcon } from 'components/mui';
import { UserStateContext } from 'context/user-state-context';
import { createMotorOemConfig, getMotorOemConfigs } from 'shared/common.api';
import { MotorOemRecommendations } from './recommendations.component';
import { MotorOemRecommendationsConfig } from './config.component';
import { configStatus } from './consts';

/*
 * Contains the entire flow for the recommendation -> configuration cycle
 */
export const MotorOemRecommendationMain = ({
  history,
  location,
  match,
  staticContext
}) => {
  const {
    asCompany
  } = useContext(UserStateContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [configuration, setConfiguration] = useState();

  // TODO on a timer reload the configuration?
  useEffect(() => {
    const initializeConfiguration = async () => {
      const configResponse = await getMotorOemConfigs({
        company: asCompany.id
      });
      let existingConfig = null;
      if (configResponse.data.results.length >= 1) {
        existingConfig = configResponse.data.results[0];
      } else {
        const createConfigResponse = await createMotorOemConfig({
          company: asCompany.id,
          status: 'new'
        });
        existingConfig = createConfigResponse.data;
      }
      if (existingConfig.status === configStatus.NEW) {
        setPage(1);
      } else {
        setPage(2);
      }
      setConfiguration(existingConfig);
    };
    const initialize = async () => {
      await initializeConfiguration();
      setLoading(false);
    };
    initialize();
  }, [asCompany.id]);
  return <>
      <CssBaseline />
      <div className="wrapper">
        {/* TODO what icon do we want to use here? */}
        <PageHeaderMui type="Motor OEM Recommendations" icon={<RepeatTransactionCalculatorIcon size="lg" />} />
        {!loading && <>
            {page === 1 && <MotorOemRecommendations configuration={configuration} setPage={setPage} />}
            {page === 2 && <MotorOemRecommendationsConfig configuration={configuration} setConfiguration={setConfiguration} setPage={setPage} />}
          </>}
      </div>
    </>;
};