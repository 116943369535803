import React, { Component, useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompaniesList } from './pages/companies/companies-list.component';
import { BroadcastEmailList } from './pages/broadcast/email/broadcast-email-list.component';
import { BroadCastEmailManageList } from './pages/broadcast/manage-email-broadcasts/broadcast-email-manage-list.component';
import { BroadcastSmsList } from './pages/broadcast/sms/broadcast-sms-list.component';
import { DataFields } from './pages/data-fields/data-fields.component';
import { NotificationList } from './pages/notification-list/notification-contact-list.component';
import UsersList from './pages/users/users-list.component';
import Users from './pages/users/users.component';
import { CustomerJourneysDetails } from './pages/customer-journeys/customer-journeys-details.component';
import { IntegrationsList } from './pages/integrations/integrations-list.component';
import { CustomerJourneys } from './pages/customer-journeys/customer-journeys.component';
import { CouponsList } from './pages/coupons/coupons-list.component';
import { SegmentsList } from './pages/segments/segments-list.component';
import { EditEmailTemplate } from './pages/templates/edit.email.component';
import { TemplateList } from './pages/templates/template-list.component';
import { TemplateListGallery } from './pages/templates/template-list-gallery.component';
import { MailTemplate } from './pages/templates/options-mail-print/mail-template.component';
import { LocationsList } from './pages/locations/locations-list.component';
import { Customers } from './pages/customers/customers.component';
import { CustomerGroups } from './pages/customer-groups/customer-groups.component';
import { LandingPage } from './pages/assets/landing-pages/landing-page.component';
import { Transactions } from './pages/transactions/transactions.component';
import { TemplateMailPublished } from './pages/templates/template-mail-published.component';
import { WebFormsList } from './pages/web-forms/web-forms-list.component';
import { WebFormEdit } from './pages/web-forms/web-form-edit.component';
import { PhoneNumbers } from './pages/phone-numbers/phone-numbers.component';
import { Domains } from './pages/domains/domains.component';
import { Files } from './pages/files/files.component';
import { Messaging } from './pages/messaging/messaging.component';
import { Imports } from './pages/import/import.component';
import { UploadImport } from './pages/import/upload.component';
import { CsvOptStatus } from './pages/import/csv-opt-status.component';
import { AiUpload } from './pages/import/ai-upload-component.js';
import Page404 from './pages/404';
import Page403 from './pages/403';
import RolesList from './pages/roles/roles-list.component';
import Role from './pages/roles/roles.component';
import { Reviews } from './pages/reviews/reviews.component';
import { EditSmsTemplate } from './pages/templates/edit.sms.component';
import { UserStateContext } from 'context/user-state-context';
import { Segment } from './pages/segments/segment.component';
import { EditSegment } from './pages/segments/edit-segment.component';
import { CustomerJourneysListMUI } from './pages/customer-journeys/customer-journeys-list-mui.component';
import { DevTools } from './pages/dev-tools/tools.component';
import { IconList } from './pages/dev-tools/icon-list.component';
import { IntegrationLogs } from './pages/dev-tools/integration-logs.component';
import { EditNotificationContact } from './pages/notification-list/edit-notification-contact.component';
import { Invoices } from './pages/billing/invoices.component';
import { SurveyResponsesList } from './pages/surveys/survey-responses-list.component';
import { RepeatTransactionCalculatorList } from './pages/transactions/repeat-transaction-calculator-list.component';
import { RTCBuilder } from './pages/transactions/repeat-transaction-calculator-builder.component';
import { RepeatTransactionCalculatorResults } from './pages/transactions/repeat-transaction-calculator-results.component';
import { TestRunComponent } from './pages/customer-journeys/test-run.component';
import { SurveysSummary } from './pages/surveys/surveys-summary.component';
import { SurveysList } from './pages/surveys/surveys-list.component';
import { SurveysEdit } from './pages/surveys/surveys-edit.component';
import { Community } from './pages/community/community.component';
import { ActionItems } from './pages/action-items/action-items.component';
import { CompanyMui } from './pages/companies/company-mui.component';
import * as FullStory from '@fullstory/browser';
import { IntegrationsListMui } from './pages/integrations/mui/integrations-list-mui.component';
import { IntegrationsAddNew } from './pages/integrations/mui/integrations-add-new.component';
import { IntegrationsManage } from './pages/integrations/mui/integrations-manage.component';
import { IntegrationsConfigureCredential } from './pages/integrations/mui/integrations-configure-credential.component';
import { IntegrationsManageFacebook } from './pages/integrations/mui/integrations-manage-facebook.component';
import { IntegrationsConfigureFacebook } from './pages/integrations/mui/integrations-configure-facebook.component';
import { AnalyticsList } from './pages/analytics/analytics-list.component';
import { Analytics } from './pages/analytics/analytics.component';
import { PrebuiltAdminList } from './pages/prebuilt/pbj-admin-list.component';
import { PrebuiltAdminSegmentsUsageStats } from './pages/prebuilt/segments/pbj-admin-segments-usage-stats.component';
import { PrebuiltAdminSegmentsDetails } from './pages/prebuilt/segments/pbj-admin-segments-details.component';
import { PrebuiltAdminSegmentsList } from './pages/prebuilt/segments/pbj-admin-segments-list.component';
import { PrebuiltAdminEmailsUsageStats } from './pages/prebuilt/emails/pbj-admin-emails-usage-stats.component';
import { PrebuiltAdminEmailsDetails } from './pages/prebuilt/emails/pbj-admin-emails-details.component';
import { PrebuiltAdminEmailsList } from './pages/prebuilt/emails/pbj-admin-emails-list.component';
import { WidgetsList } from './pages/widgets/widgets-list.component';
import { WidgetsAddNew } from './pages/widgets/widgets-add-new.component';
import { CustomerJourneysWizardArchitect } from './pages/customer-journeys/customer-journeys-wizard-architect.component';
import { IntegrationsManageFacebookReview } from './pages/integrations/mui/integration-manage-facebookreviews.component';
import { IntegrationsConfigureFacebookReview } from './pages/integrations/mui/integration-configure-facebook-reviews.component';
import { PrebuiltSegmentInstallVersion } from './pages/prebuilt/installs/segments-prebuilt-install-version.component';
import { PrebuiltEmailInstallVersion } from './pages/prebuilt/installs/emails-prebuilt-install-version.component';
import { PrebuiltInstall } from './pages/prebuilt/installs/prebuilt-install-version.component';
import { MarketsList } from './pages/locations/markets-list.component';
import { MotorOemRecommendationMain } from './pages/integrations/motor-oem-recommendations/configure.component';
import { PrebuiltAdminSmsUsageStats } from './pages/prebuilt/sms/pbj-admin-sms-usage-stats.component';
import { PrebuiltAdminSmsDetails } from './pages/prebuilt/sms/pbj-admin-sms-details.component';
import { PrebuiltAdminSmsList } from './pages/prebuilt/sms/pbj-admin-sms-list.component';
import { PrebuiltSmsInstallVersion } from './pages/prebuilt/installs/sms-prebuilt-install-version.component';
import { PrebuiltAdminPrintsUsageStats } from './pages/prebuilt/prints/pbj-admin-prints-usage-stats.component';
import { PrebuiltAdminPrintsDetails } from './pages/prebuilt/prints/pbj-admin-prints-details.component';
import { PrebuiltAdminPrintsList } from './pages/prebuilt/prints/pbj-admin-prints-list.component';
import { PrebuiltPrintInstallVersion } from './pages/prebuilt/installs/prints-prebuilt-install-version.component';
import { PrebuiltAdminSurveysUsageStats } from './pages/prebuilt/surveys/pbj-admin-surveys-usage-stats.component';
import { PrebuiltAdminSurveysDetails } from './pages/prebuilt/surveys/pbj-admin-surveys-details.component';
import { PrebuiltAdminSurveysList } from './pages/prebuilt/surveys/pbj-admin-surveys-list.component';
import { PrebuiltSurveyInstallVersion } from './pages/prebuilt/installs/surveys-prebuilt-install-version.component';
import { PrebuiltAdminJourneysUsageStats } from './pages/prebuilt/journeys/pbj-admin-journeys-usage-stats.component';
import { PrebuiltAdminJourneysDetails } from './pages/prebuilt/journeys/pbj-admin-journeys-details.component';
import { PrebuiltAdminJourneysList } from './pages/prebuilt/journeys/pbj-admin-journeys-list.component';
import { PrebuiltJourneyInstallVersion } from './pages/prebuilt/installs/journeys-prebuilt-install-version.component';
import { BulkEditLocations } from './pages/locations/bulk-edit-locations.component';
import { ImportLocations } from './pages/locations/import-locations.component';
import { IntegrationPestpacUpload } from './pages/integrations/mui/integration-pestpac-csv.component';
import { CustomerJourneysDetailsNew } from './pages/customer-journeys/customer-journeys-details-new.component';
const LOCAL = ['localhost:8080', 'localhost:8088'];
const DEV = 'marketing-dev.cinch.io';
const PROD = 'marketing.cinch.io';

// import { Billing } from './pages/billing/billing.component';
// import { BillingList } from './pages/billing/billing-list.component';

const PermissionRoute = props => {
  const {
    hasPermission,
    flags
  } = useContext(UserStateContext);
  //launch darkly flags enforce permission routing
  if (props.flags) {
    return flags[props?.flags] && hasPermission(...props.perms) ? <Route {...props} /> : <Route {...props} component={Page403} />;
  } else {
    return hasPermission(...props.perms) ? <Route {...props} /> : <Route {...props} component={Page403} />;
  }
};
export const AppRoutes = () => {
  const {
    hasMultiAccess,
    initFullStoryWithUserData,
    flags
  } = useContext(UserStateContext);
  useEffect(() => {
    let _FSOId = 'o-1Q4AMX-na1';
    try {
      if (FullStoryOrgId) {
        _FSOId = FullStoryOrgId;
      }
    } catch (e) {
      // Attempting to fix issue where the FullStoryOrgID is not defined
    }
    FullStory.init({
      orgId: _FSOId,
      devMode: LOCAL.includes(window.location.host) || window.location.host.includes(DEV)
    });
    initFullStoryWithUserData();
  }, []);
  return <Switch>
      <PermissionRoute path="/analytics-list" perms={['analytics.view_view']} exact component={AnalyticsList} />
      <PermissionRoute path="/analytics" perms={['analytics.view_view']} exact component={Analytics} />
      <Route path="/companies" exact component={CompaniesList} />
      <PermissionRoute path="/companies/:id" perms={['organization.view_company']} exact component={CompanyMui} />
      {/*   <PermissionRoute
        path="/companies/:id/email-preference-lists"
        perms={['organization.view_company']}
        exact
        component={EmailPreferenceLists}
       /> */}
      <PermissionRoute path="/email-broadcasts" perms={['email.view_broadcast']} exact component={BroadcastEmailList} />
      <PermissionRoute path="/manage-email-broadcasts" perms={['email.view_broadcast']} exact component={BroadCastEmailManageList} />
      <PermissionRoute path="/sms-broadcasts" perms={['communication.view_smsbroadcast']} exact component={BroadcastSmsList} />
      <PermissionRoute path="/data-fields" perms={['organization.view_company']} exact component={DataFields} />
      <PermissionRoute path="/notification-list" perms={['organization.view_company']} exact component={NotificationList} />
      <PermissionRoute path="/notification-list/create" perms={['notification_list.add_notificationcontact']} exact component={EditNotificationContact} />
      <PermissionRoute path="/notification-list/edit/:notificationContactId" perms={['notification_list.view_notificationcontact']} exact component={EditNotificationContact} />
      <PermissionRoute path="/forms" perms={['forms.view_form']} exact component={WebFormsList} />
      <PermissionRoute path="/forms/:formId" perms={['forms.view_form']} exact component={WebFormEdit} />
      <PermissionRoute path="/users" perms={['authentication.view_user']} exact component={UsersList} />
      <PermissionRoute path="/users/:userId" perms={['authentication.view_user']} exact component={Users} />
      <PermissionRoute path="/customer-journeys" perms={['journey.view_journey']} exact component={CustomerJourneysListMUI} />
      <PermissionRoute path="/customer-journeys/details/:id" perms={['journey.view_journey']} component={CustomerJourneysDetails} />
      <PermissionRoute path="/customer-journeys/details-new/:id" perms={['journey.view_journey']} flags="journey_details" component={CustomerJourneysDetailsNew} />
      <PermissionRoute path="/customer-journeys/wizard-architect/:id" perms={['integration.manage_private_integrations']} component={CustomerJourneysWizardArchitect} />
      <PermissionRoute path="/customer-journeys/:status/:id" perms={['journey.view_journey']} component={CustomerJourneys} />
      <PermissionRoute path="/customer-journeys/:id" perms={['journey.view_journey']} component={CustomerJourneys} />
      <PermissionRoute path="/test-runs/:id" perms={['journey.view_journey']} component={TestRunComponent} />

      {/* Prebuilts - Journeys */}
      <PermissionRoute path="/prebuilt/admin/journeys" perms={['is_superuser']} exact component={PrebuiltAdminJourneysList} />
      <PermissionRoute path="/prebuilt/admin/journeys/add-new" perms={['is_superuser']} exact component={PrebuiltAdminJourneysDetails} />
      <PermissionRoute path="/prebuilt/admin/journeys/:id" perms={['is_superuser']} exact component={PrebuiltAdminJourneysUsageStats} />
      <PermissionRoute path="/prebuilt/admin/journeys/:id/edit" perms={['is_superuser']} exact component={PrebuiltAdminJourneysDetails} />
      <PermissionRoute path="/prebuilt/admin/journeys/:id/add-new-version" perms={['is_superuser']} exact component={PrebuiltAdminJourneysDetails} />
      <PermissionRoute path="/prebuilt/install/journey" perms={['journey.add_journey']} exact component={PrebuiltJourneyInstallVersion} />

      {/* Prebuilts - Segments */}
      <PermissionRoute path="/prebuilt/admin/segments" perms={['is_superuser']} exact component={PrebuiltAdminSegmentsList} />
      <PermissionRoute path="/prebuilt/admin/segments/add-new" perms={['is_superuser']} exact component={PrebuiltAdminSegmentsDetails} />
      <PermissionRoute path="/prebuilt/admin/segments/:id" perms={['is_superuser']} exact component={PrebuiltAdminSegmentsUsageStats} />
      <PermissionRoute path="/prebuilt/admin/segments/:id/edit" perms={['is_superuser']} exact component={PrebuiltAdminSegmentsDetails} />
      <PermissionRoute path="/prebuilt/admin/segments/:id/add-new-version" perms={['is_superuser']} exact component={PrebuiltAdminSegmentsDetails} />
      <PermissionRoute path="/prebuilt/install/segment" perms={['customer.add_segment']} exact component={PrebuiltSegmentInstallVersion} />
      {/* Prebuilts - Emails */}
      <PermissionRoute path="/prebuilt/admin/emails" perms={['is_superuser']} exact component={PrebuiltAdminEmailsList} />
      <PermissionRoute path="/prebuilt/admin/emails/add-new" perms={['is_superuser']} exact component={PrebuiltAdminEmailsDetails} />
      <PermissionRoute path="/prebuilt/admin/emails/:id" perms={['is_superuser']} exact component={PrebuiltAdminEmailsUsageStats} />
      <PermissionRoute path="/prebuilt/admin/emails/:id/edit" perms={['is_superuser']} exact component={PrebuiltAdminEmailsDetails} />
      <PermissionRoute path="/prebuilt/admin/emails/:id/add-new-version" perms={['is_superuser']} exact component={PrebuiltAdminEmailsDetails} />
      <PermissionRoute path="/prebuilt/install/email" perms={['template.add_template']} exact component={PrebuiltEmailInstallVersion} />

      {/* Prebuilts - Sms templates */}
      <PermissionRoute path="/prebuilt/admin/texts" perms={['is_superuser']} exact component={PrebuiltAdminSmsList} />
      <PermissionRoute path="/prebuilt/admin/texts/add-new" perms={['is_superuser']} exact component={PrebuiltAdminSmsDetails} />
      <PermissionRoute path="/prebuilt/admin/texts/:id" perms={['is_superuser']} exact component={PrebuiltAdminSmsUsageStats} />
      <PermissionRoute path="/prebuilt/admin/texts/:id/edit" perms={['is_superuser']} exact component={PrebuiltAdminSmsDetails} />
      <PermissionRoute path="/prebuilt/admin/texts/:id/add-new-version" perms={['is_superuser']} exact component={PrebuiltAdminSmsDetails} />
      <PermissionRoute path="/prebuilt/install/text" perms={['template.add_template']} exact component={PrebuiltSmsInstallVersion} />

      {/* Prebuilts - Mailers templates */}
      <PermissionRoute path="/prebuilt/admin/mailers" perms={['is_superuser']} exact component={PrebuiltAdminPrintsList} />
      <PermissionRoute path="/prebuilt/admin/mailers/add-new" perms={['is_superuser']} exact component={PrebuiltAdminPrintsDetails} />
      <PermissionRoute path="/prebuilt/admin/mailers/:id" perms={['is_superuser']} exact component={PrebuiltAdminPrintsUsageStats} />
      <PermissionRoute path="/prebuilt/admin/mailers/:id/edit" perms={['is_superuser']} exact component={PrebuiltAdminPrintsDetails} />
      <PermissionRoute path="/prebuilt/admin/mailers/:id/add-new-version" perms={['is_superuser']} exact component={PrebuiltAdminPrintsDetails} />
      <PermissionRoute path="/prebuilt/install/mailer" perms={['template.add_template']} exact component={PrebuiltPrintInstallVersion} />

      {/* Prebuilts - surveys */}
      <PermissionRoute path="/prebuilt/admin/surveys" perms={['is_superuser']} exact component={PrebuiltAdminSurveysList} />
      <PermissionRoute path="/prebuilt/admin/surveys/add-new" perms={['is_superuser']} exact component={PrebuiltAdminSurveysDetails} />
      <PermissionRoute path="/prebuilt/admin/surveys/:id" perms={['is_superuser']} exact component={PrebuiltAdminSurveysUsageStats} />
      <PermissionRoute path="/prebuilt/admin/surveys/:id/edit" perms={['is_superuser']} exact component={PrebuiltAdminSurveysDetails} />
      <PermissionRoute path="/prebuilt/admin/surveys/:id/add-new-version" perms={['is_superuser']} exact component={PrebuiltAdminSurveysDetails} />
      <PermissionRoute path="/prebuilt/install/survey" perms={['surveys.add_survey']} exact component={PrebuiltSurveyInstallVersion} />
      {/* Prebuilts - Misc */}
      <PermissionRoute path="/prebuilt/admin" perms={['is_superuser']} component={PrebuiltAdminList} />
      <PermissionRoute path="/prebuilt/install" perms={['journey.add_journey']} component={PrebuiltInstall} />
      <PermissionRoute path="/prebuilt" perms={['journey.add_journey']} component={PrebuiltInstall} />

      <PermissionRoute path="/integrations/add-new" perms={['integration.add_credential']} exact component={IntegrationsAddNew} />
      <PermissionRoute path="/integrations/manage/pestpac/csv-upload/:id" perms={['integration.change_credential']} component={IntegrationPestpacUpload} />
      <PermissionRoute path="/integrations/manage/facebook" perms={['integration.view_credential']} component={IntegrationsManageFacebook} />
      <PermissionRoute path="/integrations/manage/facebook_reviews" perms={['integration.view_credential']} component={IntegrationsManageFacebookReview} flags="facebook_reviews" />
      <PermissionRoute path="/integrations/configure/facebook_reviews/:id" perms={['integration.view_credential']} component={IntegrationsConfigureFacebookReview} flags="facebook_reviews" />
      <PermissionRoute path="/integrations/manage/:id" perms={['integration.view_credential']} component={IntegrationsManage} />
      <PermissionRoute path="/integrations/configure/facebook/:id" perms={['integration.view_credential']} component={IntegrationsConfigureFacebook} />
      <PermissionRoute path="/integrations/configure/:id" perms={['integration.view_credential']} component={IntegrationsConfigureCredential} />
      <PermissionRoute path="/integrations-old/:redirect?" perms={['integration.view_credential']} exact component={IntegrationsList} />
      <PermissionRoute path="/integrations/:redirect?" perms={['integration.view_credential']} exact component={IntegrationsListMui} />
      <PermissionRoute path="/coupons" perms={['coupon.view_coupon']} exact component={CouponsList} />
      <PermissionRoute path="/segments" perms={['customer.view_segment']} exact component={SegmentsList} />
      <PermissionRoute path="/segments/:id" perms={['customer.view_segment']} exact component={Segment} />
      <PermissionRoute path="/segments/:id/edit" perms={['customer.view_segment']} exact component={EditSegment} />
      <PermissionRoute path="/segments/:id/copy" perms={['customer.view_segment']} exact component={EditSegment} />
      <PermissionRoute path="/create-segment" perms={['customer.view_segment']} exact component={EditSegment} />
      <PermissionRoute path="/reviews" perms={['reviews.view_review']} exact component={Reviews} />
      <PermissionRoute exact path="/templates/edit/email/:idEmailTemplate" perms={['template.view_template']} component={EditEmailTemplate} />
      <PermissionRoute exact path="/templates/edit/sms/:idEmailTemplate" perms={['template.view_template']} component={EditSmsTemplate} />
      <PermissionRoute exact path="/templates/gallery" perms={['template.view_template']} component={TemplateListGallery} />
      <PermissionRoute exact path="/templates" perms={['template.view_template']} component={TemplateList} />
      <PermissionRoute path="/templates/edit/print/:idMailTemplate" perms={['template.view_template']} exact component={MailTemplate} />
      <PermissionRoute path="/templates/print/:id/:revisionID?" perms={['template.view_template']} exact component={TemplateMailPublished} />
      <PermissionRoute path="/locations/import" perms={['organization.change_location']} flags="import_location_csv" exact component={ImportLocations} />
      <PermissionRoute path="/locations/bulk-edit" perms={['is_superuser']} exact component={BulkEditLocations} />
      <PermissionRoute path="/locations" perms={['organization.view_location']} exact component={LocationsList} />
      <PermissionRoute path="/markets" perms={['organization.view_location']} flags="crud-markets-on-locations-page" exact component={MarketsList} />
      <PermissionRoute path="/customers" perms={['customer.view_customer']} component={Customers} />
      <PermissionRoute path="/customer-groups" perms={['customer.view_customerrefgroup']} component={CustomerGroups} />
      <PermissionRoute path="/imports/upload" perms={['csvimport.change_import']} component={UploadImport} />
      <PermissionRoute path="/imports/upload-opt-status" perms={['csvimport.change_import']} component={CsvOptStatus} />
      <PermissionRoute path="/imports/ai-upload" perms={['csvimport.change_import']} component={AiUpload} />
      <PermissionRoute path="/imports" perms={['csvimport.change_import']} component={Imports} />
      <PermissionRoute path="/transactions" perms={['transaction.view_transaction']} component={Transactions} />
      <PermissionRoute path="/phone-numbers" perms={['communication.view_phonenumber']} component={PhoneNumbers} />
      <Route path="/files" component={Files} />
      <PermissionRoute path="/surveys/edit/:id" perms={['surveys.view_survey']} component={SurveysEdit} />
      <PermissionRoute path="/surveys/summary/:id" perms={['surveys.view_survey']} component={SurveysSummary} />
      <PermissionRoute path="/surveys" perms={['surveys.view_survey']} component={SurveysList} />
      <PermissionRoute path="/survey-responses" perms={['surveys.view_survey']} component={SurveyResponsesList} />
      <PermissionRoute path="/domains/:action?/:id?/:hash?" perms={['email.view_domain']} exact component={Domains} />
      <PermissionRoute path="/messaging" perms={['customer.view_two_way_messaging']} exact component={Messaging} />
      <PermissionRoute path="/billing" perms={['organization.view_billing']} exact component={Invoices} />
      <PermissionRoute path="/landing-pages" perms={['integration.manage_landing_pages']} exact component={LandingPage} />
      <PermissionRoute path="/community" perms={['organization.view_company']} exact component={Community} />
      <PermissionRoute path="/roles" perms={['authentication.view_role']} exact component={RolesList} />
      <PermissionRoute path="/roles/:id" perms={['authentication.view_role']} exact component={Role} />
      <PermissionRoute path="/dev-tools" perms={['integration.manage_private_integrations']} exact component={DevTools} />
      <PermissionRoute path="/dev-tools/icon-list" perms={['integration.manage_private_integrations']} exact component={IconList} />
      <PermissionRoute path="/dev-tools/integration-logs" perms={['integration.manage_private_integrations']} exact component={IntegrationLogs} />
      <PermissionRoute path="/repeat-transaction-calculator-list" perms={['repeat_transaction_calculator.view_transactioncalculatorrule']} exact component={RepeatTransactionCalculatorList} />
      <PermissionRoute path="/repeat-transaction-calculator-builder" perms={['repeat_transaction_calculator.add_transactioncalculatorrule']} exact component={RepeatTransactionCalculatorList} />
      <PermissionRoute path="/repeat-transaction-calculator-builder/:id" perms={['repeat_transaction_calculator.add_transactioncalculatorrule']} exact component={RTCBuilder} />
      <PermissionRoute path="/repeat-transaction-calculator-results/:id" perms={['repeat_transaction_calculator.view_transactioncalculatorresults']} exact component={RepeatTransactionCalculatorResults} />
      <PermissionRoute path="/repeat-transaction-calculator-results" perms={['repeat_transaction_calculator.view_transactioncalculatorresults']} exact component={RepeatTransactionCalculatorList} />
      {/* TODO need specific permissions on operations inside of motor OEM, for now LD flag covers it / private integration */}
      <PermissionRoute path="/motor-oem-recommendations/configure" perms={['integration.view_credential']} exact component={MotorOemRecommendationMain} flags="motor_oem_recommendations" />
      <PermissionRoute path="/action-items" perms={['organization.view_company']} component={ActionItems} />
      <PermissionRoute path="/dashboard/add-new" perms={['widgets.add_widgetinstall']} exact component={WidgetsAddNew} />
      <PermissionRoute path="/dashboard" perms={['widgets.view_widget']} component={WidgetsList} />
      <Route from="/" exact render={() => <Redirect to={'/dashboard'} />} />
      <Route component={Page404} />
    </Switch>;
};