import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { Scoped, k } from 'kremling';
import { Loader } from 'components';
import { Stack } from '@mui/material';
import { getForm } from 'shared/forms.api';
import { getCustomerRef } from 'shared/common.api';
import moment from 'moment-timezone';
export const FormWizardVerify = ({
  formFields,
  formId,
  formVerified,
  setFormVerified
}) => {
  const [form, setForm] = useState(null);
  const [customer, setCustomer] = useState(null);
  useEffect(() => {
    const int = setInterval(() => {
      getForm(formId).then(({
        data
      }) => {
        if (data && !!data.last_submitted_when) {
          clearInterval(int);

          // get sample form post
          getCustomerRef({
            company: data.company,
            entity_source: 'webform',
            entity_number: formId,
            limit: 1,
            ordering: '-date_created'
          }).then(({
            data
          }) => {
            setForm(data);
            setCustomer(data.results[0]);
            setFormVerified(true);
          });
        }
      });
    }, 5000);
    return () => {
      clearInterval(int);
    };
  }, []);
  if (formVerified) {
    return <Scoped css={css}>
        <Stack>
          <h3>Test submitted successfully</h3>

          <p>
            This is what the data looks like:
          </p>

          <p>
            Last time submitted: {moment(form.last_submitted_when).local().format('MMM D, YYYY h:mm A')}
          </p>

          <div className="form-map-table-container">
            <table className="form-map-table">
              <thead>
                <tr>
                  <th>Form Field</th>
                  <th>Cinch Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {formFields.filter(f => !!f.field).map((field, i) => <tr key={i}>
                  <td>{field.label}</td>
                  <td>{field.field}</td>
                  <td>{customer[field.field] || <em>No data</em>}</td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </Stack>
      </Scoped>;
  }
  return <Scoped css={css}>
      <Stack>
        <h3>Awaiting test submission</h3>

        <p>
          Submit a test submission on your web form to verify the connection is working between your page and Cinch.
        </p>

        <Loader />
      </Stack>
    </Scoped>;
};
const css = {
  styles: `[kremling="i40"] body,body[kremling="i40"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i40"] .form-map-table-container,[kremling="i40"].form-map-table-container {
  background-color: var(--color-grey-25);
  border-radius: var(--base-border-radius);
  border: solid 0.1rem var(--color-grey-100);
}

[kremling="i40"] .form-map-table,[kremling="i40"].form-map-table {
  width: 100%;
  border-collapse: collapse;
}

[kremling="i40"] .form-map-table tr,[kremling="i40"].form-map-table tr {
  border-bottom: solid 0.1rem var(--color-grey-100);
}

[kremling="i40"] .form-map-table tbody>tr:last-child,[kremling="i40"].form-map-table tbody>tr:last-child {
  border: none;
}

[kremling="i40"] .form-map-table tr>th,[kremling="i40"] .form-map-table tr>td,[kremling="i40"].form-map-table tr>th,[kremling="i40"].form-map-table tr>td {
  border-right: solid 0.1rem var(--color-grey-100);
  padding: 1rem;
}

[kremling="i40"] .form-map-table tr>th:last-child,[kremling="i40"] .form-map-table tr>td:last-child,[kremling="i40"].form-map-table tr>th:last-child,[kremling="i40"].form-map-table tr>td:last-child {
  border-right: none;
}`,
  id: 'i40',
  namespace: 'kremling'
};