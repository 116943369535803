import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment-timezone';
import { Scoped } from 'kremling';
import styles from './import.styles.scss';
import { PageHeader } from 'components/page-header/page-header';
import { Button } from 'components/button/button.component';
import { Loader } from 'components/loader/loader.component';
import { UserStateContext } from 'context/user-state-context';
import { SortHeader } from 'components/table/sort-header.component';
import { Pagination } from 'components/pagination/pagination.component';
import { getImports, deleteImport as apiDeleteImport, stopImport } from 'shared/common.api';
import { toasterService } from 'components/toaster/toaster-service';
import { AsyncModalDialog } from 'components/modal/async-modal-dialog.component';
import { Modal } from '../../components/modal/modal.component';
import { Icon, PageHeaderMui } from 'components';
import EnhancedTable from 'components/mui/enhanced-table/enhanced-table.component';
import { IconButton, Tooltip, Box, Stack, Typography, CircularProgress, Popper, Button as MUIButton, FormControl, ClickAwayListener } from '@mui/material';
import { CloseIcon, DeleteIcon, snackbarService, PausedIcon, CustomerDetailIcon, UserAttributesIcon, UserAttributesCheckedIcon, TransactionIcon, PlaylistAddCheckIcon } from 'components/mui';
import { NewButton } from 'components/mui/button/new.component';
import { borderRadius, width } from '@mui/system';
import { Input } from 'postcss';
import { ImportDetails } from './import-details.component';
export function Imports(props) {
  const {
    hasPermission,
    flags
  } = useContext(UserStateContext);
  const [imports, setImports] = useState([]);
  const [deleteImport, setDeleteImport] = useState();
  const [importIssuesModal, setImportIssuesModal] = useState();
  const [importIssues, setImportIssues] = useState([]);
  const [renderIteration, setRenderIteration] = useState(0);
  const [importPopperOpen, setImportPopperOpen] = useState(false);
  const [importPopperAnchorEl, setImportPopperAnchorEl] = useState(null);
  const [importDetailsOpen, setImportDetailsOpen] = useState({
    open: false,
    import: null
  });
  useEffect(() => {
    if (imports.find(imp => imp.status == 'pending')) {
      const interval = setInterval(() => setRenderIteration(r => r + 1), 5000);
      return () => clearInterval(interval);
    }
  }, [imports]);
  const confirmDeleteImport = () => {
    setDeleteImport(null);
    return apiDeleteImport(deleteImport.id).then(() => {
      toasterService.success(`Import successfully deleted.  Customers will be deleted over the next few minutes.`);
      setRenderIteration(r => r + 1);
    }).catch(() => {
      toasterService.error(`An unknown error occurred while attempting to delete the import. Please try again`);
    });
  };
  const sendToSegment = imp => {
    if ((imp.status == 'success' || imp.status == 'partial_success') && imp.options.segment_option != 'none') {
      props.history.push(`/segments/${imp.options.new_segment_id}`);
    }
    if (imp.status == 'error') {
      setImportIssuesModal(true);
      setImportIssues(imp.options.import_issues);
    }
  };
  const handlePopperClose = () => {
    setImportPopperOpen(false);
  };
  const headCells = [{
    key: 'filename',
    label: 'File Name',
    width: '25%',
    displayComponent: ({
      data: imp
    }) => imp.filename
  }, {
    key: 'type',
    label: 'Type',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => {
      if (imp.type === 'customer') {
        return 'Customer List';
      } else if (imp.type === 'optin_email') {
        return 'Email Opt In';
      } else if (imp.type === 'optout_email') {
        return 'Email Opt Out';
      } else if (imp.type === 'optin_phone') {
        return 'Phone Opt In';
      } else if (imp.type === 'optout_phone') {
        return 'Phone Opt Out';
      }
    }
  }, {
    key: 'source',
    label: 'Source',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => imp?.options?.other || imp.source || ''
  }, {
    key: 'total_records',
    label: 'Records',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => imp.total_records || ''
  }, {
    key: 'status',
    label: 'Status',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => <>
          {imp.status == 'pending' ? <Stack direction="row" spacing={2}>
              <CircularProgress sx={{
          color: '#FFB82B'
        }} size={'20px'} />
              <Typography sx={{
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
                Importing...
              </Typography>
            </Stack> : imp.status == 'success' ? <Box sx={{
        width: '80px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        backgroundColor: '#E8F5E9'
      }}>
              <Typography sx={{
          color: '#00C853'
        }}>Success</Typography>
            </Box> : imp.status == 'error' ?
      // change back to error
      <Box sx={{
        width: '80px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        backgroundColor: '#F30F0F'
      }}>
              <Typography sx={{
          color: '#fff'
        }}>Error</Typography>
            </Box> : imp.status == 'stopped' || imp.status == 'partial_success' ? <Box sx={{
        width: '80px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        backgroundColor: '#FFF3E0'
      }}>
              <Typography sx={{
          color: '#FFB82B'
        }}>{imp.status === 'stopped' ? 'Stopped' : 'Partial'}</Typography>
            </Box> : <Box sx={{
        width: '80px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        backgroundColor: '#808080'
      }}>
              <Typography sx={{
          color: '#fff'
        }}>{imp.status}</Typography>
            </Box>}
        </>
  }, {
    key: 'created_when',
    label: 'Date Loaded',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => <>{moment(imp.created_when).local().format('MMM D, YYYY h:mm A')}</>
  }, {
    key: 'created_by_first_name',
    label: 'Loaded By',
    width: '10%',
    displayComponent: ({
      data: imp
    }) => <>
          {imp.created_by_first_name} {imp.created_by_last_name}
        </>
  }];
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui type="Customer Import" icon={<Icon name="custom-exit_to_app" size={34} />} />

        {importDetailsOpen.open ? <ImportDetails open={importDetailsOpen.open} close={() => setImportDetailsOpen({
        open: false,
        imp: null
      })} importDetails={importDetailsOpen.import} props={props} /> : <div className="mui-wrapper">
            <EnhancedTable type="Import" headCells={headCells} dataState={[imports, setImports]} api={getImports} defaultSort={'-updated_when'} handleRowClick={imp => {
          if (imp.status == 'success' || imp.status == 'partial_success') {
            sendToSegment(imp);
          } else if (imp.status == 'pending') {
            snackbarService.popup({
              message: `Import is still processing. The process is ${Math.min(99, Math.floor((imp.records_updated + imp.records_inserted + imp.records_skipped) / imp.total_records * 100))}% complete.`,
              type: 'info',
              action: <React.Fragment>
                        <IconButton size="small" color="white" onClick={() => snackbarService.close()}>
                          <CloseIcon fontSize="small" fill={'#fff'} />
                        </IconButton>
                      </React.Fragment>
            });
          }
        }} renderIteration={renderIteration} actions={hasPermission('csvimport.add_import') && <NewButton sx={{
          borderRadius: '16px',
          height: '102px',
          width: '56px'
        }} onClick={e => {
          setImportPopperAnchorEl(e.currentTarget);
          setImportPopperOpen(true);
        }} />} hoverActions={({
          data
        }) => <Stack direction="row" spacing={1}>
                  {flags['import-csv-details-page'] && data.status !== 'pending' && <Tooltip title="View Details">
                      <IconButton onClick={() => {
              setImportDetailsOpen({
                open: true,
                import: data
              });
            }}>
                        <PlaylistAddCheckIcon />
                      </IconButton>
                    </Tooltip>}
                  {data.status !== 'pending' && hasPermission('csvimport.delete_import') && <Tooltip title="Delete">
                      <IconButton onClick={() => {
              setDeleteImport(data);
            }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>}
                  {data.status === 'pending' && <Tooltip title="Stop Import">
                      <IconButton onClick={() => {
              stopImport(data.id, {
                status: 'stopped'
              }).then(() => {
                setRenderIteration(r => r + 1);
              });
            }}>
                        <PausedIcon fill={'#000000'} />
                      </IconButton>
                    </Tooltip>}
                </Stack>} />
          </div>}
      </div>

      <AsyncModalDialog open={!!deleteImport} title="Delete Import" submitText="Confirm" onSubmit={() => confirmDeleteImport()} onClose={() => setDeleteImport(null)} actionType="danger" allowBackdropClick>
        <p>Are you sure you want to delete this import and all the customers imported from it? This cannot be undone.</p>
      </AsyncModalDialog>
      <Modal title="Import Issues" onClose={() => setImportIssuesModal(false)} open={!!importIssuesModal} allowBackdropClick size="lg" type="scroll">
        <div className="modal__body">
          <table className="table-list">
            <thead>
              <tr>
                <th> CSV Line</th>
                <th> Level</th>
                <th> Issue</th>
              </tr>
            </thead>
            <tbody>
              {importIssues?.length && importIssues.map((imp, index) => <tr key={index}>
                    <td>{imp.line || 0} </td>
                    <td style={{
                textTransform: 'capitalize'
              }}>{imp.level}</td>
                    <td> {imp.issue} </td>
                  </tr>) || <div>
                  <tr>
                    <td>Unknown Error</td>
                  </tr>
                  <tr>
                    <td>Contact Cinch Support for help</td>
                  </tr>
                </div>}
            </tbody>
          </table>
        </div>
        <div className="modal__actions">
          <Button actionType="primary" onClick={() => setImportIssuesModal(false)}>
            Okay
          </Button>
        </div>
      </Modal>

      <Popper sx={{
      height: '120px',
      width: '200px',
      borderRadius: '14px'
    }} open={importPopperOpen} anchorEl={importPopperAnchorEl} placement="bottom-end">
        <ClickAwayListener onClickAway={handlePopperClose}>
          <Box sx={{
          zIndex: 100,
          position: 'relative',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.40)',
          display: 'flex',
          flexDirection: 'column',
          py: '12px'
        }}>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            color: '#000',
            p: 0,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left'
          }} onClick={() => {
            setImportPopperOpen(false);
            props.history.push('/imports/upload');
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: '12px'
            }}>
                <UserAttributesIcon />
              </Box>
              <Typography>Customer list</Typography>
            </MUIButton>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            color: '#000',
            p: 0,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left'
          }} onClick={() => {
            setImportPopperOpen(false);
            props.history.push('/imports/upload-opt-status');
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: '12px'
            }}>
                <UserAttributesIcon />
              </Box>
              <Typography>Opt status</Typography>
            </MUIButton>
            {flags['ai-import-wizard'] && <MUIButton variant="text" sx={{
            textTransform: 'none',
            color: '#000',
            p: 0,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left'
          }} onClick={() => {
            setImportPopperOpen(false);
            props.history.push('/imports/ai-upload');
          }}>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: '12px'
            }}>
                  <TransactionIcon />
                </Box>
                <Typography>CRM Data</Typography>
              </MUIButton>}
          </Box>
        </ClickAwayListener>
      </Popper>
    </Scoped>;
}