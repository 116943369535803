import * as React from 'react';
const SvgIcon = props => {
  if (props.size == 'xlg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="m123-240-43-43 292-291 167 167 241-241H653v-60h227v227h-59v-123L538-321 371-488z"></path>
      </svg>;
  }
  if (props.size == 'lg') {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
        <path d="M127.33-240 80-287.33l293.33-293.34L538-416l230-229.33H648.67V-712H880v231.33h-66v-116.66L537.33-320.67 372.67-485.33z"></path>
      </svg>;
  }
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={props.fill || '#1D252D'} viewBox="0 -960 960 960">
      <path d="m136-240-56-56 296-298 160 160 208-206H640v-80h240v240h-80v-104L536-320 376-480z"></path>
    </svg>;
};
export default SvgIcon;