import React, { useEffect, useState } from 'react';
import { Calendar30DaysIcon, CarrotDownIcon, CarrotUpIcon, CloseIcon, JourneyIcon } from 'components/mui';
import { Dialog, DialogContent, DialogTitle, Stack, Tooltip, Box, Typography, IconButton, DialogActions, Divider, Button, Drawer, Checkbox } from '@mui/material';
import { Scoped, k } from 'kremling';
export const DuplicateSegmentCheck = props => {
  const [expanded, setExpanded] = useState(true);
  const [lastClicked, setLastClicked] = useState(null);
  return <>
      <Box onClick={e => {
      e.stopPropagation();
      if (!expanded) {
        setExpanded(!expanded);
      }
    }} sx={{
      px: '10px',
      border: '2px solid #E2E2E2',
      borderRadius: '14px',
      cursor: expanded ? 'auto' : 'pointer',
      '&:hover': {
        boxShadow: expanded ? 'none' : '0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
      }
    }}>
        <Stack sx={{
        p: 1
      }} spacing={1}>
          <Stack spacing={1}>
            <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
              <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
                <Typography sx={{
                fontWeight: '600',
                fontSize: '16px'
              }}> Duplicate Segments</Typography>
              </Box>

              <Box sx={{
              height: '40px',
              width: '40px'
            }}>
                <IconButton onClick={e => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}>
                  {expanded ? <CarrotUpIcon /> : <CarrotDownIcon />}
                </IconButton>
              </Box>
            </Box>

            {expanded && <Typography>
                You have used the same segment from this journey in a different journey(s) or in another revision of this journey. You can
                view the pontentially conflicting journeys below. If you are updating a journey with a new revision, you will have the
                option to pause or stop it after this warning. If this was intentional, you can ignore this warning.
              </Typography>}
          </Stack>

          {expanded && <Box>
              <Button onClick={e => {
            props.ignoreRecommendation('all', 'duplicateSegmentNodes', 'recommendations');
          }} sx={{
            textTransform: 'capitalize'
          }}>
                Ignore all warnings in this category
              </Button>
            </Box>}

          {expanded && <Scoped css={css}>
              <Stack spacing={2}>
                {props?.duplicateSegmentNodes.filter(n => n.showRecommendation).map(node => <Stack key={node.id} onClick={e => {
              e.stopPropagation();
              setLastClicked(node.id);
              props.scrollToNode(node.id, false, 'grow');
            }} onMouseLeave={e => {
              if (lastClicked === node.id) {
                props.scrollToNode(node.id, true, 'grow');
              }
              setLastClicked(null);
            }} direction="row" sx={{
              //  cursor: 'pointer',
              px: '10px',
              border: '1px solid #E2E2E2',
              borderRadius: '14px',
              '&:hover': {
                boxShadow: ' 0px 1px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.30)'
              }
            }}>
                      <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                justifyItems: 'center',
                alignContent: 'center',
                alignItems: 'center',
                py: '10px',
                pr: '10px'
              }}>
                        <div className="node-selection">
                          <div className="node-selection__img" style={{
                    backgroundColor: `#${node.allNodesMeta?.color}`
                  }}>
                            {node.allNodesMeta?.img ? <img src={node.allNodesMeta.img} alt="" draggable={false} /> : <JourneyIcon fill="#000000CC" />}
                          </div>
                        </div>
                      </Box>
                      <Divider orientation="vertical" flexItem />
                      <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%'
              }}>
                        <Stack spacing={1} sx={{
                  p: 1
                }}>
                          <Typography sx={{
                    fontWeight: '600'
                  }}>{node.allNodesMeta.name} </Typography>
                          <Typography> This tile uses a segment that is in use in another active journey</Typography>
                          {node.journeys.map(journey => <Typography key={journey.id}>
                              {journey.journey_name} - Revision #{journey.revision_number.index}
                            </Typography>)}
                        </Stack>
                        <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>
                          <Box />
                          <Stack direction="row" spacing={1}>
                            <Button onClick={e => {
                      e.stopPropagation();
                      props.ignoreRecommendation(node.id, 'duplicateSegmentNodes', 'recommendations');
                    }}>
                              <Typography sx={{
                        textTransform: 'capitalize'
                      }}> Ignore</Typography>
                            </Button>
                            <Button>
                              <Typography sx={{
                        textTransform: 'capitalize',
                        color: '#3898D9'
                      }}> Show</Typography>
                            </Button>
                          </Stack>
                        </Box>
                      </Box>
                    </Stack>)}
              </Stack>
            </Scoped>}
        </Stack>
      </Box>
    </>;
};
const css = {
  styles: `[kremling="i48"] body,body[kremling="i48"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i48"] .node-selection,[kremling="i48"].node-selection {
  text-align: center;
  vertical-align: top;
  flex-basis: 33.333333%;
  justify-items: center;
  display: flex;
}

[kremling="i48"] .node-selection__img,[kremling="i48"].node-selection__img {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50px;
  height: 50px;
}

[kremling="i48"] .node-selection__img img,[kremling="i48"].node-selection__img img {
  width: 30px;
  height: 30px;
  display: inline-block;
  opacity: 0.7;
}

[kremling="i48"] .node-selection__name,[kremling="i48"].node-selection__name {
  color: #1D252D;
  font-size: 12px;
  font-family: Nexa;
}`,
  id: 'i48',
  namespace: 'kremling'
};