import React, { useEffect, useContext } from 'react';
import { func, object, shape, string, bool } from 'prop-types';
import { a, m } from 'kremling';
import { get } from 'lodash';
import { Button } from 'components/button/button.component';
import { UserStateContext } from 'context/user-state-context';
import { getForms, getForm } from 'shared/forms.api';
import { DynamicTypeAhead } from '../../../../components/dynamic-type-ahead/dynamic-type-ahead.component';
import { getConvrrtPages } from 'shared/common.api';
export const TriggerWebForm = ({
  update,
  data
}) => {
  const {
    asCompany,
    hasIntegration
  } = useContext(UserStateContext);
  const {
    use_form,
    formId,
    uuid,
    use_landing_page,
    lp_form_id,
    form_name,
    form_pending
  } = data;
  useEffect(() => {
    // Auto fix form pending status if the form has been verified
    if (formId && form_pending) {
      getForm(formId).then(response => {
        if (!!response.data.last_submitted_when) {
          update({
            form_pending: !response.data.last_submitted_when
          });
        }
      });
    }
  }, [formId, form_pending]);
  const formattedForms = (...args) => {
    return getForms(...args).then(response => {
      response.data.results.forEach(form => {
        if (!form.last_submitted_when) {
          form.name += ' (pending)';
        }
      });
      return response;
    });
  };
  return <div className="pt-md">
      <div className="toggler toggler--block mb-md">
        <Button className={m('active', use_form)} onClick={() => update({
        use_form: true,
        use_landing_page: false
      })}>
          Choose Form
        </Button>
        {hasIntegration('convrrt') && <Button className={m('active', use_landing_page)} onClick={() => update({
        use_form: false,
        use_landing_page: true
      })}>
            Landing Page
          </Button>}
        <Button className={m('active', !use_form && !use_landing_page)} onClick={() => update({
        use_form: false,
        use_landing_page: false
      })}>
          Custom
        </Button>
      </div>

      {!use_form && !use_landing_page && <>
          <div className="form-group">
            <label>Instructions</label>
            <p>
              Set up a form to <code>POST</code> to the following url:
            </p>
            <a href={`${API_URL}/form/${uuid}`} target="_blank">
              {API_URL}/form/{uuid}
            </a>
          </div>
          <div className="form-group">
            <label>Allowed Body Properties</label>
            <ul>
              <li>
                <code>first_name</code>
              </li>
              <li>
                <code>last_name</code>
              </li>
              <li>
                <code>email</code> (required)
              </li>
              <li>
                <code>address1</code>
              </li>
              <li>
                <code>address2</code>
              </li>
              <li>
                <code>city</code>
              </li>
              <li>
                <code>state</code>
              </li>
              <li>
                <code>zip_code</code>
              </li>
              <li>
                <code>country</code>
              </li>
              <li>
                <code>entity_data</code>
              </li>
            </ul>
          </div>
        </>}
      {use_form && <div>
          <div className="mb-md">
            <DynamicTypeAhead getItems={formattedForms} getItemsFilters={{
          company: asCompany.id,
          ordering: 'name'
        }} placeholder="Select a form" displayProperty="name" keyProperty="id" value={formId} onChange={form => {
          update({
            formId: form?.id,
            form_name: form?.name,
            form_pending: !form?.last_submitted_when
          });
        }} />
          </div>

          {formId && form_pending && <div className="form-error pt-3">Form needs to be validated before journey can be published.</div>}
        </div>}

      {use_landing_page && hasIntegration('convrrt') && <div>
          <div className="mb-md">
            <DynamicTypeAhead getItems={getConvrrtPages} getItemsFilters={{
          company: asCompany.id
        }} placeholder={form_name || 'Select a landing page'} displayProperty="name" keyProperty="id" value={lp_form_id} onChange={form => update({
          lp_form_id: form?.id,
          form_name: form?.name
        })} />
          </div>
        </div>}
    </div>;
};