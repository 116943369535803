import React from 'react';
import { Scoped, k } from 'kremling';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { List, ListItemButton, ListItemText, Stack, Box, Typography } from '@mui/material';
import { Button, Dropdown } from 'components';
import { useRef, useEffect, useState } from 'react';
import { TypeAhead } from 'components/type-ahead/type-ahead.component';
const local = DateTime.local();
const waitUntilDefaults = {
  start_time: '08:00',
  end_time: '16:00',
  timezone: local.zoneName,
  days: [0, 1, 2, 3, 4]
};
const hourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minuteOptions = ['00', '15', '30', '45'];
export const Delay = props => {
  const {
    param,
    update,
    data
  } = props;
  const {
    label
  } = param;
  const {
    unit,
    amount,
    wait_until
  } = data;
  const showOptions = !!(wait_until && wait_until.start_time);
  const [timezoneSelected, setTimezoneSelected] = useState(props.timezone || null);
  const listRef = useRef(null);
  const [filteredTimezones, setFilteredTimezones] = useState([{
    value: 'America/New_York',
    label: 'Eastern Standard Time (EST)'
  }, {
    value: 'America/Chicago',
    label: 'Central Standard Time (CST)'
  }, {
    value: 'America/Denver',
    label: 'Mountain Standard Time (MST)'
  }, {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard Time (PST)'
  }, {
    value: 'America/Anchorage',
    label: 'Alaska Standard Time (AKST)'
  }, {
    value: 'Pacific/Honolulu',
    label: 'Hawaii Standard Time (HST)'
  }, {
    value: 'Etc/GMT+4',
    label: 'Atlantic Standard Time (AST)'
  }, {
    value: 'Etc/GMT+7',
    label: 'Arizona'
  }]);
  const onAmountChange = e => {
    if (unit !== 'years') {
      const amount = parseFloat(e.target.value);
      update({
        amount: amount < 1 ? 1 : amount
      });
    }
  };
  const onUnitChange = e => {
    const unit = e.target.value;
    update({
      amount: unit === 'years' ? 1 : amount,
      unit
    });
  };
  const toggleWaitUntil = () => {
    update({
      wait_until: wait_until.start_time ? {} : waitUntilDefaults
    });
  };
  const deconstructTime = time => {
    const [hourDisplay, minute] = time.split(':');
    const hourNumber = parseInt(hourDisplay);
    const meridiem = hourNumber >= 12 ? 'PM' : 'AM';
    const hour = hourNumber === 0 ? 12 : hourNumber > 12 ? hourNumber - 12 : hourNumber;
    return {
      hour,
      minute,
      meridiem
    };
  };
  const updateTime = (key, time) => {
    let newTime = '';
    if (time.hour === 12 && time.meridiem === 'PM') {
      newTime += '12';
    } else if (time.hour === 12 && time.meridiem === 'AM') {
      newTime += '00';
    } else if (time.meridiem === 'PM') {
      newTime += time.hour + 12;
    } else {
      newTime += time.hour < 10 ? `0${time.hour}` : time.hour;
    }
    newTime += `:${time.minute}`;
    update({
      wait_until: {
        ...wait_until,
        [key]: newTime
      }
    });
  };
  const isDaysChecked = day => {
    return wait_until.days.includes(day);
  };
  const updateDays = day => {
    const days = wait_until.days;
    update({
      wait_until: {
        ...wait_until,
        days: days.includes(day) ? [...days.splice(0, days.indexOf(day)), ...days.splice(days.indexOf(day) + 1)] : [...days, day]
      }
    });
  };
  const selectTimezone = (zone, label) => {
    update({
      wait_until: {
        ...wait_until,
        timezone: zone
      }
    });
    setTimezoneSelected(label);
  };
  useEffect(() => {
    if (!timezoneSelected) {
      setTimezoneSelected(filteredTimezones.find(timezone => timezone.value === wait_until.timezone)?.label);
    }
  }, [timezoneSelected]);
  const renderOptions = showOptions => {
    if (!showOptions) return null;
    const {
      wait_until
    } = data;
    const startTime = deconstructTime(wait_until.start_time);
    const endTime = deconstructTime(wait_until.end_time);
    return <>
        <Typography sx={{
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: '12px',
        pb: '2px'
      }}>
          {' '}
          Wait Between
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box>
            <select className="form-control" disabled={!showOptions} value={startTime.hour} onChange={e => {
            updateTime('start_time', {
              ...startTime,
              hour: parseInt(e.target.value)
            });
          }}>
              {hourOptions.map(hour => <option key={hour.toString()} value={hour}>
                  {hour}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={startTime.minute} onChange={e => {
            updateTime('start_time', {
              ...startTime,
              minute: e.target.value
            });
          }}>
              {minuteOptions.map(minute => <option key={minute.toString()} value={minute}>
                  {minute}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={startTime.meridiem} onChange={e => {
            updateTime('start_time', {
              ...startTime,
              meridiem: e.target.value
            });
          }}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </Box>
        </Stack>
        <Typography sx={{
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: '12px',
        pb: '2px'
      }}>
          {' '}
          and
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box>
            <select className="form-control" disabled={!showOptions} value={endTime.hour} onChange={e => {
            updateTime('end_time', {
              ...endTime,
              hour: parseInt(e.target.value)
            });
          }}>
              {hourOptions.map(hour => <option key={hour.toString()} value={hour}>
                  {hour}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={endTime.minute} onChange={e => {
            updateTime('end_time', {
              ...endTime,
              minute: e.target.value
            });
          }}>
              {minuteOptions.map(minute => <option key={minute.toString()} value={minute}>
                  {minute}
                </option>)}
            </select>
          </Box>
          <Box>
            <select className="form-control" disabled={!showOptions} value={endTime.meridiem} onChange={e => {
            updateTime('end_time', {
              ...endTime,
              meridiem: e.target.value
            });
          }}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </Box>
        </Stack>
        <div className="form-group mb-none">
          <label>On the following days</label>
        </div>
        <div className="form-group time-delay__days">
          <label>
            <input type="checkbox" checked={isDaysChecked(0)} onChange={() => updateDays(0)} /> Monday
          </label>
          <label>
            <input type="checkbox" checked={isDaysChecked(1)} onChange={() => updateDays(1)} /> Tuesday
          </label>
          <label>
            <input type="checkbox" checked={isDaysChecked(2)} onChange={() => updateDays(2)} /> Wednesday
          </label>
          <label>
            <input type="checkbox" checked={isDaysChecked(3)} onChange={() => updateDays(3)} /> Thursday
          </label>
          <label>
            <input type="checkbox" checked={isDaysChecked(4)} onChange={() => updateDays(4)} /> Friday
          </label>
          <label>
            <input type="checkbox" checked={isDaysChecked(5)} onChange={() => updateDays(5)} /> Saturday
          </label>
          <label>
            <input type="checkbox" checked={isDaysChecked(6)} onChange={() => updateDays(6)} /> Sunday
          </label>
        </div>
        <div className="form-group">
          <label>
            Select Timezone <span className="form-error">*</span>
          </label>
          <Dropdown ref={listRef} size="md" contentHeight={280} trigger={() => <Button dropdown block disabled={props.disabled}>
                {timezoneSelected || 'Select Timezone'}
              </Button>} content={() => <List>
                {filteredTimezones.map(zone => <ListItemButton sx={{
            height: '100%',
            width: '100%'
          }} onClick={() => {
            selectTimezone(zone.value, zone.label);
            listRef.current.close();
          }} key={zone.value}>
                    <ListItemText primary={zone.label} />
                  </ListItemButton>)}
              </List>} />
        </div>
      </>;
  };
  return <Scoped css={css}>
      <div>
        <div className="form-group">
          <label>{label}</label>
          <div className="row">
            <div className="col-3">
              <input type="number" className="form-control" value={amount} onChange={onAmountChange} />
            </div>
            <div className="col-9">
              <select className="form-control" value={unit} onChange={onUnitChange}>
                <option value="minutes">Minute(s)</option>
                <option value="hours">Hour(s)</option>
                <option value="days">Day(s)</option>
                <option value="weeks">Week(s)</option>
                <option value="months">Month(s)</option>
                <option value="quarters">Quarter(s)</option>
                <option value="years">Year</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form-group mb-sm">
          <label>
            <input type="checkbox" onChange={toggleWaitUntil} checked={showOptions} />
            Enable time and day options
          </label>
        </div>
        {renderOptions(showOptions)}
      </div>
    </Scoped>;
};
const css = {
  styles: `[kremling="i50"] body,body[kremling="i50"] {
  --color-primary: #487aae;
  --color-highlight: #e7efff;
  --color-accent: #354052;
  --color-success: #73b56e;
  --color-warning: #f4b707;
  --color-danger: #df5651;
  --color-grey-10: #f9f9f9;
  --color-grey-25: #f3f3f3;
  --color-grey-50: #e9e9e9;
  --color-grey-75: #e3e3e3;
  --color-grey-100: #d3d3d3;
  --color-grey-200: #c3c3c3;
  --color-grey-300: #b3b3b3;
  --color-grey-400: #a3a3a3;
  --color-grey-500: #808080;
  --color-grey-600: #707070;
  --color-grey-700: #606060;
  --color-grey-800: #505050;
  --color-grey-900: #404040;
  --base-font-family: Roboto, Helvetica, Arial, sans-serif;
  --base-font-size: 1.4rem;
  --base-font-color: #404040;
  --base-font-weight: 400;
  --base-line-height: 1.4;
  --box-shadow-1: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.12);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.17), 0 6px 6px rgba(0, 0, 0, 0.11);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.13), 0 10px 10px rgba(0, 0, 0, 0.1);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.18), 0 15px 12px rgba(0, 0, 0, 0.1);
  --base-border-radius: 0.4rem;
}

[kremling="i50"] .time-delay__overlay-block,[kremling="i50"].time-delay__overlay-block {
  position: relative;
  cursor: default;
  user-select: none;
}

[kremling="i50"] .time-delay__overlay-block::after,[kremling="i50"].time-delay__overlay-block::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.5);
}

[kremling="i50"] .time-delay__time,[kremling="i50"].time-delay__time {
  width: 64%;
}

[kremling="i50"] .time-delay__days label,[kremling="i50"].time-delay__days label {
  display: inline-flex;
  width: 11.5rem;
  align-items: center;
}

[kremling="i50"] .time-delay__divider,[kremling="i50"].time-delay__divider {
  padding-top: 0.4rem;
  margin-bottom: 1.6rem;
  border-bottom: solid 1px #d3d3d3;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
}`,
  id: 'i50',
  namespace: 'kremling'
};