import React, { useState, useRef, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import Papa from 'papaparse';
import { get, set } from 'lodash';
import { getCSVImportSources, importCSV, getSegments, patchCompany, previewCSV, getCompanyFields, getCompany, refreshAllCompanyFields, getAllCompanyFields } from 'shared/common.api';
import styles from './upload.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import constants from 'shared/constants';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from 'components/file-viewer/provider';
import { Button, Modal, toasterService, PageHeaderMui, Icon, DynamicTypeAheadMui } from 'components';
import { Box, Stack, Typography, Paper, Divider, IconButton, Button as MUIButton, TextField, MenuItem, Select, Grid, Autocomplete, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogContent, DialogActions, CircularProgress, Checkbox } from '@mui/material';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { AddIcon, ArrowCooldownIcon, ArrowRightAltIcon, CheckIcon, CloseIcon, CsvImportWizardIcon, HelpIcon, ImportIcon, NewsIcon, OpenInNewWindowIcon, PictureAsIcon, PlaylistAddStarCheckIcon, SegmentIcon, WarningRedIcon } from 'components/mui';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { cloneDeep } from 'lodash';
import { AddFieldModal } from '../data-fields/add-field-modal.component';
import { sortFields } from '../data-fields/utils';
export const UploadImport = props => {
  const {
    asCompany,
    companyFields,
    companyFieldsChange,
    flags
  } = useContext(UserStateContext);
  let fields, numRecords;
  const customerFields = useRef([]);
  const [uploadedFile, setUploadedFile] = useState();
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [importSources, setImportSources] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uppy, setUppy] = useState();
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [uniqueIdModal, setUniqueIdModal] = useState(false);
  const [isSelectingOptions, setIsSelectingOptions] = useState(false);
  const [isSelectingFields, setIsSelectingFields] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(true);
  const [isSelectingUniqueID, setIsSelectingUniqueID] = useState(false);
  const [isViewingSummary, setIsViewingSummary] = useState(false);
  const [wizardStepCount, setWizardStepCount] = useState(0);
  const [loseStepDataWarning, setLoseStepDataWarning] = useState(false);
  const [resetWizardWarning, setResetWizardWarning] = useState(false);
  const [addNewFieldOpen, setAddNewFieldOpen] = useState(false);
  const [modelFields, setModelFields] = useState([]);
  const [hoverIconColorButton1, setHoverIconColorButton1] = useState('#3898D9');
  const [hoverIconColorButton2, setHoverIconColorButton2] = useState('#3898D9');
  const [previewCSVData, setPreviewCSVData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newFieldValue, setNewFieldValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [customerAllFields, setCustomerAllFields] = useState([]);
  const [previewHeaders, setPreviewHeaders] = useState([]);
  const [fieldToMap, setFieldToMap] = useState('');
  const [displayFields, setDisplayFields] = useState({});
  const [fieldAdded, setFieldAdded] = useState(false);
  const [originalRefreshTime, setOriginalRefreshTime] = useState();
  const [company, setCompany] = useState();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  const LightTooltip = styled(({
    className,
    ...props
  }) => <Tooltip {...props} classes={{
    popper: className
  }} />)(({
    theme
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      maxWidth: '312px',
      borderRadius: '16px',
      padding: '12px',
      lineHeight: '16px'
    }
  }));
  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      const propName = parent ? `${parent}__${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };
  useEffect(() => {
    /**
     * Load company fields on mount
     */
    const companyId = asCompany.id;
    Promise.all([getCompany(companyId), getCompanyFields(companyId)]).then(([{
      data: company
    }, {
      data: fields
    }]) => {
      setCompany(company);
      setModelFields(fields);
    }
    // compare and output the differnet fields between fields and allfields
    );
  }, [asCompany]);
  useEffect(() => {
    getCompanyFields(asCompany.id).then(({
      data
    }) => {
      setCustomerAllFields(data);
      const customerField = data.find(f => f.id === 'customer');
      const fieldOptions = customerField.fields.filter(f => !constants.READ_ONLY_CUSTOMER_FIELDS.includes(f.id)).map(f => f.name);
      const temp = [...fieldOptions, 'Add new field'];
      setOptions(temp);
      setPreviewHeaders(customerField);
    });
  }, [asCompany]);

  // useEffect that replaces "Please select a field" with the latest entity_data_field created
  useEffect(() => {
    if (uploadedFile) {
      const customerField = customerAllFields.find(f => f.id === 'customer');
      const fieldOptions = customerField.fields.map(f => f.name);
      Object.keys(uploadedFile.maps).forEach(key => {
        if (uploadedFile.maps[key] === 'Please select a field') {
          const lastField = fieldOptions[fieldOptions.length - 1];
          uploadedFile.maps[key] = lastField;
        }
      });
      mapField('Please select a field', 'NEW');
      setUploadedFile({
        ...uploadedFile,
        new_fields: {
          ...uploadedFile.new_fields,
          [fieldToMap]: fieldOptions[fieldOptions.length - 1]
        }
      });
      setDisplayFields({
        ...displayFields,
        [fieldToMap]: fieldOptions[fieldOptions.length - 1]
      });
      setFieldToMap('');
    }
  }, [options]);
  useEffect(() => {
    const customerModel = companyFields.find(model => model.id === 'customer');
    const allCustomerFields = get(customerModel, 'fields') || [];
    const filteredCustomerFields = allCustomerFields.filter(f => {
      return !constants.READ_ONLY_CUSTOMER_FIELDS.some(cf => cf === f.id.substr(0, cf.length));
    });
    customerFields.current = filteredCustomerFields;
    getCSVImportSources({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setImportSources(data.map(r => r.source));
    });
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        maxTotalFileSize: 25000000,
        // Max file size 25mb
        allowedFileTypes: ['.csv']
      },
      disableThumbnailGenerator: true,
      showRemoveButtonAfterComplete: false,
      fileManagerSelectionType: 'files',
      allowMultipleUploads: false
    }).use(AwsS3Multipart, {
      companionUrl: API_URL + `/company-files/${asCompany.id}/upload`,
      parentFolder: 'csv/',
      createMultipartUpload,
      listParts,
      prepareUploadPart,
      completeMultipartUpload,
      abortMultipartUpload
    }).on('file-added', file => {
      handleFile(file.data);
    }).on('upload-success', file => {
      if (!fields) {
        uppy.reset();
        toasterService.error('Unable to parse any headers from the CSV file. Please check the file and try again.');
        return;
      }
      if (!numRecords) {
        uppy.reset();
        toasterService.error('CSV file does not contain any data. Please check the file and try again.');
        return;
      }
      const maps = {};
      const tempMap = {};
      customerFields.current.forEach(field => {
        tempMap[field.id.toUpperCase()] = field.id;
        tempMap[field.name.toUpperCase()] = field.id;
      });
      fields.forEach(field => {
        if (tempMap[field.toUpperCase()]) {
          maps[field] = tempMap[field.toUpperCase()];
        }
      });
      setUploadedFile({
        company: asCompany.id,
        filename: file.name,
        total_records: numRecords,
        source: 'CSV Upload',
        type: 'customer',
        other: '',
        fields,
        unique_id: '',
        maps,
        new_fields: {},
        segment_option: 'new',
        new_segment: file.name,
        segment_id: ''
      });
    });
    setUppy(uppy);
  }, []);
  useEffect(() => {
    if (isRefreshing) {
      /**
       * Check the company fields to see if we're done updating
       */
      const checkRefresh = setInterval(() => {
        getCompany(company.id).then(({
          data: company
        }) => {
          if (originalRefreshTime === company.all_fields_cached_when) {
            // Company field time has not changed so return
            return;
          }
        });
      }, 5000);
      return () => clearInterval(checkRefresh);
    }
  }, [isRefreshing, originalRefreshTime]);
  const handleFile = file => {
    const fr = new FileReader();
    fr.onload = function () {
      const data = Papa.parse(fr.result);
      const rows = data.data.filter(r => r.join('').trim() !== '');
      fields = rows.shift();
      numRecords = Math.max(0, rows.length);
    };
    try {
      fr.readAsText(file);
    } catch (e) {
      // For some unknown reason this throws an error but it still works *facepalm*
    }
  };
  const upload = () => {
    if (uploadedFile.source === 'other' && uploadedFile.other === '') {
      toasterService.error('Source set as "other" but not specified.');
      return;
    }
    if (uploadedFile.segment_option === 'new' && uploadedFile.new_segment === '') {
      toasterService.error('New segment must be given a name.');
      return;
    }
    if (uploadedFile.segment_option === 'select' && uploadedFile.new_segment === 'segment_id') {
      toasterService.error('A segment must be selected.');
      return;
    }

    // TODO make sure at least one field is mapped.
    setConfirmUpload(true);
  };
  const actuallyUpload = () => {
    //setIsUploading(true);
    // if any of the maps from uploadedFile are found in displayFields then change to "NEW"
    Object.keys(uploadedFile.maps).forEach(key => {
      if (displayFields[key]) {
        uploadedFile.maps[key] = 'NEW';
      }
    });
    setUploadedFile({
      ...uploadedFile,
      maps: uploadedFile.maps
    });
    if (fieldAdded) {
      // sync fields first before upload so the import can use the new data field
      refreshAllCompanyFields(asCompany.id).then(() => {
        setIsRefreshing(true);
        setIsLoading(true);
        setOriginalRefreshTime(company.all_fields_cached_when);
      }).catch(() => toasterService.error('There was an error while attempting to add your new data field. Please try again.')).then(() => {
        const checkIfRefreshed = setInterval(() => {
          if (!isRefreshing) {
            clearInterval(checkIfRefreshed);
            importCSV(uploadedFile).then(() => {
              props.history.push({
                pathname: '/imports'
              });
            }).catch(() => {
              toasterService.error('Unknown error occured uploading the CSV. Please try again.');
            }).then(() => {
              setIsUploading(false);
              setIsLoading(false);
            });
          }
        }, 5000);
      });
    } else {
      importCSV(uploadedFile).then(() => {
        props.history.push({
          pathname: '/imports'
        });
      }).catch(() => {
        toasterService.error('Unknown error occured uploading the CSV. Please try again.');
      }).then(() => {
        setIsUploading(false);
      });
    }
  };
  const mapField = (field, val) => {
    const maps = {
      ...uploadedFile.maps,
      [field]: val
    };
    Object.keys(maps).forEach(key => {
      if (val && val !== 'NEW' && val !== '' && key !== field && maps[key] === val) {
        maps[key] = '';
      }
    });
    setUploadedFile({
      ...uploadedFile,
      maps
    });
  };
  const addField = (modelID, field) => {
    /**
     * Add field to model
     */
    const newFields = cloneDeep(modelFields);
    // only need to addField to the customer model
    const model = newFields.find(m => m.id === modelID);
    if (!model?.fields?.find(f => f.id === field.id)) {
      model.fields.push(field);
    }
    setNewFieldValue(field);
    let numField = model.fields.length;
    mapField(model.fields[numField - 1], newFieldValue.id);
    setModelFields(newFields);
    return saveFields(newFields);
  };
  const saveFields = async newFields => {
    /**
     * Wrapper for saving fields to API with a count for how many saves are still happening.
     * TODO convert this to debouncing.
     */
    newFields = sortFields(newFields);
    setModelFields(newFields);
    await patchCompany(asCompany.id, {
      fields: newFields
    }).then(() => {
      // update options with the new field in customerFields
      getCompanyFields(asCompany.id).then(({
        data
      }) => {
        setCustomerAllFields(data);
        const customerField = data.find(f => f.id === 'customer');
        const fieldOptions = customerField.fields.map(f => f.name);
        const temp = [...fieldOptions, 'Add new field'];
        setOptions(temp);
        setPreviewHeaders(customerField);
        companyFieldsChange();
        setFieldAdded(true);
      });
    });
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui name="Import Data" icon={<Icon name="custom-exit_to_app" size={34} />} />
        <Paper sx={{
        position: 'relative',
        height: '88vh',
        width: '874px',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        backgroundColor: '#fff',
        marginTop: '20px',
        marginBottom: '20px'
      }}>
          <Stack direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pb: '10px'
        }}>
            <Box>
              <Typography sx={{
              fontSize: '20px'
            }}>
                {wizardStepCount === 0 ? 'Import Data Overview' : wizardStepCount === 2 ? 'Data Mapping' : wizardStepCount === 3 ? 'Unique ID' : wizardStepCount === 4 ? 'Preview Import' : 'Import Configuration'}
              </Typography>
            </Box>
            <Box sx={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
              <IconButton onClick={() => props.history.push('/imports')}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          {wizardStepCount === 4 ? null : <Divider id="top-wizard-divider" sx={{
          ml: '-20px',
          mr: '-20px'
        }} />}

          <Box sx={{
          pt: '20px',
          pr: '80px'
        }}>
            <Typography sx={{
            textAlign: 'justify'
          }}>
              {wizardStepCount === 4 ? `Here's a preview of how your data will be displayed in Cinch.
                  This table showcases the top 25 records from your CSV import, providing insight into the data layout. If you happen to see customer emails under the First Name column, it's a good opportunity to adjust your data mapping settings.` : wizardStepCount === 3 ? `If your CSV import includes a unique identifier, be sure to use a unique ID. This identifier should only appear once in your data. When applied, Cinch will check if the value already exists in your database and update the existing record instead of creating a new one.
                  For example, the most common unique ID is an email address. By selecting "Email" from the dropdown menu, Cinch will verify if the email addresses in your CSV are already in Cinch and update the existing records accordingly.` : wizardStepCount === 2 ? `Data mapping involves matching the column headers in your CSV file with the corresponding data fields in Cinch. This guarantees that the data from your CSV file is accurately imported into the right sections in Cinch, enabling seamless data integration and management.` : wizardStepCount === 1 ? `Congratulations on successfully uploading your CSV file! On this page, you have the option to label the source of the CSV file. For instance, if it's a list of customers who signed up from an event, you can create a custom source named 'Event Name 12-31-24 Sign up.' Additionally, you can choose to add these customers to a new or existing static segment.` : `Welcome to the Import Data Wizard. We will help you to upload your CSV file and import your customer data into Cinch. To get started, upload your CSV file by clicking the 'browse files' button highlighted in blue. Once uploaded, you may nagivate to the next step in the process by clicking the "Next" button located at the bottom.`}
            </Typography>
          </Box>
          {isUploadingFile && <Box id="content-container-0" sx={{
          height: '676px'
        }}>
              <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: '20px'
          }}>
                <CsvImportWizardIcon />
              </Box>

              {uppy && <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: '15px',
            pb: '85px'
          }}>
                  <Dashboard width={370} height={322} uppy={uppy} />
                </Box>}
            </Box>}
          <>
            {isSelectingOptions && uploadedFile && <Box id="content-container-1" sx={{
            height: '676px'
          }}>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              py: '20px'
            }}>
                  <Typography sx={{
                textAlign: 'justify',
                pr: '10px'
              }}>
                    File info
                  </Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography variant="caption" sx={{
                    fontWeight: 'bold'
                  }}>
                            File info
                          </Typography>
                          <br />
                          <Typography variant="caption" sx={{
                    textAlign: 'justify'
                  }}>
                            This is the information about the file you uploaded.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box>
                      <HelpIcon fill={'#53A6D6'} />
                    </Box>
                  </LightTooltip>
                </Box>
                <Stack direction="row" spacing={3}>
                  <Box>
                    <TextField sx={{
                  width: '389px'
                }} id="textfield-filename" label="Filename" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.filename} disabled />
                  </Box>
                  <Box>
                    <TextField sx={{
                  width: '389px'
                }} id="textfield-number-of-rows" label="Number of rows" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.total_records} disabled />
                  </Box>
                </Stack>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              pb: '20px',
              pt: '20px'
            }}>
                  <Typography sx={{
                textAlign: 'justify',
                pr: '10px'
              }}>
                    File source
                  </Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography variant="caption" sx={{
                    fontWeight: 'bold'
                  }}>
                            File Source
                          </Typography>
                          <br />
                          <Typography variant="caption" sx={{
                    textAlign: 'justify'
                  }}>
                            Specify which source the file came from. This is important for tracking and reporting purposes.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box>
                      <HelpIcon fill={'#53A6D6'} />
                    </Box>
                  </LightTooltip>
                </Box>
                <Stack direction="row" spacing={3}>
                  <Box>
                    <Select defaultValue={uploadedFile.source} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  source: e.target.value
                })} variant="outlined" sx={{
                  width: '389px'
                }}>
                      <MenuItem value="CSV Upload">CSV Upload</MenuItem>
                      <MenuItem value="Other">Other (specify)</MenuItem>
                      <MenuItem value="None">None</MenuItem>
                    </Select>
                  </Box>
                  {uploadedFile.source === 'Other' && <Box>
                      <TextField sx={{
                  width: '389px'
                }} id="textfield-other" error={uploadedFile.source === 'Other' && uploadedFile.other.trim() === ''} helperText={uploadedFile.source === 'Other' && uploadedFile.other.trim() === '' ? 'Please specify the source' : ''} label="Other*" variant="outlined" autoComplete="off" fullWidth defaultValue={uploadedFile.other} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  other: e.target.value
                })} />
                    </Box>}
                </Stack>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              pb: '20px',
              pt: '20px'
            }}>
                  <Typography sx={{
                textAlign: 'justify',
                pr: '10px'
              }}>
                    Add to segment
                  </Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography sx={{
                    fontWeight: 'bold'
                  }}>Add to segment</Typography>
                          <br />
                          <Typography sx={{
                    textAlign: 'justify'
                  }}>
                            Choose whether to add the customers in this file to a static segment. You can create a new segment or select an
                            existing one. If you don't want to add customers to a segment, select "None". By adding customers to a segment,
                            you can easily target them in future campaigns.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box>
                      <HelpIcon fill={'#53A6D6'} />
                    </Box>
                  </LightTooltip>
                </Box>
                <Stack direction="row" spacing={3}>
                  <Box>
                    <Select value={uploadedFile.segment_option} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  segment_option: e.target.value
                })} variant="outlined" sx={{
                  width: '389px'
                }}>
                      <MenuItem value="new">New Static Segment</MenuItem>
                      <MenuItem value="select">Select Segment</MenuItem>
                      <MenuItem value="none">None</MenuItem>
                    </Select>
                  </Box>
                  <Box>
                    {uploadedFile.segment_option === 'new' ? <TextField key="new-segment" sx={{
                  width: '389px'
                }} id="textfield-new-segment" label="Static Segment" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.new_segment} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  new_segment: e.target.value
                })} /> : uploadedFile.segment_option === 'select' ? <DynamicTypeAheadMui key="select-segment" sx={{
                  width: '389px'
                }} getItems={getSegments} getItemsFilters={{
                  time_dynamic_segment: false,
                  company: asCompany.id
                }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" defaultValue={uploadedFile.segment_id} onChange={val => {
                  setUploadedFile({
                    ...uploadedFile,
                    segment_id: val ? val.id : '',
                    new_segment: ''
                  });
                }} /> : <></>}
                  </Box>
                </Stack>
              </Box>}
          </>
          <>
            {isSelectingFields && uploadedFile && <Box id="content-container-2" sx={{
            height: '692px'
          }}>
                <Stack direction="row" spacing={10} sx={{
              pt: '20px'
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px',
                pr: '230px',
                textWrap: 'nowrap'
              }}>
                    <Typography sx={{
                  textAlign: 'justify',
                  pr: '10px'
                }}>
                      CSV Field
                    </Typography>
                    <LightTooltip title={<Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{
                      fontWeight: 'bold'
                    }}>
                              CSV Field
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{
                      textAlign: 'justify'
                    }}>
                              These are the fields in your CSV file.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>} placement="top-start">
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                  </Box>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px',
                textWrap: 'nowrap'
              }}>
                    <Typography sx={{
                  textAlign: 'justify',
                  pr: '10px'
                }}>
                      Cinch Data Field
                    </Typography>
                    <LightTooltip title={<Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{
                      fontWeight: 'bold'
                    }}>
                              Cinch Data Field
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{
                      textAlign: 'justify'
                    }}>
                              These are the data fields that we will map the CSV fields to so that you can use your customer data
                              effectively.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>} placement="top-start">
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                  </Box>
                </Stack>
                <Divider sx={{
              ml: '-20px',
              mr: '-20px'
            }} />

                <Box id="scrollable-data-fields" sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: '52vh',
              p: 0,
              pt: '10px'
            }}>
                  {/* dynamic CSV field data */}
                  {uploadedFile && uploadedFile.fields.map((field, i) => <Stack direction="row" key={i} sx={{
                py: '10px'
              }}>
                        <Box>
                          <TextField sx={{
                    width: '345px',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '14px',
                    '& .Mui-disabled': {
                      color: 'black',
                      WebkitTextFillColor: 'black'
                    }
                  }} id={`textfield-dynamic-${field}`} variant="outlined" autoComplete="off" fullWidth value={uploadedFile.fields[i]} disabled />
                        </Box>
                        <Box sx={{
                  opacity: uploadedFile.maps[field] && uploadedFile.maps[field] !== 'Please select a field' ? 1 : 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: '20px'
                }}>
                          <ArrowCooldownIcon />
                        </Box>
                        <Box>
                          <Autocomplete freeSolo id={`autocomplete-dynamic-${field}`} options={options} onChange={(e, newValue) => {
                    if (newValue === 'Add new field') {
                      setAddNewFieldOpen(true);
                      newValue = 'Please select a field';
                      setFieldToMap(field);
                      mapField(field, newValue);
                    } else {
                      newValue = customerFields.current.find(f => f.name === newValue)?.id || newValue;
                      mapField(field, newValue);
                    }
                  }} value={
                  // if there is a match for displayFields use that, else try others
                  displayFields[field] || customerFields.current.find(f => f.id === uploadedFile.maps[field])?.name || uploadedFile.maps[field] || ''} renderInput={params => <TextField label="Target field" {...params} sx={{
                    width: '345px'
                  }} variant="outlined" autoComplete="off" fullWidth />} renderOption={(props, option) => <li {...props}>
                                {option === 'Add new field' ? <>
                                    <Box sx={{
                        position: 'absolute',
                        left: '8px'
                      }}>
                                      <AddIcon />
                                    </Box>
                                    <Box sx={{
                        px: '30px'
                      }}>{option}</Box>
                                  </> : <Box sx={{
                      px: '30px'
                    }}>{option}</Box>}
                              </li>} />
                        </Box>

                        <Box sx={{
                  opacity: uploadedFile.maps[field] && uploadedFile.maps[field] !== 'Please select a field' ? 1 : 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  px: '20px'
                }}>
                          <CheckIcon fill={'#3EB87B'} />
                        </Box>
                      </Stack>)}
                </Box>
              </Box>}
          </>
          <>
            {isSelectingUniqueID && uploadedFile && <Stack id="content-container-3" direction="row" sx={{
            pt: '31px',
            pb: '32px',
            height: '660px'
          }}>
                <Box>
                  <Autocomplete freeSolo id="autocomplete-unique-id" options={['None', ...uploadedFile.fields]} onChange={(event, newValue) => {
                setUploadedFile({
                  ...uploadedFile,
                  unique_id: newValue
                });
              }} value={uploadedFile.unique_id} renderInput={params => <TextField label="Unique ID" {...params} sx={{
                width: '345px'
              }} variant="outlined" autoComplete="off" fullWidth value={uploadedFile.unique_id} onChange={e => {
                setUploadedFile({
                  ...uploadedFile,
                  unique_id: e.target.value
                });
              }} />} />
                </Box>
              </Stack>}
          </>
          <>
            {isViewingSummary && uploadedFile && previewCSVData ? <Box id="content-container-4" sx={{
            height: '693px'
          }}>
                <Box sx={{
              pt: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}>
                  <Typography>Import Configuration</Typography>
                </Box>
                <Stack direction="row" spacing={1} sx={{
              py: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px'
              }}>
                    <MUIButton variant="contained" sx={{
                  borderRadius: '8px',
                  background: 'rgba(29, 37, 45, 0.10)',
                  '&:hover': {
                    backgroundColor: 'rgba(29, 37, 45, 0.10)'
                  },
                  width: '184px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  p: '6px 6px 8px 6px'
                }} onClick={() => {
                  // return user to import step
                  setResetWizardWarning(true);
                }}>
                      <Stack direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: '6px',
                      height: '40px',
                      width: '40px',
                      background: 'rgba(29, 37, 45, 0.10)',
                      borderRadius: '8px'
                    }}>
                          <NewsIcon />
                        </Box>
                        <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: '10px',
                      alignItems: 'flex-start'
                    }}>
                          <Typography sx={{
                        fontWeight: 700
                      }}>File info</Typography>
                          <Typography noWrap sx={{
                        pt: '2px'
                      }}>
                            {makeOverflowTooltip(uploadedFile.filename, 10, false)}
                          </Typography>
                        </Box>
                      </Stack>
                    </MUIButton>
                  </Box>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px'
              }}>
                    <MUIButton variant="contained" sx={{
                  borderRadius: '8px',
                  background: 'rgba(29, 37, 45, 0.10)',
                  '&:hover': {
                    backgroundColor: 'rgba(29, 37, 45, 0.10)'
                  },
                  width: '184px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  p: '6px 6px 8px 6px'
                }} onClick={() => {
                  // return the user to data mapping step
                  setWizardStepCount(1);
                  setIsSelectingOptions(true);
                  setIsSelectingFields(false);
                  setIsSelectingUniqueID(false);
                  setIsViewingSummary(false);
                }}>
                      <Stack direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: '6px',
                      height: '40px',
                      width: '40px',
                      background: 'rgba(29, 37, 45, 0.10)',
                      borderRadius: '8px'
                    }}>
                          <PictureAsIcon fill={'#3898D9'} />
                        </Box>
                        <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: '10px',
                      alignItems: 'flex-start'
                    }}>
                          <Typography sx={{
                        fontWeight: 700
                      }}>Source</Typography>
                          <Typography sx={{
                        pt: '2px'
                      }} noWrap>
                            {makeOverflowTooltip(uploadedFile.source, 10, false)}
                          </Typography>
                        </Box>
                      </Stack>
                    </MUIButton>
                  </Box>

                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px'
              }}>
                    <MUIButton variant="contained" sx={{
                  borderRadius: '8px',
                  background: 'rgba(29, 37, 45, 0.10)',
                  '&:hover': {
                    backgroundColor: 'rgba(29, 37, 45, 0.10)'
                  },
                  width: '184px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  p: '6px 6px 8px 6px'
                }} onClick={() => {
                  // return the user segment information step
                  setWizardStepCount(1);
                  setIsSelectingOptions(true);
                  setIsSelectingFields(false);
                  setIsSelectingUniqueID(false);
                  setIsViewingSummary(false);
                }}>
                      <Stack direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: '6px',
                      height: '40px',
                      width: '40px',
                      background: 'rgba(29, 37, 45, 0.10)',
                      borderRadius: '8px'
                    }}>
                          <SegmentIcon fill={'#3898D9'} />
                        </Box>
                        <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: '10px',
                      alignItems: 'flex-start'
                    }}>
                          <Typography sx={{
                        fontWeight: 700
                      }}>Segment</Typography>
                          <Typography noWrap sx={{
                        pt: '2px'
                      }}>
                            {makeOverflowTooltip(`${uploadedFile.segment_option.charAt(0).toUpperCase()}${uploadedFile.segment_option.slice(1)}`, 10, false)}
                          </Typography>
                        </Box>
                      </Stack>
                    </MUIButton>
                  </Box>

                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pb: '20px'
              }}>
                    <MUIButton variant="contained" sx={{
                  borderRadius: '8px',
                  background: 'rgba(29, 37, 45, 0.10)',
                  '&:hover': {
                    backgroundColor: 'rgba(29, 37, 45, 0.10)'
                  },
                  width: '184px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  p: '6px 6px 8px 6px'
                }} onClick={() => {
                  // return the user to unique id step
                  setWizardStepCount(3);
                  setIsSelectingOptions(false);
                  setIsSelectingFields(false);
                  setIsSelectingUniqueID(true);
                  setIsViewingSummary(false);
                }}>
                      <Stack direction="row" sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                        <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: '6px',
                      height: '40px',
                      width: '40px',
                      background: 'rgba(29, 37, 45, 0.10)',
                      borderRadius: '8px'
                    }}>
                          <PlaylistAddStarCheckIcon fill={'#3898D9'} />
                        </Box>
                        <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: '10px',
                      alignItems: 'flex-start'
                    }}>
                          <Typography sx={{
                        fontWeight: 700
                      }}>Unique ID</Typography>
                          <Typography noWrap>{makeOverflowTooltip(uploadedFile.unique_id, 10, false)}</Typography>
                        </Box>
                      </Stack>
                    </MUIButton>
                  </Box>
                </Stack>

                <Stack id="table-preview-fields">
                  {/* TODO: class this table out cuz this file is getting huge */}
                  <Box id="scrollable-table-fields" sx={{
                overflow: 'auto',
                height: '43vh',
                p: 0,
                border: '1px solid #E2E2E2',
                borderRadius: '14px'
              }}>
                    {!isLoading ? <Table variant="outlined" sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  borderSpacing: 0,
                  '& th': {
                    backgroundColor: '#F9F9F9',
                    border: '1px solid #E2E2E2',
                    padding: '8px',
                    textAlign: 'left',
                    borderRight: 'none',
                    borderLeft: 'none'
                  },
                  '& td': {
                    border: '1px solid #E2E2E2',
                    padding: '8px',
                    textAlign: 'left',
                    borderRight: 'none',
                    borderLeft: 'none'
                  }
                }}>
                        <TableHead>
                          <TableRow>
                            {Object.keys(uploadedFile.maps).map(field => <TableCell key={field} sx={{
                        height: '60px',
                        p: '12px 16px',
                        gap: '10px'
                      }}>
                                <Typography noWrap sx={{
                          fontWeight: 700
                        }}>
                                  {makeOverflowTooltip(displayFields[field] || previewHeaders.fields.find(f => f.id === uploadedFile.maps[field])?.name || uploadedFile.maps[field], 20, false)}
                                </Typography>
                              </TableCell>)}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {previewCSVData && previewCSVData?.data?.results.map((row, rowIndex) => <TableRow key={rowIndex}>
                                {Object.keys(uploadedFile.maps).map((header, headerIndex) => {
                        const value = row[uploadedFile.maps[header]];
                        return <TableCell key={headerIndex} sx={{
                          height: '60px',
                          p: '12px 16px',
                          gap: '10px'
                        }}>
                                      <Typography noWrap>{value ? makeOverflowTooltip(value, 20, false) : '--'}</Typography>
                                    </TableCell>;
                      })}
                              </TableRow>)}
                        </TableBody>
                      </Table> : <>
                        {isLoading && <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    translateY: '50%',
                    height: '100%',
                    width: '100%'
                  }}>
                            <Stack direction="row" spacing={2}>
                              <CircularProgress sx={{
                        color: '#FFB82B'
                      }} size={'20px'} />
                              <Typography sx={{
                        opacity: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                                Please wait while we begin to import your data...
                              </Typography>
                            </Stack>
                          </Box>}
                      </>}
                  </Box>
                </Stack>
              </Box> : <></>}
          </>

          <div style={{
          position: 'absolute',
          top: '80vh',
          left: '0',
          right: '0'
        }}>
            <Divider id="bottom-wizard-divider" />
          </div>
          <Stack direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: '20px',
          pl: '26px',
          position: 'absolute',
          width: '97%',
          bottom: '20px',
          left: '0',
          right: '0'
        }}>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minWidth: '126px'
          }}>
              {wizardStepCount > 0 && <MUIButton variant="contained" sx={{
              width: '126px',
              height: '32px',
              backgroundColor: '#F3F3F4',
              '&:hover': {
                color: '#fff'
              }
            }} onMouseEnter={() => {
              setHoverIconColorButton1('#fff');
            }} onMouseLeave={() => {
              setHoverIconColorButton1('#3898D9');
            }} onClick={() => {
              setLoseStepDataWarning(true);
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transform: 'scaleX(-1)'
              }}>
                    <ArrowRightAltIcon fill={hoverIconColorButton1} />
                  </Box>

                  <Typography sx={{
                p: '8px',
                color: hoverIconColorButton1
              }}>Previous</Typography>
                </MUIButton>}
            </Box>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '-30px'
          }}></Box>
            <Box sx={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'flex-end'
          }}>
              <MUIButton variant="contained" sx={{
              width: wizardStepCount === 4 ? '114px' : '100px',
              height: '32px'
            }} disabled={uploadedFile?.source === 'Other' && uploadedFile.other.trim() === '' || isLoading} onClick={() => {
              if (uploadedFile && wizardStepCount === 0) {
                setIsUploadingFile(false);
                setIsSelectingOptions(true);
                setWizardStepCount(wizardStepCount + 1);
              } else if (uploadedFile && wizardStepCount === 1) {
                setIsSelectingOptions(false);
                setIsSelectingFields(true);
                setWizardStepCount(wizardStepCount + 1);
              } else if (uploadedFile && wizardStepCount === 2) {
                // start generating preview now to be ready for ViewSummary
                setIsLoading(true);
                let previewFile = {
                  ...uploadedFile
                };
                // remove empty keys in the map object
                Object.keys(previewFile.maps).forEach(key => {
                  if (!previewFile.maps[key] || previewFile.maps[key] === 'None') {
                    delete previewFile.maps[key];
                  }
                });
                previewCSV(previewFile).then(data => {
                  const flattenedData = data.data.results.map(row => flattenObject(row));
                  setPreviewCSVData({
                    ...data,
                    data: {
                      ...data.data,
                      results: flattenedData
                    }
                  });
                });
                setIsLoading(false);
                setIsSelectingFields(false);
                setIsSelectingUniqueID(true);
                setWizardStepCount(wizardStepCount + 1);
              } else if (uploadedFile && wizardStepCount === 3) {
                setIsSelectingUniqueID(false);
                setIsViewingSummary(true);
                setWizardStepCount(wizardStepCount + 1);
              } else if (uploadedFile && wizardStepCount === 4) {
                setConfirmUpload(true);
              } else if (wizardStepCount > 4 || wizardStepCount < 0) {
                return;
              }
            }}>
                <Typography sx={{
                p: '8px',
                color: '#fff'
              }}>{wizardStepCount === 4 ? 'Import' : 'Next'}</Typography>
                <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                  {wizardStepCount === 4 ? <span style={{
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                      <ImportIcon fill={'#fff'} />
                    </span> : <ArrowRightAltIcon fill={'#fff'} />}
                </Box>
              </MUIButton>
            </Box>
          </Stack>
        </Paper>
      </div>

      <Dialog open={confirmUpload} onClose={() => setConfirmUpload(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '407px',
        width: '560px',
        overflow: 'hidden'
      }
    }}>
        <Box sx={{
        p: '20px'
      }}>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
            <Typography sx={{
            py: '16px',
            fontSize: '24px',
            pl: '10px'
          }}>Confirm Import</Typography>
          </Box>
          <DialogContent sx={{
          p: '10px',
          overflow: 'visible'
        }}>
            <Box>
              <Typography>
                {uploadedFile && uploadedFile.total_records === 0 ? 'There are no records detected in this CSV file. Please check the file and import again.' : `Please confirm you want to load these ${uploadedFile?.total_records} records into ${asCompany.name}.`}
              </Typography>
            </Box>
            <Divider id="top-divider-confirm" sx={{
            mt: '20px',
            mb: '20px',
            ml: '-30px',
            mr: '-30px'
          }} />
            <Stack direction="row" sx={{
            pt: '16px'
          }}>
              <Box sx={{
              pr: '10px'
            }}>
                <Checkbox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'justify',
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} checked={agree1} onChange={event => {
                setAgree1(event.target.checked);
              }}></Checkbox>
              </Box>
              <Box>
                <Typography>
                  I understand that importing new records may impact my monthly pricing for Cinch. (Please see your contract or contact{' '}
                  <a href="mailto:support@cinch.io">support@cinch.io</a> for more information)
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row" sx={{
            pt: '16px'
          }}>
              <Box sx={{
              pr: '10px'
            }}>
                <Checkbox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'justify',
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} checked={agree2} onChange={event => {
                setAgree2(event.target.checked);
              }}></Checkbox>
              </Box>
              <Box>
                <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                  I certify that I have permission to contact or market to these customer records
                </Typography>
              </Box>
            </Stack>
          </DialogContent>
          <Divider id="bottom-divider-confirm" sx={{
          mt: '20px',
          mb: '20px',
          ml: '-20px',
          mr: '-20px'
        }} />

          <DialogActions sx={{
          pr: '10px',
          pt: '40px'
        }}>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}>
              <MUIButton variant="contained" sx={{
              width: '94px',
              height: '32px',
              backgroundColor: '#F3F3F4',
              '&:hover': {
                color: '#fff'
              }
            }} onMouseEnter={() => {
              setHoverIconColorButton2('#fff');
            }} onMouseLeave={() => {
              setHoverIconColorButton2('#3898D9');
            }} onClick={() => {
              setConfirmUpload(false);
            }}>
                <Typography sx={{
                p: '8px',
                color: hoverIconColorButton2
              }}>Cancel</Typography>
              </MUIButton>
            </Box>
            <MUIButton variant="contained" sx={{
            width: '100px',
            height: '32px'
          }} disabled={!(agree1 && agree2 && uploadedFile.total_records > 0)} onClick={() => {
            if (agree1 && agree2) {
              actuallyUpload();
              setConfirmUpload(false);
            }
          }}>
              Import
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Modal open={!!uniqueIdModal} onClose={() => setUniqueIdModal(true)} allowBackdropClick title="Unique ID Information">
        <div className="modal__body">
          <p>
            Check this box if your data set contains a unique identifier (meaning that this identifier or value should only exists once in
            the data). When you check this box next to the appropriate unique ID, Cinch will check if value already exists in your database,
            and if it does, it will update the existing record instead of creating a new record.
          </p>
          <p>
            For example, if you selected “Email” as your unique ID, Cinch will check if the email addresses in the CSV you’re loading have
            been loaded before, and if they have, it will just update the existing records with a matching email address”
          </p>
        </div>
        <div className="modal__actions">
          <Button actionType="primary" onClick={() => setUniqueIdModal(false)}>
            Okay
          </Button>
        </div>
      </Modal>

      <Dialog open={loseStepDataWarning} onClose={() => setLoseStepDataWarning(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '245px',
        width: '312px',
        overflow: 'hidden'
      }
    }}>
        <Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '24px'
        }}>
            <WarningRedIcon />
          </Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
            <Typography sx={{
            pt: '16px',
            fontSize: '24px'
          }}>Progress Warning</Typography>
          </Box>
          <DialogContent>
            <Box>
              <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'justify'
            }}>
                Are you sure you want to go back? You will lose all the data you have entered in this step.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          pr: '20px',
          pt: '25px'
        }}>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => setLoseStepDataWarning(false)}>
              Cancel
            </MUIButton>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => {
            setLoseStepDataWarning(false);
            if (wizardStepCount === 1) {
              setIsUploadingFile(true);
              setIsSelectingOptions(false);
              setWizardStepCount(wizardStepCount - 1);
              setLoseStepDataWarning(false);
            }
            if (wizardStepCount === 2) {
              setIsSelectingOptions(true);
              setIsSelectingFields(false);
              setWizardStepCount(wizardStepCount - 1);
              setLoseStepDataWarning(false);
            }
            if (wizardStepCount === 3) {
              setIsSelectingFields(true);
              setIsSelectingUniqueID(false);
              setWizardStepCount(wizardStepCount - 1);
              setLoseStepDataWarning(false);
            }
            if (wizardStepCount === 4) {
              setIsSelectingUniqueID(true);
              setIsViewingSummary(false);
              setWizardStepCount(wizardStepCount - 1);
              setLoseStepDataWarning(false);
            }
          }}>
              Confirm
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={resetWizardWarning} onClose={() => setResetWizardWarning(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '245px',
        width: '312px',
        overflow: 'hidden'
      }
    }}>
        <Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '24px'
        }}>
            <WarningRedIcon />
          </Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
            <Typography sx={{
            pt: '16px',
            fontSize: '24px'
          }}>Replace import file</Typography>
          </Box>
          <DialogContent>
            <Box>
              <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'justify'
            }}>
                Doing this will remove all import configurations, and you will need to restart the import process from the beginning of the
                wizard.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          pr: '20px'
        }}>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => setResetWizardWarning(false)}>
              Cancel
            </MUIButton>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => {
            setResetWizardWarning(false);
            setWizardStepCount(0);
            setIsUploadingFile(true);
            setIsSelectingOptions(false);
            setIsSelectingFields(false);
            setIsSelectingUniqueID(false);
            setIsViewingSummary(false);
          }}>
              Confirm
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>

      <AddFieldModal open={addNewFieldOpen} sx={{
      display: 'flex',
      width: '312px',
      height: 'auto',
      p: '24px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '24px',
      borderRadius: '28px'
    }} title="Add new field" onClose={() => {
      setAddNewFieldOpen(false);
    }} modelID={'customer'} addField={addField} typeDisabled={true} />
    </Scoped>;
};